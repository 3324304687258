import { Greyscale, ScreenSize } from 'glints-aries';
import styled, { CSSObject } from 'styled-components';

export const LanguageSwitcherContainer = styled.div<LanguageSwitcherContainerProps>`
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ textColor }) => textColor};

  div.dropdown-content {
    > span > svg {
      margin-right: 0;
    }
  }

  ul {
    width: auto;
    border-radius: 4px;
    background: ${Greyscale.white};
  }

  li {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
    padding: 10px 15px;
    min-width: 110px;

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  @media (max-width: ${ScreenSize.desktopS - 1}px) {
    div.dropdown-contentwrapper {
      display: inline-block;
      padding: 15px 20px;
    }

    div.dropdown-content {
      padding: 0;
    }

    ul.dropdown-listbox {
      position: relative;
      left: 0;
      transform: none;

      li {
        text-transform: uppercase;
        min-width: 150px;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
`;

export type LanguageSwitcherContainerProps = {
  fontSize: number;
  fontWeight: CSSObject['fontWeight'];
  textColor: CSSObject['color'];
};
