import { isEmpty, lowerCase, uniq } from 'lodash-es';
import moment from 'moment';

import { JobInterface } from 'src/modules/Opportunity/interface';
import { HierarchicalJobTitle } from 'src/modules/Profile/Components/Form/types';
import { HierarchyJobCategoryBase } from 'src/modules/Profile/graphql/jobCategoriesAndTitles';
import { MeValue } from 'src/reducers/user/me';

import { isL1Parent, isL2Parent } from './hierarchicalJobCategoryHelpers';

export function getLoginEventUserProperties(user: MeValue) {
  const daysFromSignUp = moment()
    .startOf('day')
    .clone()
    .diff(user.createdAt, 'days');

  return {
    days_from_signup: daysFromSignUp,
    has_resume: user.resume !== null,
    has_mobile_number: user.phone !== null,
    has_whatsapp_number: user.whatsappNumber !== null,
    age_in_years: user.age ?? 'null',
    gender: lowerCase(user.gender),
  };
}

type JobTitle = HierarchicalJobTitle & {
  amplitudeJobRolePreferences?: Array<
    Pick<HierarchyJobCategoryBase, 'name' | 'level'>
  >;
};

export const getUserJobRolePreferencesForAmplitude = (
  jobTitles: Array<JobTitle>
) => {
  const getParents = (title: JobTitle) =>
    title.amplitudeJobRolePreferences ?? title.parents;
  return {
    job_category_preference: uniq(
      jobTitles
        .flatMap(getParents as any)
        .filter(isL1Parent)
        .map((l1: HierarchyJobCategoryBase) => l1.name)
    ),
    job_subcategory_preference: uniq(
      jobTitles
        .flatMap(getParents as any)
        .filter(isL2Parent)
        .map((l1: HierarchyJobCategoryBase) => l1.name)
    ),
    job_role_preference: uniq(
      jobTitles.map((title: HierarchyJobCategoryBase) => title.name)
    ),
  };
};

export const getUserJobTypePreferences = (
  fullTime: boolean,
  partTime: boolean,
  internship: boolean,
  projectBased: boolean,
  daily: boolean
) => {
  return [
    ...(fullTime ? ['full-time'] : []),
    ...(partTime ? ['part-time'] : []),
    ...(internship ? ['internship'] : []),
    ...(projectBased ? ['freelance'] : []),
    ...(daily ? ['daily'] : []),
  ];
};

export const getJobBenefits = (opportunity: JobInterface) =>
  opportunity.benefits?.map((benefit) => benefit.title);

export const getResumeUploadRequirement = (opportunity: JobInterface) =>
  opportunity?.resumeRequiredStatus?.toLocaleLowerCase();

export const isSalaryShown = (opportunity: JobInterface) =>
  !isEmpty(opportunity.salaries) && opportunity?.shouldShowSalary;
