import { findDevice } from '@artsy/detect-responsive-traits';
import { IncomingMessage } from 'http';

import { findBreakpointAtWidth, findBreakpointsForWidths } from 'src/media';

export const getResponsiveRenderProps = ({ headers }: IncomingMessage) => {
  const userAgent = (headers['user-agent'] || headers['User-Agent']) as string;
  const device = findDevice(userAgent);

  if (device) {
    const onlyMatch = device.resizable
      ? findBreakpointsForWidths(device.minWidth, device.maxWidth)
      : [
          findBreakpointAtWidth(device.minWidth),
          findBreakpointAtWidth(device.maxWidth),
        ];

    return {
      device,
      onlyMatch,
    };
  }

  return undefined;
};
