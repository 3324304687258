import { SingletonRouter } from 'next/router';

import { resetIsOnpopstate, setIsOnpopstate } from 'src/actions/history';

export function patchRouter(router: SingletonRouter) {
  router.ready(() => {
    if (!router._patched) {
      const store = window.__NEXT_REDUX_STORE__;
      const clientConfig = store.getState().clientConfig;

      let pathPrefix = clientConfig.BASENAME;
      if (!pathPrefix || pathPrefix === '/') {
        pathPrefix = '';
      }

      router.router.scrollToHash = (asPath) => {
        const hash = asPath.split('#')[1];
        if (!hash) {
          return;
        }

        const anchor = document.getElementById(hash);
        if (!anchor) {
          return;
        }

        const offSet = anchor.getBoundingClientRect().top + window.scrollY - 90;
        window.scrollTo(0, offSet);
      };

      // this is for scroll restoration:
      // beforePopState is triggered by browser action
      // such as a click on the back or forward button
      // we set a flag isOnpopstate to true in redux store,
      // then the route change will be handled by router.router.change,
      // where resetIsOnpopstate is dispatched to reset isOnpopstate to false
      // and set isBrowserPrevNextNavigation to true
      router.beforePopState(() => {
        store.dispatch(setIsOnpopstate(true));
        return true;
      });

      // patch router change method to make it support basePath.
      // @ts-ignore
      router.router._unpatchedChange = router.router.change;
      // @ts-ignore
      router.router.change = (method, url, asPath, options) => {
        store.dispatch(resetIsOnpopstate());

        // Prepend basename to asPath.
        if (pathPrefix && !asPath.includes(pathPrefix)) {
          asPath = pathPrefix + asPath;
        }

        return router.router._unpatchedChange(method, url, asPath, options);
      };

      router._patched = true;
    }
  });
}
