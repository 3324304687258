import React from 'react';
import { defineMessages, FormattedMessage, Messages } from 'react-intl';

import { JobType } from 'src/global/models/Job';

import { ExperienceValueLabel } from '../enums/jobs';

export const JobTypeMessages: Messages = defineMessages({
  [JobType.INTERNSHIP]: {
    id: 'internship',
    defaultMessage: 'Internship',
  },
  [JobType.FULL_TIME]: {
    id: 'fullTime',
    defaultMessage: 'Full-Time',
  },
  [JobType.PART_TIME]: {
    id: 'partTime',
    defaultMessage: 'Part-Time',
  },
  [JobType.PROJECT_BASED]: {
    id: 'projectBased',
    defaultMessage: 'Freelance',
  },
  [JobType.DAILY]: {
    id: 'text-daily',
    defaultMessage: 'Daily',
  },
  [JobType.CONTRACT]: {
    id: 'text-contract',
    defaultMessage: 'Contract',
  },
});

export const ExperienceMessageMap = {
  [ExperienceValueLabel.NoExperience]: (
    <FormattedMessage id="text-no-experience" defaultMessage="No experience" />
  ),
  [ExperienceValueLabel.FreshGrad]: (
    <FormattedMessage
      id="forYou.sort.option.fresh-grad"
      defaultMessage="Fresh Graduate"
    />
  ),
  [ExperienceValueLabel.LessThanOneYear]: (
    <FormattedMessage
      id="job-filter.experience.less-than-one"
      defaultMessage="<1 yr"
    />
  ),
  [ExperienceValueLabel.OneToThreeYears]: (
    <FormattedMessage
      id="job-filter.experience.one-to-three"
      defaultMessage="1 - 3 yrs"
    />
  ),
  [ExperienceValueLabel.ThreeToFiveYears]: (
    <FormattedMessage
      id="job-filter.experience.three-to-five"
      defaultMessage="3 - 5 yrs"
    />
  ),
  [ExperienceValueLabel.FiveToTenYears]: (
    <FormattedMessage
      id="job-filter.experience.five-to-ten"
      defaultMessage="5 - 10 yrs"
    />
  ),
  [ExperienceValueLabel.MoreThanTenYears]: (
    <FormattedMessage
      id="job-filter.experience.more-than-ten"
      defaultMessage="10+ yrs"
    />
  ),
};
