import { Greyscale } from 'glints-aries';
import styled from 'styled-components';

export const NAVBAR_Z_INDEX = 101;

export const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
`;

interface MainHeaderProps {
  readonly isSticky?: boolean;
}

export const MainHeader = styled.div<MainHeaderProps>`
  ${({ isSticky }) => (isSticky ? 'position: sticky;' : '')}
  top: 0px;
  background-color: ${Greyscale.white};
  z-index: ${NAVBAR_Z_INDEX};
  border-bottom: 1px solid ${Greyscale.lightgrey};
`;

MainHeader.defaultProps = {
  isSticky: true,
};

export const MainBody = styled.div`
  flex-grow: 1;
`;
