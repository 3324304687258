import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { openLoginSignUpModal } from 'src/actions/app';

import { useShouldUseLoginSignupModal } from '../../useShouldUseLoginSignupModal';

function MobileLoginSignupButtonFn({ onClick, href, children }) {
  const shouldUseLoginSignupModal = useShouldUseLoginSignupModal();
  const dispatch = useDispatch();
  const enhanceOnClick = () => {
    dispatch(openLoginSignUpModal());
    onClick();
  };
  return (
    <Choose>
      <When condition={shouldUseLoginSignupModal}>
        <a onClick={enhanceOnClick}>{children}</a>
      </When>
      <Otherwise>
        <Link href={href}>
          <a onClick={onClick}>{children}</a>
        </Link>
      </Otherwise>
    </Choose>
  );
}

MobileLoginSignupButtonFn.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['login', 'signup']).isRequired,
};

export const MobileLoginSignupButton = MobileLoginSignupButtonFn;
