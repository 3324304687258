import {
  isCountryCodeID,
  isCountryCodeVN,
} from 'src/common/hooks/useCountryData';

import { CountryCodes } from '../../../common/enums';

const defaultSlugSuffix = '-jobs';
const vnSlugPrefix = 'tim-viec-lam-';
const idSlugPrefix = 'loker-';
const idJobRoleSlugPrefix = 'lowongan-kerja-';

export const extractSlugFromFullSlug = (
  fullSlug: string,
  countryCode?: CountryCodes,
  language?: string
) => {
  if (isCountryCodeVN(countryCode) && language === 'vi')
    return fullSlug.replace(vnSlugPrefix, '');

  return fullSlug.replace(defaultSlugSuffix, '');
};

interface CreateUrlSlugParams {
  slug: string;
  countryCode?: CountryCodes;
  language?: string;
  type?: string;
}

export const createUrlSlug = ({
  slug,
  countryCode,
  language,
  type,
}: CreateUrlSlugParams) => {
  const isIndonesia = isCountryCodeID(countryCode);
  const isVietnam = isCountryCodeVN(countryCode);

  const isIndonesianLanguage = language === 'id';
  const isVietnameseLanguage = language === 'vi';

  // If the user is in Vietnam, and using the Vietnamese language, return the slug with the vnSlugPrefix.
  if (isVietnam && isVietnameseLanguage) return `${vnSlugPrefix}${slug}`;

  // If the user is in Indonesia
  if (isIndonesia) {
    const isJobRoleSlugType = type === 'job-role';
    const isCspSlugType = type === 'find-jobs';

    // using the Indonesian language, return the slug with the idSlugPrefix.
    if (isIndonesianLanguage) {
      // If the slug type is job role, return the slug with the idJobRoleSlugPrefix.
      const slugPrefix = isJobRoleSlugType ? idJobRoleSlugPrefix : idSlugPrefix;
      return `${slugPrefix}${slug}`;
    }

    // Exception for CSP Links in EN version
    if (isCspSlugType) {
      return `${idSlugPrefix}${slug}`;
    }
  }

  // If not in Vietnam or Indonesia, or not using their respective language,return the slug with the defaultSlugSuffix.
  return `${slug}${defaultSlugSuffix}`;
};
