import { handleActions } from 'redux-actions';

import { Actions } from 'src/actions/countries';
import { DstAction } from 'src/middleware/api/interfaces';

export interface CountriesState {
  loading: boolean;
  error: any;
  value: string[];
}

export const initialState: CountriesState = {
  loading: false,
  error: null,
  value: [],
};

export const rearrangeCountries = (
  value: CountriesState['value'],
  payload: string
) => {
  const countries = value;
  const country = payload.toUpperCase();
  countries.splice(countries.indexOf(country), 1);
  countries.unshift(country);
  return countries;
};

export default handleActions<CountriesState, any>(
  {
    [Actions.REARRANGE_COUNTRY_TO_FIRST]: (state, action) => ({
      ...state,
      value:
        state.value && state.value.indexOf(action.payload.toUpperCase()) > -1
          ? rearrangeCountries(state.value, action.payload)
          : state.value,
    }),
    [Actions.COUNTRIES_REQUEST]: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
    [Actions.COUNTRIES_SUCCESS]: (state, action: DstAction) => ({
      ...state,
      loading: false,
      error: null,
      value: action.response.result,
    }),
    [Actions.COUNTRIES_SUCCESS_ADD]: (state, action: DstAction) => ({
      ...state,
      loading: false,
      value: [...new Set([...state.value, ...action.response.result])],
      error: null,
    }),
    [Actions.COUNTRIES_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
      value: [],
    }),
  },
  initialState
);
