import { get } from 'lodash';
import { normalize, Schema } from 'normalizr';
import { toast } from 'react-toastify';
import { ThunkMiddleware } from 'redux-thunk';

import { showCloseableNotificationWithType } from 'src/actions/notifications';
import { CALL_API } from 'src/common/constants';
import { APIVersion } from 'src/common/enums';
import { isError409 } from 'src/common/ErrorHelpers';
import { State } from 'src/global/store';
import { setTokenIsNotValid } from 'src/modules/Session/Actions';
import { getIsAuthenticated } from 'src/modules/Session/Selectors';
import { getLanguage } from 'src/selectors/locale';

import { makeApiCall, makeFullUrl, makeFullV2Url } from './helpers';
import { CallAPIOptions, DstAction, RequestMethod } from './interfaces';
/**
 * Fetches an API response and normalizes the result JSON according to schema.
 * This makes every API response have the same shape, regardless of how nested it was.
 */
async function callApi(
  fullUrl: string,
  schema: Schema,
  options: CallAPIOptions,
  method: RequestMethod = 'get'
) {
  const response = await makeApiCall(fullUrl, options, method);
  if (!response.data) {
    return { result: null, isAuthenticated: false };
  }
  let result = response.data.data;
  const isAuthenticated = response.data.isAuthenticated;
  if (!result) {
    return { result: null, isAuthenticated };
  }
  // Collections include a count to indicate the total number of items.
  if (schema) {
    if (response.data.count) {
      result = {
        ...normalize(response.data.data, schema),
        count: response.data.count,
      };
    } else {
      result = { ...normalize(response.data.data, schema) };
    }
  }
  return { result, isAuthenticated };
}

/**
 * A Redux middleware that interprets actions with CALL_API info specified.
 * Performs the call and promises when such actions are dispatched.
 */
const apiMiddleware: ThunkMiddleware<State> =
  (store) => (next) => async (action) => {
    const state = store.getState();
    /* eslint callback-return:0 */
    const callAPI = action[CALL_API];
    if (typeof callAPI === 'undefined') {
      return next(action);
    }

    let { endpoint } = callAPI;
    const { schema, types, hooks, method, version = APIVersion.V1 } = callAPI;
    let options = callAPI.options || {};

    options = {
      ...options,
      headers: { ...options.headers, 'accept-language': getLanguage(state) },
    };

    // this is only used when the request is sent on server side
    //   the cookie is from client, if we don't do this, the request from server side will have different session
    const cookie = get(state, 'session.cookie');
    if (cookie) {
      options = { ...options, headers: { ...options.headers, cookie } };
    }

    const triggerToast =
      hooks && hooks.triggerToast ? hooks.triggerToast : null;
    const triggerAlert =
      hooks && hooks.triggerAlert ? hooks.triggerAlert : null;

    if (typeof endpoint === 'function') {
      endpoint = endpoint(state);
    }

    if (typeof endpoint !== 'string') {
      throw new Error('Specify a string endpoint URL.');
    }

    if (!Array.isArray(types) || types.length !== 3) {
      throw new Error('Expected an array of 3 action types.');
    }

    if (!types.every((type) => typeof type === 'string')) {
      throw new Error('Expected action types to be strings.');
    }

    function actionWith(data: DstAction) {
      const finalAction = { ...action, ...data };
      delete finalAction[CALL_API];
      return finalAction;
    }

    const [requestType, successType, failureType] = types;
    const response =
      hooks && hooks.requestPayload
        ? { requestPayload: hooks.requestPayload }
        : null;

    next(
      actionWith({
        response,
        type: requestType,
      })
    );
    if (triggerToast && triggerToast.requestMsg) {
      toast.info(triggerToast.requestMsg);
    }

    if (triggerAlert && triggerAlert.requestMsg) {
      await next(
        showCloseableNotificationWithType({
          message: triggerAlert.requestMsg,
          type: 'danger',
        })
      );
    }

    try {
      const data = await callApi(
        version === APIVersion.V2
          ? makeFullV2Url(endpoint, state)
          : makeFullUrl(endpoint, state),
        schema,
        options,
        method
      );

      const { isAuthenticated } = data;
      let { result: response } = data;

      if (!isAuthenticated && getIsAuthenticated(state)) {
        next(setTokenIsNotValid());
      }

      if (hooks && hooks.successPayload) {
        response = { ...response, successPayload: hooks.successPayload };
      }

      next(
        actionWith({
          response,
          type: successType,
          payload: hooks && hooks.successPayload,
        })
      );
      if (triggerToast && triggerToast.successMsg) {
        toast.success(triggerToast.successMsg);
      }
      if (triggerAlert && triggerAlert.successMsg) {
        await next(
          showCloseableNotificationWithType({
            message: triggerAlert.successMsg,
            type: 'success',
          })
        );
      }
    } catch (err) {
      next(
        actionWith({
          error: err,
          response:
            hooks && hooks.failurePayload
              ? { failurePayload: hooks.failurePayload }
              : null,
          type: failureType,
        })
      );
      if (triggerToast) {
        if (triggerToast.alreadyAppliedMsg && isError409(err.response)) {
          toast.error(triggerToast.alreadyAppliedMsg);
        } else if (triggerToast.failureMsg) {
          toast.error(triggerToast.failureMsg);
        }
      }

      if (triggerAlert && triggerAlert.failureMsg) {
        await next(
          showCloseableNotificationWithType({
            message: triggerAlert.failureMsg,
            type: 'danger',
          })
        );
      }
    }
  };

export default apiMiddleware;
