import get from 'lodash/get';

/* Assumption that any error is from action.payload.response or action.error.response */

export const isError409 = (error: unknown) => get(error, 'status') === 409;
export const isError400 = (error: unknown) => get(error, 'status') === 400;

export const errorDetails = (error: unknown) =>
  get(error, 'data.error.details').length
    ? get(error, 'data.error.details')[0]
    : null;
