import React, { useCallback, useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { useUrlUtils } from 'src/common/hooks/useUrlUtils';
import ConsoleErrorBoundary from 'src/components/ConsoleErrorBoundary';
import { getConfig } from 'src/modules/ClientConfig';

import { AssetType, GlintsPictureProps } from './interfaces';
import { ThumborImage } from './ThumborImage';

const GlintsPicture: React.FC<React.PropsWithChildren<GlintsPictureProps>> = (
  props: GlintsPictureProps
) => {
  const {
    name,
    className,
    assetType = AssetType.images,
    fallback,
    fallbackAssetType,
    sizes,
    isCircleImage = false,
    ...thumborProps
  } = props;

  const [measured, setMeasured] = useState(Boolean(sizes));
  const [measuredWidth, setMeasuredWidth] = useState('');
  const [isError, setIsError] = useState(false);
  const [fallbackIsError, setFallbackIsError] = useState(false);

  const thumborBaseUrl = useSelector(getConfig).THUMBOR_BASE;

  const { getImageAssetURL } = useUrlUtils();

  const isURLImage = name?.includes('https');

  const handleMeasure = useCallback((node: any) => {
    const width = get(node, 'parentNode.clientWidth');
    setMeasured(true);
    setMeasuredWidth(`${width}px`);
  }, []);

  const handleOnError = () => {
    if (!isError) {
      setIsError(true);
    } else if (!fallbackIsError) {
      // Fallback can also encounter errors.
      setFallbackIsError(true);
    }
  };

  if (!measured) {
    return <div className={className} ref={handleMeasure} />;
  }

  // Handle error conditions, like 404 on image load.
  let assetName = null;
  let validAssetType = null;

  if (isError) {
    if (fallbackIsError) {
      // Last ditch attempt to load also failed, don't render this image.
      console.warn(
        'Failed to load image asset and fallback',
        assetType,
        name,
        fallback
      );
      return null;
    }

    if (!fallback) {
      // No fallback specified, bail.
      console.warn(
        'Failed to load image asset and no fallback specified',
        assetType,
        name
      );
      return null;
    }
    // Fallback specified, try the fallback.
    assetName = fallback;
    validAssetType = fallbackAssetType ? fallbackAssetType : assetType;
  } else {
    const useFallback = !name;
    assetName = useFallback ? fallback : name;
    validAssetType = useFallback ? fallbackAssetType : assetType;
  }

  const isEmbeddedImage = assetName?.includes('images/');
  const src = isURLImage
    ? name
    : isEmbeddedImage
    ? assetName
    : getImageAssetURL(assetName ?? '', validAssetType ?? '');

  return (
    <ConsoleErrorBoundary componentName="ThumborImage">
      <ThumborImage
        className={className}
        onError={handleOnError}
        serverURL={thumborBaseUrl}
        src={src ?? ''}
        sizes={sizes ? sizes : measuredWidth}
        isURLImage={isURLImage}
        isCircleImage={isCircleImage}
        isEmbeddedImage={isEmbeddedImage}
        {...thumborProps}
      />
    </ConsoleErrorBoundary>
  );
};

export default GlintsPicture;
