import { createContext, useContext } from 'react';

type DownloadAppPromotionContext = {
  openDownloadAppPromotionModal: () => void;
};

export const DownloadAppPromotionContext = createContext<
  DownloadAppPromotionContext | undefined
>(undefined);

class UndefinedDownloadAppPropmotionContextError extends Error {
  static message =
    'useDownloadAppPromotionState must be used within MainContainer';
  constructor(message?: string) {
    super(message || UndefinedDownloadAppPropmotionContextError.message);
  }
}

export function useDownloadAppPromotionState() {
  const context = useContext(DownloadAppPromotionContext);
  if (!context) {
    throw new UndefinedDownloadAppPropmotionContextError();
  }
  return context;
}
