import Moment from 'moment';
import { handleActions } from 'redux-actions';

import { Actions, UpdateMePayload } from 'src/actions/user/me';
import { User } from 'src/global/models/User';
import { DstAction } from 'src/middleware/api/interfaces';

interface ResumeDetails {
  name: string;
  lastModified: string;
  size: string;
  uploadedName: string;
}

type SelectedUserValues = Partial<Omit<User, 'birthDate'>>;

export interface MeValue extends SelectedUserValues {
  birthDate: string | Moment.Moment;
  resumeDetails?: ResumeDetails;
  preferences?: any; // legacy attribute
  whatsappLoginNumber?: string;
}

export interface MeState {
  fetching: boolean;
  isError: boolean;
  errorDetails: string;
  missingFields: {
    fetching: boolean;
    error: any;
    value: string[];
  };
  value: MeValue;
  resume: string;
  isUploadingResume: boolean;
}

export const initialState: MeState = {
  fetching: false,
  isError: null,
  errorDetails: null,

  missingFields: {
    fetching: false,
    error: null,
    value: [],
  },

  value: {
    email: null,
    firstName: null,
    lastName: null,
    phone: null,
    birthDate: null,
    gender: null,
    race: null,
    CountryCode: null,
    metadata: {},
    resume: null,
    resumeDetails: null,
    portfolio: {
      document: '',
      website: '',
    },
    preferredLanguage: null,
  },
  resume: null,
  isUploadingResume: false,
};

export default handleActions<MeState, any>(
  {
    [Actions.FETCH_ME_REQUEST]: (state) => ({
      ...state,
      fetching: true,
    }),
    [Actions.FETCH_ME_SUCCESS]: (state, action: DstAction) => ({
      ...state,
      fetching: false,
      value: {
        ...action.response.entities.user[action.response.result],
        birthDate:
          typeof action.response.entities.user[action.response.result]
            .birthDate === 'string'
            ? Moment(
                action.response.entities.user[action.response.result].birthDate
              ).utc()
            : null,
      },
    }),
    [Actions.FETCH_ME_FAILURE]: (state) => ({
      ...state,
      fetching: false,
      value: state.value,
      isError: true,
    }),
    [Actions.PERSIST_ME_REQUEST]: (state) => ({
      ...state,
      isError: null,
      errorDetails: null,
    }),
    [Actions.PERSIST_ME_SUCCESS]: (state, action: DstAction) => ({
      ...state,
      value: {
        ...action.response.entities.user[action.response.result],
        birthDate:
          typeof action.response.entities.user[action.response.result]
            .birthDate === 'string'
            ? Moment(
                action.response.entities.user[action.response.result].birthDate
              ).utc()
            : null,
      },
    }),
    [Actions.PERSIST_ME_FAILURE]: (state, action: DstAction) => ({
      ...state,
      isError: true,
      errorDetails: action.error.response?.data?.error?.details[0],
    }),
    [Actions.CLEAR_ME]: () => Object.assign({}, initialState),
    [Actions.UPLOAD_RESUME_START]: (state) => ({
      ...state,
      fetching: true,
      isUploadingResume: true,
    }),
    [Actions.UPDATE_RESUME]: (state, action) => {
      // Modified resume upload to include more details for use in new onboarding step,
      // while being backwards compatible
      const result = {
        ...state,
        fetching: false,
        isUploadingResume: false,
        value: {
          ...state.value,
        },
      };
      if (typeof action.payload === 'string') {
        result.value.resume = action.payload;
      } else if (action.payload === null) {
        result.value.resume = null;
        result.value.resumeDetails = null;
      } else {
        const { uploadedName, name, size, lastModified } = action.payload;
        result.value.resumeDetails = {
          name,
          lastModified,
          size,
          uploadedName,
        };
        result.value.resume = uploadedName;
      }
      return result;
    },
    [Actions.UPDATE_META]: (state, action) => ({
      ...state,
      value: {
        ...state.value,
        metadata: {
          ...state.value.metadata,
          ...action.payload,
        },
      },
    }),
    [Actions.UPDATE_ME]: (state, action: DstAction<UpdateMePayload>) => {
      const temp = { ...state.value };
      const fieldNames = action.payload.field.split('.');
      if (fieldNames.length === 1) {
        // @ts-ignore
        temp[action.payload.field] = action.payload.value;
      } else {
        const fieldNameItem = fieldNames[0] as keyof MeValue;
        if (!temp[fieldNameItem]) {
          // @ts-ignore
          temp[fieldNameItem] = {};
        }
        temp[fieldNameItem][fieldNames[1]] = action.payload.value;
      }
      return {
        ...state,
        value: temp,
      };
    },
    [Actions.UPDATE_ME_VALUE]: (state, action) => ({
      ...state,
      value: action.payload || initialState.value,
    }),
    [Actions.REQUEST_MISSING_FIELDS]: (state) => ({
      ...state,
      missingFields: {
        ...state.missingFields,
        fetching: true,
        error: null,
      },
    }),
    [Actions.RECEIVE_MISSING_FIELDS]: (state, action) => ({
      ...state,
      missingFields: {
        ...state.missingFields,
        fetching: false,
        error: action.error,
        value: action.error ? state.missingFields.value : action.payload,
      },
    }),
  },
  initialState
);
