export enum Actions {
  SET_IS_ONPOPSTATE = 'glints/history/SET_IS_ONPOPSTATE',
  RESET_IS_ONPOPSTATE = 'glints/history/RESET_IS_ONPOPSTATE',
  PUSH_AS_PATH = 'glints/history/PUSH_AS_PATH',
}

export const setIsOnpopstate = (isOnpopstate: boolean): HistoryActionTypes => ({
  type: Actions.SET_IS_ONPOPSTATE,
  payload: {
    isOnpopstate,
  },
});

interface SetIsOnpopstate {
  type: typeof Actions.SET_IS_ONPOPSTATE;
  payload: {
    isOnpopstate: boolean;
  };
}

export const resetIsOnpopstate = (): HistoryActionTypes => ({
  type: Actions.RESET_IS_ONPOPSTATE,
});

interface ResetIsOnpopstate {
  type: typeof Actions.RESET_IS_ONPOPSTATE;
}

export const pushAsPath = (asPath: string): HistoryActionTypes => ({
  type: Actions.PUSH_AS_PATH,
  payload: {
    asPath,
  },
});

interface PushAsPath {
  type: typeof Actions.PUSH_AS_PATH;
  payload: {
    asPath: string;
  };
}

export type HistoryActionTypes =
  | SetIsOnpopstate
  | ResetIsOnpopstate
  | PushAsPath;
