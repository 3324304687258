// Integrates Google Tag Manager with a Redux store.
// https://medium.com/@j3y/analytics-with-react-redux-spas-and-google-tag-manager-4d3a55833619
const GTMMiddleware = () => next => action => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: action.type,
    payload: action.payload,
  });
  return next(action);
};

export default GTMMiddleware;
