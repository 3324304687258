import { defineMessage } from 'react-intl';

export const settingPageLinkMessage = defineMessage({
  id: 'user-menu_settings-page-link',
  defaultMessage: 'Account Settings',
});

export const employerSiteLinkMessage = defineMessage({
  id: 'for.employers',
  defaultMessage: 'FOR EMPLOYERS',
});
