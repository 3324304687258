export enum DefaultExperienceYear {
  MinYear = 'minYear',
  MaxYear = 'maxYear',
}

export enum ExperienceValueLabel {
  NoExperience = 'NO_EXPERIENCE',
  FreshGrad = 'FRESH_GRAD',
  LessThanOneYear = 'LESS_THAN_A_YEAR',
  OneToThreeYears = 'ONE_TO_THREE_YEARS',
  ThreeToFiveYears = 'THREE_TO_FIVE_YEARS',
  FiveToTenYears = 'FIVE_TO_TEN_YEARS',
  MoreThanTenYears = 'MORE_THAN_TEN_YEARS',
}

export enum JobsSortByKey {
  DEFAULT = 'DEFAULT',
  LATEST = 'LATEST',
  RELEVANCY = 'RELEVANCY',
}

export enum LastUpdated {
  Past24Hours = 'PAST_24_HOURS',
  PastWeek = 'PAST_WEEK',
  PastMonth = 'PAST_MONTH',
  AnyTime = 'ANY_TIME',
}
