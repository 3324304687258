import { mapValues } from 'lodash';
import { useSelector } from 'react-redux';

import { CountryCodes } from 'src/common/enums';
import { getConfig } from 'src/modules/ClientConfig/Selectors';

export default function useConfig(selectors?: $TSFixMe) {
  const config = useSelector((state) => getConfig(state));

  if (!selectors) {
    return config;
  }
  return mapValues(selectors, function (selector) {
    return config[selector];
  });
}

export function useCountry(defaultCountry = CountryCodes.SG): CountryCodes {
  const country = useConfig({ country: 'COUNTRY' }).country || defaultCountry;
  return country;
}

export function useBasename() {
  const basename = useConfig({ basename: 'BASENAME' }).basename;
  if (!basename || basename === '/') {
    return null;
  }

  return basename;
}

export function useEmployersURL() {
  const employersBase = useConfig({
    employersBaseURL: 'EMPLOYERS_BASE',
  }).employersBaseURL;

  const country = useCountry();

  switch (country) {
    case CountryCodes.ID:
      return employersBase.replace('.com', '.id');
    case CountryCodes.MY:
      return employersBase.replace('.com', '.my');
    case CountryCodes.VN:
      return employersBase.replace('.com', '.vn');
    case CountryCodes.TW:
      return employersBase.replace('.com', '.tw');
    default:
      return 'https://talenthub.glints.com';
  }
}
