import React from 'react';
import { get, throttle } from 'lodash';
import { createAction } from 'redux-actions';

import { showCloseableNotificationWithType } from 'src/actions/notifications';
import { clearBookmarks, fetchMyBookmarks } from 'src/actions/user/bookmarks';
import { clearJobLocationPreferences } from 'src/actions/user/jobLocationPreferences';
import { clearJobRolePreferences } from 'src/actions/user/jobRolePreferences';
import { clearMe, fetchMe } from 'src/actions/user/me';
import { CALL_API, LocalStorageKeys } from 'src/common/constants';
import { Products } from 'src/common/enums';
import { stringifyPhoneNumber } from 'src/common/phoneNumberInputHelper';
import Schemas from 'src/common/schemas';
import { hasLocalStorage } from 'src/common/utils/hasLocalStorage';
import { SignInSource } from 'src/components/LoginModal/ExternalSignInButtons';
import { ReduxThunkAction } from 'src/global/store';
import { getConfig } from 'src/modules/ClientConfig';
import {
  getSessionToken,
  getSignUpSuccess,
} from 'src/modules/Session/Selectors';
import { fetchFeatures } from 'src/modules/Unleash/Actions';
import { setIsNewSignUp } from 'src/modules/Virality/Actions';
import { getLocale } from 'src/selectors/locale';
import { getAmplitudeUserProperties } from 'src/selectors/user';

import { broadcastLogout } from './BroadcastChannel';
import FacebookEmailErrorMessage from './Components/FacebookEmailErrorMessage';
import * as GTM from './GTMActions';
import { GTMActions } from './GTMActions';

type GtmData = {
  login: string;
  signup: string;
};

const getGtmDataWithDefaultValue = (gtmData: GtmData): GtmData => ({
  login: get(gtmData, 'login', Products.Marketplace),
  signup: get(gtmData, 'signup', Products.Marketplace),
});

export const Actions = {
  REQUEST_TOKEN: 'glints/session/REQUEST_TOKEN',
  RECEIVE_TOKEN: 'glints/session/RECEIVE_TOKEN',
  CLEAR_TOKEN: 'glints/session/CLEAR_TOKEN',
  UPDATE_SOCIAL_MEDIA_SIGNUP: 'glints/session/UPDATE_SOCIAL_MEDIA_SIGNUP',
  CLEAR_SOCIAL_MEDIA_SIGNUP: 'glints/session/CLEAR_SOCIAL_MEDIA_SIGNUP',
  CLEAR_RECEIVE_TOKEN_ERROR: 'glints/session/CLEAR_RECEIVE_TOKEN_ERROR',

  CREATE_USER_REQUEST: 'glints/session/CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'glints/session/CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'glints/session/CREATE_USER_FAILURE',
  CLEAR_CREATE_USER_ERROR: 'glints/session/CLEAR_CREATE_USER_ERROR',

  UPDATE_USER_META: 'glints/session/UPDATE_USER_META',
  CLEAR_USER_META: 'glints/session/CLEAR_USER_META',

  SEND_EMAIL_VERIFICATION_TOKEN_REQUEST:
    'glints/session/SEND_EMAIL_VERIFICATION_TOKEN_REQUEST',
  SEND_EMAIL_VERIFICATION_TOKEN_SUCCESS:
    'glints/session/SEND_EMAIL_VERIFICATION_TOKEN_SUCCESS',
  SEND_EMAIL_VERIFICATION_TOKEN_FAILURE:
    'glints/session/SEND_EMAIL_VERIFICATION_TOKEN_FAILURE',
  RESET_SEND_EMAIL_VERIFICATION_TOKEN:
    'glints/session/RESET_SEND_EMAIL_VERIFICATION_TOKEN',

  VERIFY_EMAIL_REQUEST: 'glints/session/VERIFY_EMAIL_REQUEST',
  VERIFY_EMAIL_SUCCESS: 'glints/session/VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILURE: 'glints/session/VERIFY_EMAIL_FAILURE',

  SET_EMAIL_VERIFICATION_SENT_AT:
    'glints/session/SET_EMAIL_VERIFICATION_SENT_AT',
  RESET_EMAIL_VERIFICATION_SENT_AT:
    'glints/session/RESET_EMAIL_VERIFICATION_SENT_AT',

  SET_TOKEN_IS_NOT_VALID: 'glints/session/SET_TOKEN_IS_NOT_VALID',
};

export const setTokenIsNotValid = createAction(Actions.SET_TOKEN_IS_NOT_VALID);

/**
 * Sets sentAt in redux.
 * If the user's browser has local storage, also stores sentAt in local storage.
 */
export const setEmailVerificationSentAt = (sentAt: number) => {
  if (hasLocalStorage()) {
    localStorage.setItem(
      LocalStorageKeys.emailVerificationRequestSentAt,
      sentAt.toString()
    );
  }
  return {
    type: Actions.SET_EMAIL_VERIFICATION_SENT_AT,
    payload: sentAt,
  };
};

/**
 * Resets sentAt in redux.
 * If the user's browser has local storage, also removes sentAt from local storage.
 */
export const resetEmailVerificationSentAt = () => {
  if (hasLocalStorage()) {
    localStorage.removeItem(LocalStorageKeys.emailVerificationRequestSentAt);
  }
  return {
    type: Actions.RESET_EMAIL_VERIFICATION_SENT_AT,
  };
};

export const requestToken = createAction(Actions.REQUEST_TOKEN);

export const receiveToken = createAction(
  Actions.RECEIVE_TOKEN,
  (token: string, err: Error) => (err ? err : token)
);
export const clearToken = createAction(Actions.CLEAR_TOKEN);
export const updateSocialMediaSignup = createAction(
  Actions.UPDATE_SOCIAL_MEDIA_SIGNUP
);
export const clearSocialMediaSignup = createAction(
  Actions.CLEAR_SOCIAL_MEDIA_SIGNUP
);
export const clearReceiveTokenError = createAction(
  Actions.CLEAR_RECEIVE_TOKEN_ERROR
);

export const clearCreateUserError = createAction(
  Actions.CLEAR_CREATE_USER_ERROR
);

export const updateUserMeta = createAction(Actions.UPDATE_USER_META);
export const clearUserMeta = createAction(Actions.CLEAR_USER_META);

export const sendEmailVerificationTokenRequest = createAction(
  Actions.SEND_EMAIL_VERIFICATION_TOKEN_REQUEST
);
export const sendEmailVerificationTokenSuccess = createAction(
  Actions.SEND_EMAIL_VERIFICATION_TOKEN_SUCCESS
);
export const sendEmailVerificationTokenFailure = createAction(
  Actions.SEND_EMAIL_VERIFICATION_TOKEN_FAILURE
);

export const resetSendEmailVerificationToken = createAction(
  Actions.RESET_SEND_EMAIL_VERIFICATION_TOKEN
);

export interface LoginGTMOptions {
  productSource?: Products;
  shouldDispatchLoginSuccess: boolean;
}

export function fetchUserData(): ReduxThunkAction<any> {
  return async (dispatch) => {
    await Promise.all([
      dispatch(fetchMe()),
      dispatch(fetchFeatures()),
      dispatch(fetchMyBookmarks()),
    ]);
  };
}

/* We only want to trigger the GTM action upon successful login after
login button click. Since the signup action also uses this login action,
we add the boolean flag to determine when to trigger the GTM action  */
export function login(
  email: string,
  password: string,
  gtmOptions = {
    shouldDispatchLoginSuccess: true,
    productSource: Products.Marketplace,
  } as LoginGTMOptions
): ReduxThunkAction<any> {
  return async (dispatch, getState, { api }) => {
    const config = getConfig(getState());
    const {
      shouldDispatchLoginSuccess = true,
      productSource = Products.Marketplace,
    } = gtmOptions;

    try {
      dispatch(requestToken());
      const response = await api(null, config.OAUTH2_BASE).post('token', {
        grant_type: 'password',
        client_id: config.CLIENT_ID,
        username: email,
        password,
      });
      dispatch(receiveToken(response.data['access_token'], null));

      if (shouldDispatchLoginSuccess) {
        dispatch(GTM.LoginWithEmailSuccess(productSource));
      }
    } catch (err) {
      dispatch(receiveToken(null, err));
      dispatch(GTM.LoginWithEmailFailure());
    }

    if (getSessionToken(getState())) {
      await dispatch(fetchUserData());
    }
  };
}

export function cleanDataWhenLogout(): ReduxThunkAction<any> {
  return (dispatch, getState) => {
    const state = getState();
    const payload = getAmplitudeUserProperties(state);

    dispatch({
      type: GTMActions.LOGOUT_SUCCESS,
      payload,
    });
    dispatch(clearToken());
    dispatch(clearMe());
    dispatch(clearJobRolePreferences());
    dispatch(clearJobLocationPreferences());
    dispatch(clearUserMeta());
    dispatch(clearBookmarks());
    dispatch(fetchFeatures());
  };
}

const throttledLogout = throttle<ReduxThunkAction<any>>(
  async (dispatch, getState, { api }) => {
    // for those users who come back when the token is expired,
    // the request will get 401 cause frontend server doesn't have the token already
    // dispatch cleanDataWhenLogout in finally to make sure those states are cleaned properly
    try {
      // logout from the server.
      await api(null, null).post('/auth/logout');
    } catch (err) {
      const responseStatus = get(err, 'response.status');
      if (401 === responseStatus) {
        return;
      }
      throw err;
    } finally {
      broadcastLogout();

      // clear redux state and stored data in localstorage.
      dispatch(cleanDataWhenLogout());
    }
  },
  5000,
  { trailing: false }
);

export function logout(): ReduxThunkAction<any> {
  return throttledLogout;
}

export function signUp(
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  phone: string,
  CityId: number,
  CountryCode: string,
  sendUpdates: boolean,
  gtmPayloadString = Products.Marketplace
): ReduxThunkAction<any> {
  return async (dispatch, getState) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
      phone,
      CityId,
      CountryCode,
      metadata: { sendUpdates },
      preferredLanguage: getLocale(getState()).language,
    };

    data.phone = stringifyPhoneNumber(JSON.parse(phone));

    await dispatch({
      [CALL_API]: {
        types: [
          Actions.CREATE_USER_REQUEST,
          Actions.CREATE_USER_SUCCESS,
          Actions.CREATE_USER_FAILURE,
        ],
        endpoint: 'users',
        schema: Schemas.USER,
        method: 'post',
        options: {
          data: data,
        },
        hooks: {
          successPayload: gtmPayloadString,
        },
      },
    });

    const signupSuc = getSignUpSuccess(getState());

    if (signupSuc) {
      dispatch(GTM.SignupWithEmailSuccess());
      dispatch(setIsNewSignUp(true));
      await dispatch(
        login(email, password, {
          shouldDispatchLoginSuccess: false,
        })
      );
    }
  };
}

export function signUpWithHierarchicalLocation(
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  phone: string,
  whatsappNumber: string,
  locationId: string,
  CountryCode: string,
  sendUpdates: boolean,
  gtmPayloadString = Products.Marketplace
): ReduxThunkAction<any> {
  return async (dispatch, getState) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
      phone,
      whatsappNumber: whatsappNumber ? `+62${whatsappNumber}` : null,
      LocationId: locationId,
      CountryCode,
      metadata: { sendUpdates },
      preferredLanguage: getLocale(getState()).language,
    };

    data.phone = stringifyPhoneNumber(JSON.parse(phone));

    await dispatch({
      [CALL_API]: {
        types: [
          Actions.CREATE_USER_REQUEST,
          Actions.CREATE_USER_SUCCESS,
          Actions.CREATE_USER_FAILURE,
        ],
        endpoint: 'users',
        schema: Schemas.USER,
        method: 'post',
        options: {
          data: data,
        },
        hooks: {
          successPayload: gtmPayloadString,
        },
      },
    });

    const signupSuc = getSignUpSuccess(getState());

    if (signupSuc) {
      dispatch(GTM.SignupWithEmailSuccess());
      dispatch(setIsNewSignUp(true));
      await dispatch(
        login(email, password, {
          shouldDispatchLoginSuccess: false,
        })
      );
    }
  };
}

export function loginWithFacebook(
  code: string,
  gtmData: any,
  signInSource: SignInSource
): ReduxThunkAction<any> {
  return async (dispatch, getState, { api }) => {
    dispatch(requestToken());
    const config = getConfig(getState());
    const { login: gtmLogin, signup: gtmSignUp } =
      getGtmDataWithDefaultValue(gtmData);
    try {
      const response = await api(null, config.OAUTH2_BASE).post('/facebook', {
        code,
        clientId: config.FACEBOOK_APPID,
        apiClientId: config.CLIENT_ID,
        redirectUri: `${
          document.getElementsByTagName('base')[0].href
        }oauth2/facebook`,
      });
      dispatch(receiveToken(response.data.token, null));
      if (response.data.signup) {
        dispatch(updateSocialMediaSignup('facebook'));
        dispatch(GTM.SignupWithFacebookSuccess(gtmSignUp));
        dispatch(setIsNewSignUp(true));
      } else {
        dispatch(GTM.LoginWithFacebookSuccess(gtmLogin));
      }
    } catch (err) {
      console.error(err);
      if (err.response?.data?.error?.details?.[0] === 'No email provided') {
        dispatch(
          showCloseableNotificationWithType({
            message: <FacebookEmailErrorMessage />,
            type: 'danger',
            autoCloseInterval: 300000,
          })
        );
      }
      dispatch(receiveToken(null, err));
      dispatch(
        GTM.LoginWithFacebookFailed({
          source: signInSource,
          errorMessage: err?.message,
        })
      );
    }

    if (getSessionToken(getState())) {
      await dispatch(fetchUserData());
    }
  };
}

export function loginWithLinkedIn(
  code: string,
  csrfToken: string,
  gtmData: any,
  signInSource: SignInSource
): ReduxThunkAction<any> {
  return async (dispatch, getState, { api }) => {
    dispatch(requestToken());
    const config = getConfig(getState());
    const { login: gtmLogin, signup: gtmSignUp } =
      getGtmDataWithDefaultValue(gtmData);
    try {
      const response = await api(null, config.OAUTH2_BASE).post('/linkedin', {
        code,
        clientId: config.LINKEDIN_APPID,
        apiClientId: config.CLIENT_ID,
        redirectUri: `${
          document.getElementsByTagName('base')[0].href
        }oauth2/linkedin`,
        state: csrfToken,
      });
      dispatch(receiveToken(response.data.token, null));
      if (response.data.signup) {
        dispatch(updateSocialMediaSignup('linkedin'));
        dispatch(GTM.SignupWithLinkedinSuccess(gtmSignUp));
        dispatch(setIsNewSignUp(true));
      } else {
        dispatch(GTM.LoginWithLinkedinSuccess(gtmLogin));
      }
    } catch (err) {
      dispatch(receiveToken(null, err));
      dispatch(
        GTM.LoginWithLinkedinFailed({
          source: signInSource,
          errorMessage: err?.message,
        })
      );
    }

    if (getSessionToken(getState())) {
      await dispatch(fetchUserData());
    }
  };
}

export function loginWithGoogle(
  token: string,
  gtmData: any,
  signInSource: SignInSource
): ReduxThunkAction<any> {
  return async (dispatch, getState, { api }) => {
    dispatch(requestToken());
    const config = getConfig(getState());
    const { login: gtmLogin, signup: gtmSignUp } =
      getGtmDataWithDefaultValue(gtmData);
    try {
      const response = await api(null, config.OAUTH2_BASE).post('/google', {
        idToken: token,
        apiClientId: config.CLIENT_ID,
        redirectUri: `${
          document.getElementsByTagName('base')[0].href
        }oauth2/google`,
      });
      dispatch(receiveToken(response.data.token, null));
      if (response.data.signup) {
        dispatch(updateSocialMediaSignup('google'));
        const signUpAction =
          signInSource === 'one tap'
            ? GTM.SignupWithGoogleOneTapSuccess(gtmSignUp)
            : GTM.SignupWithGoogleSuccess(gtmSignUp);
        dispatch(signUpAction);
        dispatch(setIsNewSignUp(true));
      } else {
        const loginAction =
          signInSource === 'one tap'
            ? GTM.LoginWithGoogleOneTapSuccess(gtmLogin)
            : GTM.LoginWithGoogleSuccess(gtmLogin);
        dispatch(loginAction);
      }
    } catch (err) {
      dispatch(receiveToken(null, err));
      dispatch(
        GTM.LoginWithGoogleFailed({
          source: signInSource,
          errorMessage: err?.message,
        })
      );
    }

    if (getSessionToken(getState())) {
      await dispatch(fetchUserData());
    }
  };
}

export function sendEmailVerificationToken(
  onSuccess?: () => void
): ReduxThunkAction<any> {
  return async (dispatch, getState, { api }) => {
    try {
      dispatch(sendEmailVerificationTokenRequest());
      await api().post('/users/sendEmailVerificationToken');
      dispatch(sendEmailVerificationTokenSuccess());
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      dispatch(sendEmailVerificationTokenFailure(err));
    }
  };
}

interface VerifyEmailParams {
  email: string;
  id: string;
  token: string;
}

export function verifyEmail({
  token,
  id,
  email,
}: VerifyEmailParams): ReduxThunkAction<any> {
  return async (dispatch) => {
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.VERIFY_EMAIL_REQUEST,
          Actions.VERIFY_EMAIL_SUCCESS,
          Actions.VERIFY_EMAIL_FAILURE,
        ],
        endpoint: 'users/verifyEmail',
        schema: Schemas.USER,
        method: 'post',
        options: {
          token,
          id,
          email,
        },
      },
    });
  };
}
