import React from 'react';
import { Greyscale, PrimaryColor } from 'glints-aries';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Badge = styled.span`
  background-color: ${PrimaryColor.glintsyellow};
  color: ${Greyscale.black};
  font-weight: bold;
  text-transform: uppercase;
`;

export const NewFeatureBadge = () => (
  <Badge>
    <FormattedMessage id="navbar_new-feature-badge" defaultMessage="New" />
  </Badge>
);
