import { handleActions } from 'redux-actions';
import { IClientConfigVars } from 'shared/interfaces/ClientConfig';

import { Actions } from './Actions';

export type ReducerState = IClientConfigVars;

export const initialState: ReducerState = {};

export default handleActions<ReducerState, any>(
  {
    [Actions.SET_CLIENT_CONFIG]: (state, action) =>
      Object.assign({}, state, action.payload),
  },
  initialState
);
