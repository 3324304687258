import { gql } from '@apollo/client';

export const speakerWithRatingFragment = gql`
  fragment speakerWithRatingFragment on User {
    id
    ugcFullName
    profilePic
    expertRating {
      finalRating
    }
    presentExperiences {
      id
      title
      organization
    }
  }
`;
