import React, { Component } from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from 'apollo-utilities';
import fetch from 'isomorphic-fetch';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import isServer from 'src/common/isServer';
import cache from 'src/graphql/cache';
import { setTokenIsNotValid } from 'src/modules/Session/Actions';
import { getIsAuthenticated } from 'src/modules/Session/Selectors';
import { getLanguage } from 'src/selectors/locale';

import { getConfig } from '../ClientConfig';
import { ApiV2AlcClientName, ApiV2ClientName } from './constants';

class ApolloWrapperProviderClass extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    candidateBaseUrl: PropTypes.string.isRequired,
    enableWebsocketReconnect: PropTypes.bool.isRequired,
    websocketReconnectTimes: PropTypes.number,
    enableWebsocket: PropTypes.bool,
    dispatch: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    locale: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const {
      candidateBaseUrl,
      enableWebsocketReconnect,
      enableWebsocket,
      locale,
    } = props;
    if (!enableWebsocket) {
      return;
    }
    const candidateUrlObj = new URL('/api/graphql', candidateBaseUrl);
    candidateUrlObj.protocol =
      candidateUrlObj.protocol === 'https:' ? 'wss' : 'ws';

    // don't apply any middleware for now
    // might need to add a middleware to check the isAuthenticated
    // but the possibility of user sending message with expired access token is very low
    // (because we check access token every message and http request,
    // this should only happens when users keep their tab active and not doing anything for more than 3.5 days)
    const wsLink = new WebSocketLink({
      uri: candidateUrlObj,
      options: {
        lazy: true,
        reconnect: enableWebsocketReconnect,
        connectionParams: async () => {
          return {
            headers: {
              'accept-language': locale,
            },
          };
        },
      },
    });

    const gtmAfterware = new ApolloLink((operation, forward) => {
      return forward(operation).map(res => {
        // only push to GTM dataLayer when there is no error from the query/mutation
        if (!res.errors) {
          window.dataLayer = window.dataLayer || [];
          // clear payload and graphqlResData
          window.dataLayer.push({
            payload: undefined,
            graphqlResData: undefined,
          });
          window.dataLayer.push({
            event: operation.operationName,
            payload: operation.variables,
            graphqlResData: res.data,
          });
        }
        return res;
      });
    });

    const resAfterware = new ApolloLink((operation, forward) => {
      return forward(operation).map(res => {
        if (!res.isAuthenticated && this.props.isAuthenticated) {
          this.props.dispatch(setTokenIsNotValid());
        }
        return res;
      });
    });

    const localeHeaderLink = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'Accept-Language': locale,
        },
      }));

      return forward(operation);
    });

    const customFetch = (uri, options) => {
      // Append operation name to the query string
      // e.g. /api/graphql?operationName=searchJobs
      const { body } = options;
      const { operationName } = JSON.parse(body);
      return fetch(`${uri}?op=${operationName}`, options);
    };

    const httpLinkV1 = new HttpLink({
      uri: new URL('/api/graphql', candidateBaseUrl).href,
      fetch: customFetch,
      credentials: 'include',
    });
    const httpLinkV2 = new HttpLink({
      uri: new URL('/api/v2/graphql', candidateBaseUrl).href,
      fetch: customFetch,
      credentials: 'include',
    });
    const httpLinkV2Alc = new HttpLink({
      uri: new URL('/api/v2-alc/graphql', candidateBaseUrl).href,
      fetch: customFetch,
      credentials: 'include',
    });

    const terminatingLink = split(
      operation => operation.getContext().clientName === ApiV2ClientName,
      httpLinkV2,
      split(
        operation => operation.getContext().clientName === ApiV2AlcClientName,
        httpLinkV2Alc,
        httpLinkV1
      )
    );

    const httpLinkWithMiddleAfterware = [
      isServer ? null : gtmAfterware,
      resAfterware,
      localeHeaderLink,
      terminatingLink,
    ];

    const link = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      from(compact(httpLinkWithMiddleAfterware))
    );

    this.client = new ApolloClient({
      ssrMode: false,
      link,
      cache,
      // ssrForceFetchDelay skip force fetching during initialization
      // even queries with fetchPolicy network-only or cache-and-network
      // run using cache. However there is a bug in apollo, we need to
      // set it to 0 for ssr: false queries.
      ssrForceFetchDelay: isEmpty(window.__NEXT_DATA__.props.apolloCache)
        ? 0
        : 100,
    });
    this.wsClient = wsLink.subscriptionClient;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
      this.reconnectWebsocket();
    }
  }

  reconnectWebsocket() {
    // ignore if the websocket connection is not established
    if (!this.wsClient || this.wsClient.status === 0) {
      return;
    }

    // because we enable websocket auto reconnect
    // it'll try to reconnect back after the connection is closed
    this.wsClient.close();
  }

  render() {
    const { enableWebsocket } = this.props;
    if (!enableWebsocket) {
      return this.props.children;
    }
    return (
      <ApolloProvider client={this.client}>
        {this.props.children}
      </ApolloProvider>
    );
  }
}

const mapStateToProps = state => {
  const config = getConfig(state);
  return {
    candidateBaseUrl: config.CANDIDATE_BASE,
    enableWebsocketReconnect: config.ENABLE_WEBSOCKET_RECONNECT === true,
    enableWebsocket: config.ENABLE_WEBSOCKET,
    isAuthenticated: getIsAuthenticated(state),
    locale: getLanguage(state),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export const ApolloWrapperProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApolloWrapperProviderClass);
