import { handleActions } from 'redux-actions';

import { DstAction } from 'src/middleware/api/interfaces';

import { Actions } from './Actions';
import { serverErrorMessages } from './Constants';

export interface ReducerState {
  // initServerApp expects a cookie to exist on the session state
  cookie?: string;
  token: string;
  socialMediaSignup: string;
  isFetching: boolean;
  error: any;
  scopedTokenActionCompleted: boolean;
  createUser: {
    creating: boolean;
    success: boolean;
    error: any;
  };
  userMeta: Record<string, unknown>;
  emailVerification: {
    sendToken: {
      loading: boolean;
      error: any;
      success: boolean;
    };

    verifyEmail: {
      loading: boolean;
      error: any;
      success: boolean;
    };
    sentAt: number;
  };
  isTokenNotValid: boolean;
  isMfaRequired: boolean;
}

export const initialState: ReducerState = {
  token: null,
  socialMediaSignup: null,
  isFetching: false,
  error: null,
  scopedTokenActionCompleted: false,

  createUser: {
    creating: false,
    success: false,
    error: null,
  },

  userMeta: {},

  emailVerification: {
    sendToken: {
      loading: false,
      error: null,
      success: false,
    },

    verifyEmail: {
      loading: false,
      error: null,
      success: false,
    },
    sentAt: null,
  },

  isTokenNotValid: false,

  isMfaRequired: false,
};

export default handleActions<ReducerState, any>(
  {
    [Actions.SET_TOKEN_IS_NOT_VALID]: (state) => ({
      ...state,
      isTokenNotValid: true,
    }),
    [Actions.REQUEST_TOKEN]: (state) =>
      Object.assign({}, state, { isFetching: true }),
    [Actions.RECEIVE_TOKEN]: (state, action) => {
      const isMfaRequired =
        action.payload?.response?.data?.error ===
        serverErrorMessages.MFA_REQUIRED;
      return {
        ...state,
        isFetching: false,
        // Special case: isMfaRequired might be considered an error
        // once other roles require it. But for now, it's expected
        // from employer accounts.
        error: action.error && !isMfaRequired ? action.payload.response : null,
        token: action.error ? null : action.payload,
        isMfaRequired,
      };
    },
    [Actions.CLEAR_TOKEN]: (state) => ({
      ...state,
      token: null,
      isTokenNotValid: false,
    }),
    [Actions.UPDATE_SOCIAL_MEDIA_SIGNUP]: (state, action) => ({
      ...state,
      socialMediaSignup: action.payload || null,
    }),
    [Actions.CLEAR_SOCIAL_MEDIA_SIGNUP]: (state) => ({
      ...state,
      socialMediaSignup: null,
    }),
    [Actions.CLEAR_RECEIVE_TOKEN_ERROR]: (state) => ({
      ...state,
      error: null,
    }),
    [Actions.CREATE_USER_REQUEST]: (state) => ({
      ...state,
      createUser: {
        ...state.createUser,
        creating: true,
        success: false,
        error: null,
      },
    }),
    [Actions.CREATE_USER_SUCCESS]: (state) => ({
      ...state,
      createUser: {
        ...state.createUser,
        creating: false,
        success: true,
        error: null,
      },
    }),
    [Actions.CREATE_USER_FAILURE]: (state, action: DstAction) => ({
      ...state,
      createUser: {
        ...state.createUser,
        creating: false,
        success: false,
        error: action.error.response,
      },
    }),
    [Actions.CLEAR_CREATE_USER_ERROR]: (state) => ({
      ...state,
      createUser: {
        ...state.createUser,
        error: null,
      },
    }),
    [Actions.UPDATE_USER_META]: (state, action) => ({
      ...state,
      userMeta: action.payload,
    }),
    [Actions.CLEAR_USER_META]: (state) => ({
      ...state,
      userMeta: initialState.userMeta,
    }),
    [Actions.SEND_EMAIL_VERIFICATION_TOKEN_REQUEST]: (state) => ({
      ...state,
      emailVerification: {
        ...state.emailVerification,
        sendToken: {
          ...initialState.emailVerification.sendToken,
          loading: true,
        },
      },
    }),
    [Actions.SEND_EMAIL_VERIFICATION_TOKEN_SUCCESS]: (state) => ({
      ...state,
      emailVerification: {
        ...state.emailVerification,
        sendToken: {
          ...initialState.emailVerification.sendToken,
          success: true,
        },
      },
    }),
    [Actions.SEND_EMAIL_VERIFICATION_TOKEN_FAILURE]: (state, action) => ({
      ...state,
      emailVerification: {
        ...state.emailVerification,
        sendToken: {
          ...initialState.emailVerification.sendToken,
          error: action.payload.response,
        },
      },
    }),
    [Actions.RESET_SEND_EMAIL_VERIFICATION_TOKEN]: (state) => ({
      ...state,
      emailVerification: {
        ...state.emailVerification,
        sendToken: initialState.emailVerification.sendToken,
      },
    }),
    [Actions.VERIFY_EMAIL_REQUEST]: (state) => ({
      ...state,
      emailVerification: {
        ...state.emailVerification,
        verifyEmail: {
          ...initialState.emailVerification.verifyEmail,
          loading: true,
        },
      },
    }),
    [Actions.VERIFY_EMAIL_SUCCESS]: (state) => ({
      ...state,
      emailVerification: {
        ...state.emailVerification,
        verifyEmail: {
          ...initialState.emailVerification.verifyEmail,
          success: true,
        },
      },
    }),
    [Actions.VERIFY_EMAIL_FAILURE]: (state, action: DstAction) => ({
      ...state,
      emailVerification: {
        ...state.emailVerification,
        verifyEmail: {
          ...initialState.emailVerification.verifyEmail,
          error: action.error.response,
        },
      },
    }),
    [Actions.SET_EMAIL_VERIFICATION_SENT_AT]: (state, action: DstAction) => ({
      ...state,
      emailVerification: {
        ...state.emailVerification,
        sentAt: action.payload,
      },
    }),
    [Actions.RESET_EMAIL_VERIFICATION_SENT_AT]: (state) => ({
      ...state,
      emailVerification: {
        ...state.emailVerification,
        sentAt: null,
      },
    }),
  },
  initialState
);
