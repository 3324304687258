import { State } from 'src/global/store';

const getSession = (state: State) => state.session;

export const getSendEmailVerificationTokenStates = (state: State) =>
  getSession(state).emailVerification.sendToken;

export const getVerifyEmailStates = (state: State) =>
  getSession(state).emailVerification.verifyEmail;

export const getIsLoggingIn = (state: State) => getSession(state).isFetching;

export const getLoginError = (state: State) => getSession(state).error;

export const getSessionToken = (state: State) => getSession(state).token;

export const getIsAuthenticated = (state: State) =>
  Boolean(getSessionToken(state));

export const getScopedTokenActionCompleted = (state: State) =>
  getSession(state).scopedTokenActionCompleted;

export const getIsSigningUp = (state: State) =>
  getSession(state).createUser.creating;
export const getSignUpSuccess = (state: State) =>
  getSession(state).createUser.success;
export const getSignUpError = (state: State) =>
  getSession(state).createUser.error;

export const getEmailVerificationSentAt = (state: State) =>
  getSession(state).emailVerification.sentAt;

export const getIsTokenNotValid = (state: State) =>
  getSession(state).isTokenNotValid;

export const getUserMeta = (state: State) => getSession(state).userMeta;

export const getIsMfaRequired = (state: State) =>
  getSession(state).isMfaRequired;
