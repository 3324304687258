import { createAction } from 'redux-actions';

import { CALL_API } from 'src/common/constants';
import { isJsonString } from 'src/common/isJsonString';
import { stringifyPhoneNumber } from 'src/common/phoneNumberInputHelper';
import Schemas from 'src/common/schemas';
import { ReduxThunkAction } from 'src/global/store';
import { getSessionToken } from 'src/modules/Session/Selectors';
import { MeValue } from 'src/reducers/user/me';
import { getErrorDetails, getUser } from 'src/selectors/user';

const formatPhoneNumber = (phone: string) => {
  if (isJsonString(phone)) {
    return stringifyPhoneNumber(JSON.parse(phone));
  } else {
    return phone;
  }
};

export const Actions = {
  REPORT_JOB_REQUEST: 'glints/user/me/REPORT_JOB_REQUEST',
  REPORT_JOB_SUCCESS: 'glints/user/me/REPORT_JOB_SUCCESS',
  REPORT_JOB_FAILURE: 'glints/user/me/REPORT_JOB_FAILURE',

  FETCH_ME_REQUEST: 'glints/user/me/FETCH_ME_REQUEST',
  FETCH_ME_SUCCESS: 'glints/user/me/FETCH_ME_SUCCESS',
  FETCH_ME_FAILURE: 'glints/user/me/FETCH_ME_FAILURE',

  PERSIST_ME_REQUEST: 'glints/user/me/PERSIST_ME_REQUEST',
  PERSIST_ME_SUCCESS: 'glints/user/me/PERSIST_ME_SUCCESS',
  PERSIST_ME_FAILURE: 'glints/user/me/PERSIST_ME_FAILURE',

  CLEAR_ME: 'glints/user/me/CLEAR_ME',
  UPLOAD_RESUME_START: 'glints/user/me/UPLOAD_RESUME_START',
  UPDATE_RESUME: 'glints/user/me/UPDATE_RESUME',
  UPDATE_META: 'glints/user/me/UPDATE_META',
  UPDATE_ME: 'glints/user/me/UPDATE_ME',
  UPDATE_ME_VALUE: 'glints/user/me/UPDATE_ME_VALUE',

  REQUEST_BETA_STATUS: 'glints/user/me/REQUEST_BETA_STATUS',
  RECEIVE_BETA_STATUS: 'glints/user/me/RECEIVE_BETA_STATUS',
  REQUEST_CHANGE_BETA_STATUS: 'glints/user/me/REQUEST_CHANGE_BETA_STATUS',
  RECEIVE_CHANGE_BETA_STATUS: 'glints/user/me/RECEIVE_CHANGE_BETA_STATUS',

  REQUEST_MISSING_FIELDS: 'glints/user/me/REQUEST_MISSING_FIELDS',
  RECEIVE_MISSING_FIELDS: 'glints/user/me/RECEIVE_MISSING_FIELDS',
};

export const clearMe = createAction(Actions.CLEAR_ME);

export const updateResume = createAction(Actions.UPDATE_RESUME);
export const uploadResumeStart = createAction(Actions.UPLOAD_RESUME_START);
export const updateMeta = createAction(Actions.UPDATE_META);

export interface UpdateMePayload {
  field: keyof MeValue;
  value: any;
}
export const updateMe = createAction(
  Actions.UPDATE_ME,
  (field: keyof MeValue, value: any): UpdateMePayload => ({
    field: field,
    value: value,
  })
);
export const updateMeValue = createAction(Actions.UPDATE_ME_VALUE);

export const requestMissingFields = createAction(
  Actions.REQUEST_MISSING_FIELDS
);
export const receiveMissingFields = createAction(
  Actions.RECEIVE_MISSING_FIELDS
);

export function fetchMe(): ReduxThunkAction<any> {
  return async (dispatch) => {
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.FETCH_ME_REQUEST,
          Actions.FETCH_ME_SUCCESS,
          Actions.FETCH_ME_FAILURE,
        ],
        endpoint: 'me',
        schema: Schemas.USER,
        options: {
          params: {
            include: 'City, Country',
          },
        },
      },
    });
  };
}

export function persistMe(
  fetchProfileCompletion = false,
  values: Partial<MeValue>,
  onError?: (errorDetail: string) => void
): ReduxThunkAction<any, Promise<any>> {
  return async (dispatch, getState) => {
    const authenticated = Boolean(getSessionToken(getState()));
    if (!authenticated) {
      return null;
    }

    if (values?.phone) {
      values.phone = formatPhoneNumber(values.phone);
    }

    await dispatch({
      [CALL_API]: {
        types: [
          Actions.PERSIST_ME_REQUEST,
          Actions.PERSIST_ME_SUCCESS,
          Actions.PERSIST_ME_FAILURE,
        ],
        endpoint: 'me',
        schema: Schemas.USER,
        method: 'put',
        options: {
          data: values || getUser(getState()),
        },
      },
    });

    if (fetchProfileCompletion) {
      dispatch(fetchMissingFields());
    }

    if (onError) {
      return onError(getErrorDetails(getState()));
    }
  };
}

export function fetchMissingFields(): ReduxThunkAction<any> {
  return async function (dispatch, getState, { api }) {
    dispatch(requestMissingFields());
    try {
      const response = await api(getState().session.token).get(
        '/me/missingFields'
      );
      dispatch(receiveMissingFields(response.data.data.missingFields));
    } catch (err) {
      dispatch(receiveMissingFields(err));
    }
  };
}

export function reportListing(id: string, reason: string) {
  return {
    [CALL_API]: {
      types: [
        Actions.REPORT_JOB_REQUEST,
        Actions.REPORT_JOB_SUCCESS,
        Actions.REPORT_JOB_FAILURE,
      ],
      endpoint: 'report/jobs',
      options: {
        method: 'POST',
        data: {
          data: {
            id: id,
            reason: reason,
          },
        },
      },
      hooks: {
        triggerToast: {
          requestMsg: null as any,
          successMsg:
            'Thanks for reporting!' +
            ' We take the quality of our listings really seriously and will review the listing as soon as possible.',
          failureMsg: 'Oops something went wrong. Please try again later.',
        },
      },
    },
  };
}
