import { Button as AriesButton, ScreenSize, Typography } from 'glints-aries';
import styled from 'styled-components';

import GlintsContainer from 'src/components/GlintsContainer';
import GlintsPicture from 'src/components/GlintsPicture';

const { Paragraph, Title: AriesTitle } = Typography;

export const Container = styled(GlintsContainer)`
  margin-top: 60px;
  text-align: center;

  @media (min-width: ${ScreenSize.desktopS}px) {
    margin-top: 30px;
  }
`;

export const Image = styled(GlintsPicture)`
  max-width: 100%;
  max-height: 400px;
`;

export const Title = styled(AriesTitle).attrs({ tag: 'h3' })`
  margin-top: 25px;
  font-weight: normal;
`;

export const Description = styled(Paragraph).attrs({ variant: 'caption' })`
  margin-top: 15px;
`;

export const Button = styled(AriesButton).attrs({
  variant: 'solid-blue',
  tag: 'a',
  small: true,
})`
  margin-top: 15px;
`;
