import { Component } from 'react';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateUserMeta } from '../../modules/Session/Actions';
import {
  MARKETING_PARAMS_NAMESPACE,
  MARKETING_PARAMS_WHITELIST_PATTERNS,
} from './constants';

class MarketingParams extends Component {
  static propTypes = {
    updateUserMeta: PropTypes.func,
    router: PropTypes.object,
  };

  componentDidMount() {
    const query = this.props.router.query;

    const locationQueryKeys = Object.keys(query);
    const marketingKeys = locationQueryKeys.filter(key =>
      MARKETING_PARAMS_WHITELIST_PATTERNS.some(pattern => {
        const regexPattern = RegExp(pattern);
        return regexPattern.test(key);
      })
    );

    const marketingMeta = {};
    marketingKeys.forEach(key => (marketingMeta[key] = query[key]));

    if (Object.keys(marketingKeys).length > 0) {
      this.props.updateUserMeta({
        [MARKETING_PARAMS_NAMESPACE]: marketingMeta,
      });
    }
  }

  render() {
    return null;
  }
}

MarketingParams = connect(null, dispatch => ({
  updateUserMeta: meta => dispatch(updateUserMeta(meta)),
}))(MarketingParams);

export default withRouter(MarketingParams);
