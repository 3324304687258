import { CALL_API } from 'src/common/constants';
import Schemas from 'src/common/schemas';
import { CallAPIOptions } from 'src/middleware/api/interfaces';

export const Actions = {
  SKILLS_REQUEST: 'glints/skills/SKILLS_REQUEST',
  SKILLS_SUCCESS: 'glints/skills/SKILLS_SUCCESS',
  SKILLS_FAILURE: 'glints/skills/SKILLS_FAILURE',

  POPULAR_SKILLS_REQUEST: 'glints/skills/POPULAR_SKILLS_REQUEST',
  POPULAR_SKILLS_SUCCESS: 'glints/skills/POPULAR_SKILLS_SUCCESS',
  POPULAR_SKILLS_FAILURE: 'glints/skills/POPULAR_SKILLS_FAILURE',
};

export function fetchPopularSkills(options: CallAPIOptions) {
  return {
    [CALL_API]: {
      types: [
        Actions.POPULAR_SKILLS_REQUEST,
        Actions.POPULAR_SKILLS_SUCCESS,
        Actions.POPULAR_SKILLS_FAILURE,
      ],
      endpoint: 'skills',
      schema: Schemas.SKILL_ARRAY,
      options: {
        ...options,
        params: {
          limit: 50,
          order: 'popularity DESC',
          where: {
            popularity: { $gte: 1000 },
          },
          ...(options ? options.params : {}),
        },
      },
    },
  };
}

export function fetchSkills(options: CallAPIOptions) {
  return {
    [CALL_API]: {
      types: [
        Actions.SKILLS_REQUEST,
        Actions.SKILLS_SUCCESS,
        Actions.SKILLS_FAILURE,
      ],
      endpoint: 'skills',
      schema: Schemas.SKILL_ARRAY,
      options: {
        ...options,
        params: {
          limit: 10,
          order: 'popularity DESC',
          ...(options ? options.params : {}),
        },
      },
    },
  };
}
