import { CALL_API } from 'src/common/constants';
import { CallAPIOptions } from 'src/middleware/api/interfaces';

export const Actions = {
  SIMILAR_JOBS_REQUEST: 'glints/similarJobs/SIMILAR_JOBS_REQUEST',
  SIMILAR_JOBS_SUCCESS: 'glints/similarJobs/SIMILAR_JOBS_SUCCESS',
  SIMILAR_JOBS_FAILURE: 'glints/similarJobs/SIMILAR_JOBS_FAILURE',
};

export function fetchSimilarJobs(id: string, options: CallAPIOptions) {
  return {
    [CALL_API]: {
      types: [
        Actions.SIMILAR_JOBS_REQUEST,
        Actions.SIMILAR_JOBS_SUCCESS,
        Actions.SIMILAR_JOBS_FAILURE,
      ],
      endpoint: `jobs/${id}/similarJobs`,
      options: {
        ...options,
      },
    },
  };
}
