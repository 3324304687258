import get from 'lodash/get';
import { createSelector } from 'reselect';

import { State } from 'src/global/store';
import { BOOKMARK_TYPES } from 'src/modules/Bookmark/constants';

import { getBookmarks } from '../api';
import { getCurrentOpportunityId } from '../opportunity';

export const getMyBookmarksLoading = (state: State) =>
  state.user.bookmarks.loading;

export const getMyBookmarksLastFetched = (state: State) =>
  state.user.bookmarks.lastFetched;

export const getMyBookmarkedIds = (state: State) => state.user.bookmarks.value;

export const getMyBookmarkedOpportunityIds = (state: State) =>
  state.user.bookmarks.opportunities.value;

export const getMyBookmarkedCompanyIds = (state: State) =>
  state.user.bookmarks.companies.value;

export const getMyBookmarks = createSelector(
  [
    getMyBookmarkedIds,
    getBookmarks,
    (state: State, type: BOOKMARK_TYPES) => type,
  ],
  (ids, bookmarks, type) => {
    const result = ids.map((id) => bookmarks[id]);
    switch (type) {
      case BOOKMARK_TYPES.company:
        return result.filter((bookmark) => bookmark.CompanyId);
      case BOOKMARK_TYPES.opportunity:
        return result.filter((bookmark) => bookmark.JobId);
      case BOOKMARK_TYPES.event:
        return result.filter((bookmark) => bookmark.EventId);
      default:
        return result;
    }
  }
);

export const getMyBookmarkCount = (state: State, type: BOOKMARK_TYPES) =>
  get(getMyBookmarks(state, type), 'length', 0);

export const getCurrentOpportunityBookmark = createSelector(
  [getCurrentOpportunityId, getMyBookmarks],
  (id, bookmarks) => bookmarks.find((bookmark) => bookmark.JobId === id)
);

export const getIsJobBookmarked = (state: State, jobId: string) => {
  return Boolean(
    getMyBookmarks(state, BOOKMARK_TYPES.opportunity).find(
      (bookmark) => bookmark.JobId === jobId
    )
  );
};
