import { createAction } from 'redux-actions';

import { CALL_API } from 'src/common/constants';
import Schemas from 'src/common/schemas';
import { CallAPIOptions } from 'src/middleware/api/interfaces';

export const Actions = {
  INDUSTRY_REQUEST: 'glints/industry/INDUSTRY_REQUEST',
  INDUSTRY_SUCCESS: 'glints/industry/INDUSTRY_SUCCESS',
  INDUSTRY_FAILURE: 'glints/industry/INDUSTRY_FAILURE',
  CLEAR_INDUSTRY: 'glints/industry/CLEAR_INDUSTRY',
};

export const clearIndustry = createAction(Actions.CLEAR_INDUSTRY);

export function fetchIndustry(id: number, options?: CallAPIOptions) {
  return {
    [CALL_API]: {
      types: [
        Actions.INDUSTRY_REQUEST,
        Actions.INDUSTRY_SUCCESS,
        Actions.INDUSTRY_FAILURE,
      ],
      endpoint: `industries/${id}`,
      schema: Schemas.INDUSTRY,
      options,
    },
  };
}
