import { createAction } from 'redux-actions';

export const Actions = {
  SHOW_WHATSAPP_MESSAGING: 'glints/whatsapp/SHOW_WHATSAPP_MESSAGING',
  HIDE_WHATSAPP_MESSAGING: 'glints/whatsapp/HIDE_WHATSAPP_MESSAGING',
};

export const showWhatsappMessagingAction = createAction(
  Actions.SHOW_WHATSAPP_MESSAGING
);

export const hideWhatsappMessagingAction = createAction(
  Actions.HIDE_WHATSAPP_MESSAGING
);
