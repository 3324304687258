import { handleActions } from 'redux-actions';

import { Actions } from 'src/actions/skills';
import { DstAction } from 'src/middleware/api/interfaces';

interface PopularSkill {
  loading: boolean;
  error: any;
  value: string[];
}

export interface SkillsState {
  loading: boolean;
  error: any;
  value: string[];
  popularSkill: PopularSkill;
}

export const initialState: SkillsState = {
  loading: false,
  error: null,
  value: [],
  popularSkill: {
    loading: false,
    error: null,
    value: [],
  },
};

export default handleActions<SkillsState, any>(
  {
    [Actions.SKILLS_REQUEST]: (state) => ({
      ...state,
      loading: true,
      value: state.value.length ? state.value : [],
      error: null,
    }),
    [Actions.SKILLS_SUCCESS]: (state, action: DstAction) => ({
      ...state,
      loading: false,
      value: action.response.result,
      error: null,
    }),
    [Actions.SKILLS_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      value: [],
      error: action.error,
    }),
    [Actions.POPULAR_SKILLS_REQUEST]: (state) => ({
      ...state,
      popularSkill: {
        loading: true,
        value: state.value.length ? state.value : [],
        error: null,
      },
    }),
    [Actions.POPULAR_SKILLS_SUCCESS]: (state, action: DstAction) => ({
      ...state,
      popularSkill: {
        loading: false,
        value: action.response.result,
        error: null,
      },
    }),
    [Actions.POPULAR_SKILLS_FAILURE]: (state, action) => ({
      ...state,
      popularSkill: {
        loading: false,
        value: [],
        error: action.error,
      },
    }),
  },
  initialState
);
