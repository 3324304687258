import pick from 'lodash/pick';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import { DOWNLOAD_MOBILE_APP_DYNAMIC_LINK } from 'src/common/constants';
import { CountryCodes } from 'src/common/enums';
import { getJobWithCountryFilterURL } from 'src/common/links';
import {
  ID_JOB_EXPLORE_URL,
  JOB_EXPLORE_URL,
  ROUTES,
  VN_JOB_EXPLORE_URL,
} from 'src/common/routes';
import {
  getAboutUsUrl,
  getBlogUrl,
  getExploreUrl,
  getMarketPlaceUrl,
  getTalentHubUrl,
} from 'src/components/links/LocalizedUrl/getLocalizedUrl';
import { UnleashFeatureNames } from 'src/modules/Unleash/constants';

/**
 * This array consists of top-level menu items that should appear in the primary
 * navigation header of the site.
 *
 * The required properties are:
 *
 *   - to: The route associated with this item, as used in the Link component
 *   - label: The text to appear for this item
 *
 * This array is consumed in Navigation and DesktopMenu.
 */

const menuMessages = defineMessages({
  blog: {
    id: 'menu.blog',
    defaultMessage: 'Blog',
  },
  event: {
    id: 'menu.event',
    defaultMessage: 'ExpertClass',
  },
  jobs: {
    id: 'menu.jobs',
    defaultMessage: 'Jobs',
  },
  jobsMarketplace: {
    id: 'menu.jobsMarketplace',
    defaultMessage: 'Job Marketplace',
  },
  talentHub: {
    id: 'menu.talentHub',
    defaultMessage: 'TalentHub',
  },
  explore: {
    id: 'menu.explore',
    defaultMessage: 'Explore Career',
  },
  aboutUs: {
    id: 'menu.aboutUs',
    defaultMessage: 'About Us',
  },
  companies: {
    id: 'menu.companies',
    defaultMessage: 'Companies',
  },
  testimonials: {
    id: 'menu.testimonials',
    defaultMessage: 'Testimonials',
  },
  home: {
    id: 'menu.home',
    defaultMessage: 'Home',
  },
  whoWeAre: {
    id: 'menu.whoWeAre',
    defaultMessage: 'Who We Are',
  },
  workingAtGlints: {
    id: 'menu.workingAtGlints',
    defaultMessage: 'Working at Glints',
  },
  howWeHire: {
    id: 'menu.howWeHire',
    defaultMessage: 'How We Hire',
  },
  lifeAtGlints: {
    id: 'menu.lifeAtGlints',
    defaultMessage: 'Life At Glints',
  },
  ourLocations: {
    id: 'menu.ourLocations',
    defaultMessage: 'Our Locations',
  },
  downloadApp: {
    id: 'text-header-menu-download-app',
    defaultMessage: 'Download App',
  },
});

const blogs = {
  [CountryCodes.ID]: {
    label: 'Blog',
    to: getBlogUrl(CountryCodes.ID),
    message: menuMessages.blog,
    requiresAuth: false,
    external: true,
  },
  [CountryCodes.VN]: {
    label: 'Blog',
    to: getBlogUrl(CountryCodes.VN),
    message: menuMessages.blog,
    requiresAuth: false,
    external: true,
  },
  [CountryCodes.TW]: {
    label: 'Blog',
    to: getBlogUrl(CountryCodes.TW),
    message: menuMessages.blog,
    requiresAuth: false,
    external: true,
  },
};

const getIntlLink = (country, label, toMapper, message) => {
  return {
    label,
    to: toMapper(country),
    message,
    requiresAuth: false,
    external: true,
  };
};

const events = {
  [CountryCodes.ID]: {
    label: 'ExpertClass',
    to: `/${ROUTES.expertClass}`,
    match: new RegExp(ROUTES.expertClass),
    message: menuMessages.event,
    requiresAuth: false,
    new: false,
  },
};

export const downloadAppMenuItemProps = {
  label: 'Download App',
  to: DOWNLOAD_MOBILE_APP_DYNAMIC_LINK,
  message: menuMessages.downloadApp,
  requiresAuth: false,
  external: true,
  new: true,
  className: 'nav-btn-download-app',
  visibleOnViewport: ['mobile'],
  visibleOnCountryCodes: [CountryCodes.VN],
};

export const MenuItemTypes = {
  JOBS: 'JOBS',
  JOB_CATEGORIES: 'JOB_CATEGORIES',
  COMPANIES: 'COMPANIES',
  TESTIMONIALS: 'TESTIMONIALS',
  BLOG: 'BLOG',
  FORUM: 'FORUM',
  EVENT: 'EVENT',
  DOWNLOAD_APP: 'DOWNLOAD APP',
  HIRING_SOLUTIONS: 'HIRING_SOLUTIONS',
  EXPLORE_CAREER: 'EXPLORE_CAREER',
  ABOUT_US: 'ABOUT_US',
};

export const CareersPageMenuItemTypes = {
  HOME: 'HOME',
  WHO_WE_ARE: 'WHO_WE_ARE',
  HOW_WE_HIRE: 'HOW_WE_HIRE',
  WORKING_AT_GLINTS: 'WORKING_AT_GLINTS',
  LIFE_AT_GLINTS: 'LIFE_AT_GLINTS',
  OUR_LOCATIONS: 'OUR_LOCATIONS',
};

export const MenuItemPropType = PropTypes.shape({
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  external: PropTypes.bool,
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,
  }).isRequired,
  requiresAuth: PropTypes.bool,
  new: PropTypes.bool,
  className: PropTypes.string,
  visibleOnViewport: PropTypes.arrayOf(PropTypes.string),
  visibleOnCountryCodes: PropTypes.arrayOf(PropTypes.string),
});

const MenuItems = {
  [MenuItemTypes.HIRING_SOLUTIONS]: undefined,
  [MenuItemTypes.EXPLORE_CAREER]: undefined,
  [MenuItemTypes.ABOUT_US]: undefined,
  [MenuItemTypes.JOBS]: {
    label: 'Jobs',
    to: `/${ROUTES.opportunitiesJobsExplore}`,
    match: new RegExp(ROUTES.opportunitiesJobs),
    message: menuMessages.jobs,
    requiresAuth: false,
  },
  [MenuItemTypes.COMPANIES]: {
    label: 'Companies',
    to: `/${ROUTES.companies}`,
    match: new RegExp(ROUTES.companies),
    message: menuMessages.companies,
    requiresAuth: false,
  },
  [MenuItemTypes.TESTIMONIALS]: {
    label: 'Testimonials',
    to: `/${ROUTES.testimonials}`,
    match: new RegExp(ROUTES.testimonials),
    message: menuMessages.testimonials,
    requiresAuth: false,
  },
  [MenuItemTypes.DOWNLOAD_APP]: downloadAppMenuItemProps,
};

const CareerPageMenuItems = {
  [CareersPageMenuItemTypes.HOME]: {
    label: 'Home',
    to: `#grow-together`,
    requiresAuth: false,
    message: menuMessages.home,
  },
  [CareersPageMenuItemTypes.WHO_WE_ARE]: {
    label: 'Who We Are',
    to: `#who-we-are`,
    requiresAuth: false,
    message: menuMessages.whoWeAre,
  },
  [CareersPageMenuItemTypes.HOW_WE_HIRE]: {
    label: 'How We Hire',
    to: `#how-we-hire`,
    requiresAuth: false,
    message: menuMessages.howWeHire,
  },
  [CareersPageMenuItemTypes.WORKING_AT_GLINTS]: {
    label: 'Working at Glints',
    to: `#working-at-glints`,
    requiresAuth: false,
    message: menuMessages.workingAtGlints,
  },
  [CareersPageMenuItemTypes.LIFE_AT_GLINTS]: {
    label: 'Life At Glints',
    to: `#life-at-glints`,
    requiresAuth: false,
    message: menuMessages.lifeAtGlints,
  },
  [CareersPageMenuItemTypes.OUR_LOCATIONS]: {
    label: 'Our Locations',
    to: `#locations`,
    requiresAuth: false,
    message: menuMessages.ourLocations,
  },
};

function makeMenuItems(
  config,
  features,
  isAuthenticated,
  hasRecommendations,
  pagePath
) {
  const country = config.COUNTRY;
  const isOnCareersPage = pagePath?.includes('/careers');
  const defaultMenuItemKeys = country
    ? [MenuItemTypes.JOBS, MenuItemTypes.COMPANIES]
    : [
        MenuItemTypes.EXPLORE_CAREER,
        MenuItemTypes.HIRING_SOLUTIONS,
        MenuItemTypes.ABOUT_US,
      ];

  const menuItems = isOnCareersPage
    ? CareerPageMenuItems
    : pick(MenuItems, defaultMenuItemKeys);
  const isID = country === CountryCodes.ID;
  const isVN = country === CountryCodes.VN;
  const isTWJobFilterEnabled = Boolean(
    features[UnleashFeatureNames.dstTWJobFilter]
  );
  const isNotTWOrTWDefaultCountryQueryEnabled =
    country !== CountryCodes.TW || isTWJobFilterEnabled;

  const linkJobsToRecommendationsTab = () => {
    menuItems[
      MenuItemTypes.JOBS
    ].to = `/${ROUTES.opportunitiesJobsRecommended}`;
    menuItems[
      MenuItemTypes.JOBS
    ].as = `/${ROUTES.opportunitiesJobsRecommended}`;
    menuItems[MenuItemTypes.JOBS].match = new RegExp(
      ROUTES.opportunitiesJobsRecommended
    );
  };

  const shouldUseRecommendationsLink =
    isAuthenticated && hasRecommendations && !isOnCareersPage && country;

  if (shouldUseRecommendationsLink) {
    linkJobsToRecommendationsTab();
  }

  if (country) {
    try {
      // The job link either returns recommended or explore (with country filter)
      if (shouldUseRecommendationsLink) {
        linkJobsToRecommendationsTab();
      } else {
        menuItems[MenuItemTypes.JOBS].to = getJobWithCountryFilterURL(
          country,
          isNotTWOrTWDefaultCountryQueryEnabled
        );
      }

      // if current country is ID, we mask the url to /lowongan-kerja
      if (isID && !shouldUseRecommendationsLink) {
        menuItems[MenuItemTypes.JOBS].as = ID_JOB_EXPLORE_URL;
      } else if (isVN && !shouldUseRecommendationsLink) {
        menuItems[MenuItemTypes.JOBS].as = VN_JOB_EXPLORE_URL;
      } else if (!shouldUseRecommendationsLink) {
        // This is necessary as of the time of this writing (next v9.2.2).
        // Due to an unknown bug, href attribute of the anchor link will remains as ID_JOB_EXPLORE_URL, even after page refresh.

        menuItems[MenuItemTypes.JOBS].as = JOB_EXPLORE_URL;
      }

      menuItems[MenuItemTypes.COMPANIES].to =
        isNotTWOrTWDefaultCountryQueryEnabled
          ? `/${ROUTES.companies}?countries=${country}`
          : `/${ROUTES.companies}`;

      if (config.ENABLE_BLOG_MENU && blogs[country]) {
        menuItems[MenuItemTypes.BLOG] = blogs[country];
      }
      if (config.ENABLE_EXPERT_CLASS_MENU && events[country] && isID) {
        menuItems[MenuItemTypes.EVENT] = events[country];
      }
    } catch (err) {
      console.error(`Make menu items failed: ${err}`);
    }
  } else {
    if (!menuItems[MenuItemTypes.HIRING_SOLUTIONS]) {
      menuItems[MenuItemTypes.HIRING_SOLUTIONS] = [];
    }
    menuItems[MenuItemTypes.HIRING_SOLUTIONS].push(
      getIntlLink(
        country || CountryCodes.SG,
        'Jobs Marketplace',
        getMarketPlaceUrl,
        menuMessages.jobsMarketplace
      )
    );
    menuItems[MenuItemTypes.HIRING_SOLUTIONS].push(
      getIntlLink(
        country || CountryCodes.SG,
        'TalentHub',
        getTalentHubUrl,
        menuMessages.talentHub
      )
    );
    menuItems[MenuItemTypes.EXPLORE_CAREER] = getIntlLink(
      country || CountryCodes.SG,
      'Explore Career',
      getExploreUrl,
      menuMessages.explore
    );
    menuItems[MenuItemTypes.ABOUT_US] = getIntlLink(
      country || CountryCodes.SG,
      'About Us',
      getAboutUsUrl,
      menuMessages.aboutUs
    );
  }

  if (downloadAppMenuItemProps.visibleOnCountryCodes.includes(country)) {
    menuItems[MenuItemTypes.DOWNLOAD_APP] = downloadAppMenuItemProps;
  }

  return item => (item ? menuItems[item] : values(menuItems));
}

export default makeMenuItems;
