import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { useEmployersURL } from 'src/common/hooks';

const EmployersLink = ({ children, ...otherProps }) => {
  const employersUrl = useEmployersURL();
  return (
    <a
      href={`${employersUrl}/?utm_source=marketplace`}
      target="_blank"
      {...otherProps}
    >
      {children}
    </a>
  );
};

EmployersLink.propTypes = { children: PropTypes.node };

EmployersLink.defaultProps = {
  children: (
    <FormattedMessage
      id="menu.employers"
      defaultMessage="Employers"
      tagName="span"
    />
  ),
};

export default EmployersLink;
