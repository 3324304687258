import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { getBasename } from 'src/modules/ClientConfig/Selectors';
import { Button } from 'src/pages/ErrorStyles.sc';

export const TakeMeHomeButton: React.FC<React.PropsWithChildren<Props>> = ({
  className,
}) => {
  const basename = useSelector(getBasename);
  return (
    <a href={basename}>
      <Button className={className}>
        <FormattedMessage
          id="error_home.button"
          defaultMessage="Take me home!"
        />
      </Button>
    </a>
  );
};

interface Props {
  className?: string;
}
