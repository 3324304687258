import { combineReducers } from 'redux';

import { AsyncReducersMap } from 'src/global/store';
import { DstAction } from 'src/middleware/api/interfaces';

// Reducers from new modules.
import { applicationsReducer as applications } from './modules/Applications/reducer';
import clientConfig from './modules/ClientConfig/Reducer';
import generalConfig from './modules/GeneralConfig/Reducer';
import session from './modules/Session/Reducer';
import unleash from './modules/Unleash/Reducer';
import virality from './modules/Virality/Reducer';
import whatsapp from './modules/Whatsapp/reducer';
import zendesk from './modules/Zendesk/reducer';
import app from './reducers/app';
import cities from './reducers/cities';
import countries from './reducers/countries';
import currencies from './reducers/currencies';
import history from './reducers/history';
import industry from './reducers/industry';
import notifications from './reducers/notifications';
import opportunities from './reducers/opportunities';
import opportunity from './reducers/opportunity';
import server from './reducers/server';
import similarJobs from './reducers/similarJobs';
import skills from './reducers/skills';
import user from './reducers/user';

/**
 * Updates an entity cache in response to any action with response.entities.
 */
export const entitiesInitialState = {
  application: {},
  applicationDraft: {},
  bookmark: {},
  campaign: {},
  city: {},
  company: {},
  country: {},
  currency: {},
  degree: {},
  event: {},
  industry: {},
  jobRolePreferences: {},
  opportunity: {},
  opportunitySkill: {},
  platform: {},
  recommendedJobs: {},
  school: {},
  skill: {},
  study: {},
  user: {},
};

function entities(state = entitiesInitialState, action: DstAction) {
  if (action.response && action.response.entities) {
    // Construct a patch to patch the entity store.
    const updatedEntityTypes = Object.keys(action.response.entities);
    const patch = updatedEntityTypes.reduce(
      (accumulator, type: keyof typeof state) => {
        accumulator[type] = {
          ...state[type],
          ...action.response.entities[type],
        };
        return accumulator;
      },
      {} as typeof state
    );

    return {
      ...state,
      ...patch,
    };
  }

  return state;
}

/**
 * This is the reducer that transforms the previous state into the next state.
 * The transformation to be done is specified via 'action'.
 *
 * The reducer must be a pure function, i.e. given the same arguments, it
 * should always return a consistent result.
 *
 * More information available at http://redux.js.org/docs/basics/Reducers.html.
 */
const staticReducersMap = {
  entities,
  app,
  applications,
  cities,
  clientConfig,
  countries,
  currencies,
  generalConfig,
  history,
  industry,
  notifications,
  opportunities,
  opportunity,
  server,
  session,
  similarJobs,
  skills,
  unleash,
  virality,
  user,
  whatsapp,
  zendesk,
};

export const createRootReducer = (asyncReducersMap: AsyncReducersMap) =>
  combineReducers({ ...staticReducersMap, ...asyncReducersMap });
