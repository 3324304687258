import {
  Button,
  Greyscale,
  PrimaryColor,
  ScreenSize,
  SecondaryColor,
} from 'glints-aries';
import styled from 'styled-components';

export const LoginSignUpModalContainer = styled.div`
  margin: 0 15px;

  .small-text {
    font-size: 16px;
  }

  .large-text {
    font-size: 18px;
  }

  .modal-title {
    font-weight: bold;
  }

  .modal-link {
    font-size: 16px;
    color: ${SecondaryColor.actionblue};
  }

  .loading-spinner-container {
    margin-top: 20px;
    text-align: center;
  }

  .modal-footer-container {
    margin-top: 20px;
  }

  .click-here {
    display: inline;
    cursor: pointer;
  }

  .forgot-password {
    display: block;
    margin-top: 20px;
  }

  .redirect-to-login {
    margin-top: 20px;
  }

  .redirect-to-signup {
    margin-top: 30px;
  }

  .terms-of-service {
    color: ${Greyscale.grey};
    line-height: normal;
  }

  .aries-textfield {
    margin-top: 20px;
  }

  .aries-textfield + span {
    color: ${PrimaryColor.glintsred};
  }

  .aries-solid-shadow-btn {
    margin-bottom: 5px;
  }

  .modal-body {
    padding: 0;

    .large-text {
      padding: 20px;
      padding-bottom: 0;
    }
  }

  .modal-content {
    width: 90vw;

    @media (min-width: ${ScreenSize.mobileM}px) {
      width: 500px;
    }
  }
`;

export const SignUpFormContainer = styled.div`
  form > section div {
    grid-template-columns: 100%;
  }

  form > section > div:first-child > div:first-child {
    margin-top: 0px;
  }

  form > section > div > div {
    margin-top: 20px;
    padding: 0;
  }

  /* override sign up form styles */
  form input {
    border: solid 2px ${Greyscale.grey};
  }

  form input:hover,
  input:active,
  input:focus {
    border: solid 2px ${SecondaryColor.actionblue};
  }
`;

export const ForgotPasswordLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubmitButton = styled(Button).attrs({
  variant: 'yellow',
})`
  margin-top: 10px;
  position: relative;
  letter-spacing: 0.88px;
  width: 100%;
  height: 42px;
  .solid-shadow-btn-content {
    letter-spacing: 0.88px;
  }

  .aries-loading {
    position: absolute;
    top: 9px;
    left: calc(50% - 12px);

    & > div {
      width: 24px;
      height: 24px;
    }
  }
`;
