import { handleActions } from 'redux-actions';

import { DstAction } from 'src/middleware/api/interfaces';

import { Actions } from '../actions/cities';

export interface CitiesState {
  error: Error;
  loading: boolean;
  value: number[];
}

export const initialState: CitiesState = {
  error: null,
  loading: false,
  value: [],
};

export default handleActions<CitiesState, any>(
  {
    [Actions.CITIES_REQUEST]: (state) => ({
      ...state,
      loading: true,
      value: state.value.length ? state.value : [],
      error: null,
    }),
    [Actions.CITIES_SUCCESS]: (state, action: DstAction) => {
      return {
        ...state,
        loading: false,
        value: action.response.result,
        error: null,
      };
    },
    [Actions.CITIES_SUCCESS_ADD]: (state, action: DstAction) => ({
      ...state,
      loading: false,
      value: [...new Set([...state.value, ...action.response.result])],
      error: null,
    }),
    [Actions.CITIES_FAILURE]: (state, action: DstAction) => ({
      ...state,
      loading: false,
      value: [],
      error: action.error,
    }),
    [Actions.RESET_CITIES]: () => ({
      ...initialState,
    }),
  },
  initialState
);
