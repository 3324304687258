import React from 'react';
import { RadioButton as RadioButtonNext } from 'glints-aries/lib/@next';
import { MessageDescriptor, useIntl } from 'react-intl';

import * as Styles from './ReportModalV2.sc';

type ReportReasonListItemProps = {
  value: string;
  currentSelection: string;
  reasonTitle: MessageDescriptor;
  reasonDescription: MessageDescriptor;
  view?: 'mobile' | 'desktop';
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const ReportReasonListItem = ({
  currentSelection,
  value,
  reasonTitle,
  reasonDescription,
  view = 'desktop',
  handleOnChange,
}: ReportReasonListItemProps) => {
  const intl = useIntl();
  return (
    <Styles.ReportReasonListItemContainer>
      <RadioButtonNext
        name="reason"
        value={value}
        label={intl.formatMessage(reasonTitle)}
        helperText={view === 'desktop' && intl.formatMessage(reasonDescription)}
        onChange={handleOnChange}
        checked={currentSelection === value}
      />
    </Styles.ReportReasonListItemContainer>
  );
};
