import { merge } from 'lodash';
import { createAction } from 'redux-actions';

import { CALL_API } from 'src/common/constants';
import Schemas from 'src/common/schemas';
import { ReduxThunkAction } from 'src/global/store';
import { CallAPIOptions } from 'src/middleware/api/interfaces';

export const Actions = {
  CITIES_REQUEST: 'glints/cities/CITIES_REQUEST',
  CITIES_SUCCESS: 'glints/cities/CITIES_SUCCESS',
  CITIES_SUCCESS_ADD: 'glints/cities/CITIES_SUCCESS_ADD',
  CITIES_FAILURE: 'glints/cities/CITIES_FAILURE',
  RESET_CITIES: 'glints/cities/RESET_CITIES',
};

export const resetCities = createAction(Actions.RESET_CITIES);

/**
 * @param options
 * @param addToState Set to false by default. If false, the cities.value array
 * is overriden with the fetched city ids. If true, the fetched city ids are
 * added to the cities.value array instead.
 */
export const fetchCities: (
  options: CallAPIOptions,
  addToState?: boolean
) => ReduxThunkAction<any> =
  (options, addToState = false) =>
  (dispatch) => {
    const actions = [Actions.CITIES_REQUEST, Actions.CITIES_FAILURE];
    if (addToState) {
      actions.splice(1, 0, Actions.CITIES_SUCCESS_ADD);
    } else {
      actions.splice(1, 0, Actions.CITIES_SUCCESS);
    }

    return dispatch({
      [CALL_API]: {
        types: actions,
        endpoint: 'cities',
        schema: Schemas.CITY_ARRAY,
        options: merge(options, {
          params: {
            order: 'name ASC',
          },
        }),
      },
    });
  };
