import { merge } from 'lodash';
import { createAction } from 'redux-actions';

import { CALL_API } from 'src/common/constants';
import Schemas from 'src/common/schemas';
import { ReduxThunkAction } from 'src/global/store';
import { CallAPIOptions } from 'src/middleware/api/interfaces';
import { getConfig } from 'src/modules/ClientConfig';

export const Actions = {
  COUNTRIES_REQUEST: 'glints/countries/COUNTRIES_REQUEST',
  COUNTRIES_SUCCESS: 'glints/countries/COUNTRIES_SUCCESS',
  COUNTRIES_SUCCESS_ADD: 'glints/countries/COUNTRIES_SUCCESS_ADD',
  COUNTRIES_FAILURE: 'glints/countries/COUNTRIES_FAILURE',
  REARRANGE_COUNTRY_TO_FIRST: 'glints/countries/REARRANGE_COUNTRY_TO_FIRST',
};

const rearrangeCountryToFirst = createAction(
  Actions.REARRANGE_COUNTRY_TO_FIRST
);

/**
 * @param options
 * @param addToState Set to false by default. If false, the countries.value
 * array is overriden with the fetched country ids. If true, the fetched country
 * ids are added to the countries.value array instead.
 */
export function fetchCountries(
  options?: CallAPIOptions,
  addToState?: boolean
): ReduxThunkAction<any, Promise<any>> {
  return async (dispatch, getState) => {
    const actionTypes = [Actions.COUNTRIES_REQUEST, Actions.COUNTRIES_FAILURE];
    if (addToState) {
      actionTypes.splice(1, 0, Actions.COUNTRIES_SUCCESS_ADD);
    } else {
      actionTypes.splice(1, 0, Actions.COUNTRIES_SUCCESS);
    }

    await dispatch({
      [CALL_API]: {
        types: actionTypes,
        endpoint: 'countries',
        schema: Schemas.COUNTRY_ARRAY,
        options: merge(options, {
          params: {
            order: 'name ASC',
          },
        }),
      },
    });

    const config = getConfig(getState());
    if (config.COUNTRY) {
      dispatch(rearrangeCountryToFirst(config.COUNTRY));
    }
  };
}
