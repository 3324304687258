import * as React from 'react';
import App, { AppContext, AppProps } from 'next/app';
import Router from 'next/router';
import { makeClientStore } from 'nextDir/modules/Global/store';

import { pushAsPath } from 'src/actions/history';
import { AsyncReducerKeys } from 'src/global/store';

import { DstContext } from '../../shared/interfaces/DstContext';
import { patchRouter } from '../modules/Router/patchRouter';
import { DstServerAppProps } from './initServerApp';

export const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

export interface DstClientAppProps
  extends AppProps<{ asyncReducerKeys: AsyncReducerKeys; statusCode: number }> {
  dstContext: DstContext;
}

function initClientApp(AppComponent: typeof App) {
  return class ClientApp extends App<DstClientAppProps> {
    // This function will only be called when navigating to a different route
    // via the Link component or using the routing APIs.
    static async getInitialProps(appContext: AppContext) {
      const dstContext = window.__NEXT_DATA__.props.dstContext;

      // Provide the store to getInitialProps of pages
      appContext.ctx.reduxStore = window[__NEXT_REDUX_STORE__];

      const appProps = await App.getInitialProps(appContext);

      return {
        ...appProps,
        dstContext,
      };
    }

    // initial props is passed down from the server side
    constructor(props: DstServerAppProps) {
      super(props);
      const store = makeClientStore(
        props.initialReduxState,
        props.pageProps?.asyncReducerKeys
      );

      window[__NEXT_REDUX_STORE__] = store;

      // patch Next.js Router with customised behaviours.
      patchRouter(Router as any);

      // in initServerApp, we stored current asPath to history reducer,
      // at client side, we keep tracking asPath change on route change
      Router.events.on('routeChangeStart', (asPath) => {
        store.dispatch(pushAsPath(asPath));
      });
    }

    render() {
      return (
        <AppComponent
          {...this.props}
          reduxStore={window[__NEXT_REDUX_STORE__]}
        />
      );
    }
  };
}

export default initClientApp;
