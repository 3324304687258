import React from 'react';
import dynamic from 'next/dynamic';

import { useModal } from 'src/modules/Profile/Components/Modal/useModal';

import { DownloadAppPromotionContext } from './Context';
import { PromotionModalProps } from './PromotionModal';

const PromotionModal = dynamic<PromotionModalProps>(() =>
  import('./PromotionModal').then((module) => module.PromotionModal)
);
type Props = {
  children: React.ReactNode;
};
export const DownloadAppPromotionModal = ({ children }: Props) => {
  const { openModal, modal } = useModal(PromotionModal);
  return (
    <>
      <DownloadAppPromotionContext.Provider
        value={{
          openDownloadAppPromotionModal: openModal,
        }}
      >
        {modal}
        {children}
      </DownloadAppPromotionContext.Provider>
    </>
  );
};
