import { handleActions } from 'redux-actions';

import { DstAction } from 'src/middleware/api/interfaces';

import { Actions } from './Actions';

export type ReducerState = StrIndexObject;

export const initialState: ReducerState = {};

export default handleActions<ReducerState, any>(
  {
    [Actions.FETCH_GENERAL_CONFIG_SUCCESS]: (state, action: DstAction) => {
      const data = action.response;

      if (data && Array.isArray(data)) {
        return {
          ...state,
          ...data.reduce((acc, config) => {
            return {
              ...acc,
              [config.namespace]: config.value,
            };
          }, {}),
        };
      }

      return state;
    },
  },
  initialState
);
