import { toUpper } from 'lodash-es';

/*
TODO: Make sure the bucket is created before releasing it.
      https://glints.slack.com/archives/C05LTSN4EBW/p1699604478541259?thread_ts=1699604192.173659&cid=C05LTSN4EBW
*/
export const FILE_LABEL = 'job-report';
export const MAX_NUMBER_OF_FILES_ALLOWED = 5;
export const VALID_FILE_EXTENSIONS = ['jpeg', 'jpg', 'png'];
export const MAX_FILE_SIZE_IN_MB = 5;

export const ACCEPTED_FILE_TYPES = VALID_FILE_EXTENSIONS.map(
  (fileExtension) => `.${fileExtension}`
).join(',');

export const IMAGE_TYPE_COPY = VALID_FILE_EXTENSIONS.map(toUpper).join(', ');
