import { Actions, HistoryActionTypes } from 'src/actions/history';

export interface HistoryState {
  isOnpopstate: boolean;
  isBrowserPrevNextNavigation: boolean;
  asPaths: string[];
}

const initialState: HistoryState = {
  isOnpopstate: false,
  isBrowserPrevNextNavigation: false,
  asPaths: [],
};

const historyReducer = (
  state = initialState,
  action: HistoryActionTypes
): HistoryState => {
  switch (action.type) {
    case Actions.SET_IS_ONPOPSTATE:
      return {
        ...state,
        isOnpopstate: action.payload.isOnpopstate,
      };
    case Actions.RESET_IS_ONPOPSTATE:
      return {
        ...state,
        isBrowserPrevNextNavigation: state.isOnpopstate,
        isOnpopstate: false,
      };
    case Actions.PUSH_AS_PATH:
      // current asPath is asPaths[asPaths.length - 1]
      return {
        ...state,
        asPaths: [...state.asPaths, action.payload.asPath],
      };

    default:
      return state;
  }
};

export default historyReducer;
