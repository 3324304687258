import { InMemoryCache } from '@apollo/client';

import isServer from 'src/common/isServer';

import { typePolicies } from './cacheTypePolicies';
import possibleTypes from './possibleTypes.json';

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies,
}).restore(isServer ? {} : window.__NEXT_DATA__.props.apolloCache);

export default cache;
