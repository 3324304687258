import { mapKeys, snakeCase } from 'lodash-es';

import { ReduxThunkAction } from 'src/global/store';

const Actions = {
  REPORT_JOB: 'glints/GoogleTagManager/REPORT_JOB',
};

type ReportJobProperties = {
  jobId: string;
  companyId: string;
  reportReason?: string;
};

export const trackReportJob =
  (eventProperties: ReportJobProperties): ReduxThunkAction<any> =>
  (dispatch) =>
    dispatch({
      type: Actions.REPORT_JOB,
      payload: mapKeys(eventProperties, (value, key) => snakeCase(key)),
    });
