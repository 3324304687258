import axios, { AxiosResponse } from 'axios';
import { IClientConfigVars } from 'shared/interfaces/ClientConfig';

import { Locale } from 'src/common/locale';

const folderName = 'marketplace';

const localeMessages: Record<string, Record<string, string>> = {};
const etag: Record<string, string> = {};

export async function getLocaleMessages(
  locale: Locale,
  config: IClientConfigVars
) {
  // We replace locale json name here because Lokalise is using %LANG_ISO% as output filename (zh_TW)
  // while React-Intl and LanguageSwitcher are using BCP 47 language tag (zh-TW).

  const localeJsonName = locale.replace('-', '_');
  let messages = null;

  const etagValue = etag[localeJsonName];

  const eTagHeader = etagValue
    ? {
        'If-None-Match': etag[localeJsonName] || '',
      }
    : undefined;

  const requestConfig = {
    headers: eTagHeader,
    validateStatus: (status: number) => {
      return (status >= 200 && status < 300) || status === 304;
    },
  };

  const manageResponse = (response: AxiosResponse<any>) => {
    const latestEtag = response.headers.etag;

    if (latestEtag !== undefined && etag[localeJsonName] === latestEtag) {
      return localeMessages[localeJsonName];
    }

    etag[localeJsonName] = latestEtag;
    const { data } = response;
    localeMessages[localeJsonName] = data;

    return data;
  };

  messages = await axios
    .get(
      `https://${config?.S3_I18N}/${folderName}/${localeJsonName}.json`,
      requestConfig
    )
    .then((response) => manageResponse(response))
    .catch(() =>
      console.error(
        `[i18n] Unable to locate default locale '${localeJsonName}'`
      )
    );

  return messages;
}
