// TODO: Clean up the following css
// dropzoneFilepickerCustom from app/components/GlintsDropzone.js

import { Greyscale, ScreenSize, SecondaryColor } from 'glints-aries';
// @ts-ignore
import normalize from 'normalize.css/normalize.css';
import { createGlobalStyle, css } from 'styled-components';

import { dropzoneFilepickerCustom } from 'src/components/dropzone-filepicker-custom.sc';

const normalizeCss = css`
  ${normalize}
`;

/**
 * Application-level styles.
 */

export const GlobalStyles = createGlobalStyle`
  ${normalizeCss}
  body,
  #app {
    min-height: 100vh;
    background-color: white;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html,
  body {
    height: 100%;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
  }

  html {
    box-sizing: border-box;
  }

  body {
    overflow-x: hidden;
    min-width: ${ScreenSize.mobileS}px;
    background: ${Greyscale.white};
    color: ${Greyscale.black};
    line-height: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: normal;
  }

  p {
    margin: 0;
  }

  a,
  a:visited {
    text-decoration: none;
  }

  a {
    color: ${SecondaryColor.actionblue};
  }

  ::selection {
    background-color: #cce2ff;
    color: rgba(0, 0, 0, 0.87);
  }

  textarea::selection,
  input::selection {
    background-color: rgba(100, 100, 100, 0.4);
    color: rgba(0, 0, 0, 0.87);
  }

  /* The space between body and :: is necessary for the styles to be applied. */
  body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
  }

  body ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.25);
    transition: color 0.2s ease;
  }

  body ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0px;
  }

  li:focus {
    outline: none;
  }
  .whatsapp-float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    z-index: 100;
  }
  ${dropzoneFilepickerCustom}
`;
