// The name of the header that is used by SSR clients to verify themselves with
// the ApiProxyPlugin so that the plugin trusts the other SSR headers.
export const SSRTokenHeader = 'x-glints-ssr-token';

// The name of the header that carries the original x-forwarded-for from the
// request that initiated the downstream request.
export const SSRForwardedForHeader = 'x-glints-ssr-forwarded-for';

// The name of the header that carries the original remote address from the
// request that initiated the downstream request.
export const SSRRemoteAddressHeader = 'x-glints-ssr-remote-address';

export const CountryCodeHeader = 'x-glints-country-code';

export const SessionIDHeader = 'x-glints-session-id';

export const AppPlatformHeader = 'x-app-platform';
