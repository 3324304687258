import styled from 'styled-components';

import { glintsContainerResponsiveStyles } from 'src/styles/components/glintsContainer';

const GlintsContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
  ${glintsContainerResponsiveStyles};

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;

export default GlintsContainer;
