import merge from 'lodash/merge';

import { CALL_API } from 'src/common/constants';
import Schemas from 'src/common/schemas';
import { ReduxThunkAction } from 'src/global/store';
import {
  applicationSortingOption,
  applicationStatusQueryAndApplicationStatusMap,
} from 'src/modules/Applications/constants';
import {
  getMyApplicationsCompanyIds,
  getUserApplicationsItemsPerPage,
} from 'src/modules/Applications/selectors';
import { getSessionToken } from 'src/modules/Session/Selectors';

export const Actions = {
  APPLICATIONS_REQUEST: 'glints/user/applications/APPLICATIONS_REQUEST',
  APPLICATIONS_SUCCESS: 'glints/user/applications/APPLICATIONS_SUCCESS',
  APPLICATIONS_FAILURE: 'glints/user/applications/APPLICATIONS_FAILURE',

  APPLICATIONS_COMPANIES_REQUEST:
    'glints/user/applications/APPLICATIONS_COMPANIES_REQUEST',
  APPLICATIONS_COMPANIES_SUCCESS:
    'glints/user/applications/APPLICATIONS_COMPANIES_SUCCESS',
  APPLICATIONS_COMPANIES_FAILURE:
    'glints/user/applications/APPLICATIONS_COMPANIES_FAILURE',
};

export function fetchMyApplications(
  { fetchCompanies = false, fetchOpportunities = true } = {},
  options = { params: {} },
  orderBy = 'createdAt DESC'
): ReduxThunkAction<any> {
  return async (dispatch, getState) => {
    const authenticated = Boolean(getSessionToken(getState()));
    if (!authenticated) {
      return null;
    }

    const includeJob = fetchOpportunities ? { include: 'Job' } : {};
    const params = {
      ...includeJob,
      ...options.params,
      order: orderBy,
    };

    await dispatch({
      [CALL_API]: {
        types: [
          Actions.APPLICATIONS_REQUEST,
          Actions.APPLICATIONS_SUCCESS,
          Actions.APPLICATIONS_FAILURE,
        ],
        endpoint: 'me/applications',
        schema: Schemas.APPLICATION_ARRAY,
        options: merge(options, { params }),
      },
    });

    if (fetchCompanies) {
      const companyIds = getMyApplicationsCompanyIds(getState());

      dispatch({
        [CALL_API]: {
          types: [
            Actions.APPLICATIONS_COMPANIES_REQUEST,
            Actions.APPLICATIONS_COMPANIES_SUCCESS,
            Actions.APPLICATIONS_COMPANIES_FAILURE,
          ],
          endpoint: 'companies',
          schema: Schemas.COMPANY_ARRAY,
          options: {
            params: {
              includeHierarchicalLocation: true,
              attributes: JSON.stringify(['id', 'name', 'logo', 'LocationId']),
              where: {
                id: { $in: companyIds },
              },
            },
          },
        },
      });
    }
  };
}

const sortByOrderMap: { [x: string]: string } = {
  [applicationSortingOption.MostRecent]: 'createdAt DESC',
  [applicationSortingOption.Oldest]: 'createdAt ASC',
  // TODO: Update RESTCollectionController to enable this
  // [applicationSortingOption.AToZ]: null,
};

export function fetchMyApplicationsWithFilter(
  status: string,
  sortBy: string,
  page: number,
  createdBetween?: string
): ReduxThunkAction<any> {
  return async (dispatch, getState) => {
    const authenticated = Boolean(getSessionToken(getState()));
    if (!authenticated) {
      return null;
    }

    const limit = getUserApplicationsItemsPerPage(getState());
    const applicationStatus =
      status === 'All'
        ? {}
        : {
            where: {
              status: applicationStatusQueryAndApplicationStatusMap[status],
            },
          };

    const options = {
      params: {
        limit,
        offset: (page - 1) * limit,
        createdBetween,
        ...applicationStatus,
      },
    };

    const order = sortByOrderMap[sortBy];

    const params = {
      ...options.params,
      include: 'Job',
      includeFollowUpRequestSummary: true,
      order,
    };

    await dispatch({
      [CALL_API]: {
        types: [
          Actions.APPLICATIONS_REQUEST,
          Actions.APPLICATIONS_SUCCESS,
          Actions.APPLICATIONS_FAILURE,
        ],
        endpoint: 'me/applications',
        schema: Schemas.APPLICATION_ARRAY,
        options: merge(options, { params }),
      },
    });
    const companyIds = getMyApplicationsCompanyIds(getState());

    await dispatch({
      [CALL_API]: {
        types: [
          Actions.APPLICATIONS_COMPANIES_REQUEST,
          Actions.APPLICATIONS_COMPANIES_SUCCESS,
          Actions.APPLICATIONS_COMPANIES_FAILURE,
        ],
        endpoint: 'companies',
        schema: Schemas.COMPANY_ARRAY,
        options: {
          params: {
            includeHierarchicalLocation: true,
            attributes: JSON.stringify(['id', 'name', 'logo', 'LocationId']),
            where: {
              id: { $in: companyIds },
            },
          },
        },
      },
    });
  };
}
