import Axios from 'axios';

import { makeFullUrlApiCall } from 'src/middleware/api/helpers';

type AwsS3PresignedPostUrlOutput = {
  request: string;
  fields: [string, string][];
  name: string;
  label: string;
};

type AwsS3PresignedPostUrlInput = {
  name: string;
  type: string;
  label: string;
};

const getAwsS3PresignedPostUrl = async (
  inputParams: AwsS3PresignedPostUrlInput
) => {
  const response = await makeFullUrlApiCall(
    's3/uploadPostUrl',
    {
      params: inputParams,
    },
    'get'
  );
  return response.data as AwsS3PresignedPostUrlOutput;
};

export const uploadFileToS3 = async (
  file: File | Blob,
  options: AwsS3PresignedPostUrlInput
) => {
  const { request, name, fields } = await getAwsS3PresignedPostUrl(options);
  await uploadFileToS3FromPresignedData(file, {
    request,
    fields,
    name: options.name,
    label: options.label,
  });
  return name;
};

const uploadFileToS3FromPresignedData = (
  file: File | Blob,
  { request, name, fields, label }: AwsS3PresignedPostUrlOutput,
  formData = new FormData()
) => {
  fields.forEach(([key, value]) => {
    formData.append(key, value);
  });
  // We need to manually append custom metadata when uploading to OSS.
  if (request.includes('oss-ap-southeast-1.aliyuncs.com')) {
    formData.append('x-oss-meta-filename', name);
    formData.append('x-oss-meta-label', label);
  }
  formData.append('file', file);
  return Axios.post(request, formData);
};
