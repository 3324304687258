import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { createUrlUtils } from 'src/common/urlUtils/urlUtils';
import { getConfig } from 'src/modules/ClientConfig';

export const useUrlUtils = () => {
  const config = useSelector(getConfig);
  const urlUtils = useMemo(() => createUrlUtils(config), [config]);
  return urlUtils;
};
