import axios, { AxiosInstance } from 'axios';
import { IClientConfigVars } from 'shared/interfaces/ClientConfig';

export type DstAxios = (token?: string, baseURL?: string) => AxiosInstance;

interface AxiosConfig {
  baseURL: string;
  headers: StrIndexObject;
}

export function makeAxios(
  config: IClientConfigVars,
  headers?: StrIndexObject
): DstAxios {
  // Token not needed anymore, but keeping it for compatibility with the existing codes.
  return (token, baseURL = config.CANDIDATE_BASE + config.API_BASE) => {
    const axiosConfig: AxiosConfig = {
      baseURL,
      headers: {},
    };

    // Include the current country if we have it.
    if (config.COUNTRY) {
      axiosConfig.headers['X-Glints-Country-Code'] = config.COUNTRY;
    }

    if (headers) {
      axiosConfig.headers = { ...axiosConfig.headers, ...headers };
    }

    const instance = axios.create(axiosConfig);

    // Axios doesn't wrap stuff as an Error object. In order for redux-actions
    // to recognize something as an error (and set action.error), the rejected
    // promise must have a payload which is an instance of Error.
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!(error instanceof Error)) {
          error = Object.assign(new Error('A HTTP request has failed'), error);
        }

        return Promise.reject(error);
      }
    );

    return instance;
  };
}
