import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'isomorphic-fetch';

import { typePolicies } from 'src/graphql/cacheTypePolicies';
import possibleTypes from 'src/graphql/possibleTypes.json';

import { EAppPlatformKey } from '../../../server/src/enums/EAppPlatformKey';
import {
  AppPlatformHeader,
  SSRForwardedForHeader,
  SSRRemoteAddressHeader,
  SSRTokenHeader,
} from '../../../shared/ApiProxyPluginHeaders';
import { ApiV2AlcClientName, ApiV2ClientName } from './constants';

const createApolloClient = ({
  listenPort,
  token,
  cookie,
  ssrToken,
  remoteAddress,
  xForwardedFor,
  locale,
}) => {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        cookie,
        [SSRTokenHeader]: ssrToken,
        [SSRRemoteAddressHeader]: remoteAddress,
        [SSRForwardedForHeader]: xForwardedFor,
        [AppPlatformHeader]: EAppPlatformKey.WEB,
      },
    };
  });

  const activityLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'accept-language': locale,
      },
    };
  });

  const customFetch = (uri, options) => {
    // Append operation name to the query string
    // e.g. /api/graphql?operationName=searchJobs
    const { body } = options;
    const { operationName } = JSON.parse(body);
    return fetch(`${uri}?op=${operationName}`, options);
  };

  const httpLinkV1 = new HttpLink({
    uri: `http://localhost:${listenPort}/api/graphql`,
    fetch: customFetch,
  });

  const httpLinkV2 = new HttpLink({
    uri: `http://localhost:${listenPort}/api/v2/graphql`,
    fetch: customFetch,
  });

  const httpLinkV2Alc = new HttpLink({
    uri: `http://localhost:${listenPort}/api/v2-alc/graphql`,
    fetch: customFetch,
  });

  const terminatingLink = split(
    operation => operation.getContext().clientName === ApiV2ClientName,
    httpLinkV2,
    split(
      operation => operation.getContext().clientName === ApiV2AlcClientName,
      httpLinkV2Alc,
      httpLinkV1
    )
  );

  return new ApolloClient({
    ssrMode: true,
    link: from([authLink, activityLink, terminatingLink]),
    cache: new InMemoryCache({ possibleTypes, typePolicies }),
  });
};

export default createApolloClient;
