/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';

import isServer from 'src/common/isServer';

// References: https://github.com/beautifulinteractions/beautiful-react-hooks/blob/master/src/useMediaQuery.js
export const useMediaQuery = (mediaQuery: string) => {
  if (isServer || !('matchMedia' in window)) {
    return null;
  }

  const [isMatched, setIsMatched] = useState(
    Boolean(window.matchMedia(mediaQuery).matches)
  );

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    const documentChangeHandler = () =>
      setIsMatched(Boolean(mediaQueryList.matches));

    /**
     * MediaQueryList.addEventListener() is not supported on iOS browser, so we
     * need to use addListener instead, even it is marked as deprecated.
     * ref: https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
     *
     * Other plugins also use addListener and removeListener,
     * matchmediaquery (used by react-responsive): https://github.com/ncochard/matchmediaquery/blob/master/index.js#L14
     * useMediaQuery: https://github.com/beautifulinteractions/beautiful-react-hooks/blob/master/src/useMediaQuery.js#L29
     */
    mediaQueryList.addListener(documentChangeHandler);

    documentChangeHandler();
    return () => {
      mediaQueryList.removeListener(documentChangeHandler);
    };
  }, [mediaQuery]);

  return isMatched;
};
