import axios from 'axios';

import isServer from 'src/common/isServer';
import { State } from 'src/global/store';
import { getConfig } from 'src/modules/ClientConfig';

import { CallAPIOptions, RequestMethod } from './interfaces';

export const makeApiCall = (
  fullUrl: string,
  options: CallAPIOptions = {},
  method: RequestMethod = 'get'
) => {
  const { headers, ...redactedOptions } = options;
  const headerOptions = { headers };
  const methodHandlers = {
    get: () => axios(fullUrl, options),
    put: () => axios.put(fullUrl, redactedOptions, headerOptions),
    post: () => axios.post(fullUrl, redactedOptions, headerOptions),
    delete: () => axios.delete(fullUrl, options),
  };

  return methodHandlers[method]();
};

export const makeFullUrl = (endpoint: string, state: State) => {
  const API_BASE = getConfig(state).API_BASE;
  const CANDIDATE_BASE = getConfig(state).CANDIDATE_BASE;
  return `${CANDIDATE_BASE}${API_BASE}/${endpoint}`;
};

export const makeFullV2Url = (endpoint: string, state: State) => {
  const API_V2_BASE = getConfig(state).API_V2_ALC_BASE;
  const CANDIDATE_BASE = getConfig(state).CANDIDATE_BASE;
  return `${CANDIDATE_BASE}${API_V2_BASE}/${endpoint}`;
};

export function makeFullUrlApiCall(
  endpoint: string,
  options?: CallAPIOptions,
  method: RequestMethod = 'get',
  basePath?: string
) {
  if (isServer) {
    return null;
  }

  const store = window.__NEXT_REDUX_STORE__;
  const state = store.getState();

  if (basePath) {
    return makeApiCall(`${basePath}/${endpoint}`, options, method);
  }
  return makeApiCall(makeFullUrl(endpoint, state), options, method);
}
