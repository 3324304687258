interface CookieMap {
  [key: string]: string;
}

export const parseCookies = (cookieString: string): CookieMap => {
  return cookieString.split(';').reduce<CookieMap>((acc, part) => {
    const [key, value] = part.trim().split('=');
    acc[key] = decodeURIComponent(value);
    return acc;
  }, {});
};
