import { NextRouter } from 'next/router';
import { IClientConfigVars } from 'shared/interfaces/ClientConfig';

import { COUNTRY_CALLING_CODES, COUNTRY_DOMAINS } from 'src/common/constants';

export const initialValuesSignUpForm = (config: IClientConfigVars) => {
  const { COUNTRY } = config;
  return {
    sendUpdates: true,
    phone: JSON.stringify({
      callingCode: Number(
        COUNTRY_CALLING_CODES[
          COUNTRY ? COUNTRY.toLowerCase() : COUNTRY_DOMAINS.sg
        ].replace('+', '')
      ),
      significantNumber: null,
    }),
  };
};

export const getPrimaryPathName = (fullPath: string) => {
  const pathArr = (fullPath.split('/') || []).filter((v) => v !== '');
  if (pathArr.length === 0) {
    return '';
  }
  return pathArr[0];
};
type Query = { [key: string]: string | string[] };
export const getRouterQueryRedirect = (router: NextRouter): Query => {
  const query = { ...router.query };

  if (['/', '/signup', '/login', '/signup-email'].includes(router.pathname)) {
    return query;
  }

  return { ...query, next: router.pathname, nextAs: router.asPath };
};

export const getRouterQueryProduct = (router: NextRouter): Query => {
  const query = { ...router.query };

  if (['/', '/signup', '/login', '/signup-email'].includes(router.pathname)) {
    return query;
  }

  return { ...query, product: getPrimaryPathName(router.pathname) };
};
