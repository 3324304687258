import { TypePolicies } from '@apollo/client';

export const typePolicies: TypePolicies = {
  Answer: {
    fields: {
      comments: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
  Question: {
    fields: {
      answers: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
  ExpertIndonesianFinancialDetail: {
    keyFields: ['expertFinancialDetailId'],
  },
  ExpertIndonesianNPWPCardDetail: {
    keyFields: ['expertTaxDetailId'],
  },
};
