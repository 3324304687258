import { CALL_API } from 'src/common/constants';
import { ReduxThunkAction } from 'src/global/store';
import { getCountryWithSGFallback } from 'src/modules/ClientConfig/Selectors';

import { getDeviceIdFromCookie } from '../ClientConfig/helpers';

export const Actions = {
  FETCH_FEATURES_REQUEST: 'glints/unleash/FETCH_FEATURES_REQUEST',
  FETCH_FEATURES_SUCCESS: 'glints/unleash/FETCH_FEATURES_SUCCESS',
  FETCH_FEATURES_FAILURE: 'glints/unleash/FETCH_FEATURES_FAILURE',
};

export function fetchFeatures(): ReduxThunkAction<any> {
  return (dispatch, getState) => {
    const state = getState();
    const currentCountry = getCountryWithSGFallback(state);
    const cookie = state.session.cookie;
    const deviceId = getDeviceIdFromCookie(cookie);

    return dispatch({
      [CALL_API]: {
        types: [
          Actions.FETCH_FEATURES_REQUEST,
          Actions.FETCH_FEATURES_SUCCESS,
          Actions.FETCH_FEATURES_FAILURE,
        ],
        endpoint: 'enabled-feature-flags',
        options: {
          headers: {
            'x-glints-country-code': currentCountry,
            'x-glints-device-id': deviceId,
          },
        },
      },
    });
  };
}
