import { createAction } from 'redux-actions';

import { CALL_API } from 'src/common/constants';
import Schemas from 'src/common/schemas';
import { CallAPIOptions } from 'src/middleware/api/interfaces';

export const Actions = {
  OPPORTUNITIES_REQUEST: 'glints/opportunities/OPPORTUNITIES_REQUEST',
  OPPORTUNITIES_SUCCESS:
    'glints/GoogleTagManager/opportunities/OPPORTUNITIES_SUCCESS',
  OPPORTUNITIES_FAILURE: 'glints/opportunities/OPPORTUNITIES_FAILURE',

  CLEAR_OPPORTUNITIES: 'glints/opportunities/CLEAR_OPPORTUNITIES',

  MORE_OPPORTUNITIES_REQUEST: 'glints/opportunities/MORE_OPPORTUNITIES_REQUEST',
  MORE_OPPORTUNITIES_SUCCESS: 'glints/opportunities/MORE_OPPORTUNITIES_SUCCESS',
  MORE_OPPORTUNITIES_FAILURE: 'glints/opportunities/MORE_OPPORTUNITIES_FAILURE',

  SET_OPPORTUNITIES_LOCATION_QUERY_STRING:
    'glints/opportunities/SET_OPPORTUNITIES_LOCATION_QUERY_STRING',
};

export const clearOpportunities = createAction(Actions.CLEAR_OPPORTUNITIES);
export const setOpportunitiesLocationQueryString = createAction(
  Actions.SET_OPPORTUNITIES_LOCATION_QUERY_STRING
);

export function fetchOpportunities(page = 1, options: CallAPIOptions) {
  const limit =
    options.params && options.params.limit ? options.params.limit : 30;
  return {
    [CALL_API]: {
      types: [
        Actions.OPPORTUNITIES_REQUEST,
        Actions.OPPORTUNITIES_SUCCESS,
        Actions.OPPORTUNITIES_FAILURE,
      ],
      endpoint: 'jobs',
      schema: Schemas.OPPORTUNITY_ARRAY,
      options: {
        ...options,
        params: {
          ...(options ? options.params : {}),
          limit,
          offset: (page - 1) * limit,
          include:
            options && options.params && options.params.include
              ? options.params.include.concat(', Company')
              : 'Company',
          where: {
            ...(options && options.params ? options.params.where : {}),
            status: 'OPEN',
          },
        },
      },
    },
  };
}

export function fetchMoreOpportunities(page = 1, options: CallAPIOptions) {
  const limit =
    options.params && options.params.limit ? options.params.limit : 30;
  return {
    [CALL_API]: {
      types: [
        Actions.MORE_OPPORTUNITIES_REQUEST,
        Actions.MORE_OPPORTUNITIES_SUCCESS,
        Actions.MORE_OPPORTUNITIES_FAILURE,
      ],
      endpoint: 'jobs',
      schema: Schemas.OPPORTUNITY_ARRAY,
      options: {
        ...options,
        params: {
          ...(options ? options.params : {}),
          limit,
          offset: (page - 1) * limit,
          include:
            options && options.params && options.params.include
              ? options.params.include.concat(', Company')
              : 'Company',
          where: {
            ...(options && options.params ? options.params.where : {}),
            status: 'OPEN',
          },
        },
      },
    },
  };
}

export function fetchMoreMarketplaceOpportunities(
  page = 1,
  options: CallAPIOptions
) {
  const params = options.params || {};
  const limit = params.limit ? params.limit : 30;

  return {
    [CALL_API]: {
      types: [
        Actions.MORE_OPPORTUNITIES_REQUEST,
        Actions.MORE_OPPORTUNITIES_SUCCESS,
        Actions.MORE_OPPORTUNITIES_FAILURE,
      ],
      endpoint: 'marketplace/jobs',
      schema: Schemas.OPPORTUNITY_ARRAY,
      options: {
        ...options,
        params: {
          ...params,
          limit,
          offset: (page - 1) * limit,
        },
      },
    },
  };
}
