import React, { Component, Fragment } from 'react';
import NoSSR from '@mpth/react-no-ssr';
import {
  ArrowRoundForwardIcon,
  CloseIcon,
  Drawer,
  FileSolidIcon,
  Greyscale,
  PowerIcon,
  SettingSolidIcon,
  TicketSolidIcon,
  UserIcon,
} from 'glints-aries';
import Link from 'next/link';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { closeSidebar } from 'src/actions/app';
import authenticate from 'src/common/authenticate';
import { getClassName } from 'src/common/ComponentHelpers';
import { CountryCodes } from 'src/common/enums';
import { pageCategoryMapper } from 'src/common/helpers';
import { ROUTES } from 'src/common/routes';
import pushGTMEvent from 'src/common/utils/google-tag-manager';
import LanguageSwitcher from 'src/components/GlintsLanguageSwitcher';
import { ProfilePicture } from 'src/components/GlintsPicture';
import { EmployersLink } from 'src/components/links';
import { NewFeatureBadge } from 'src/components/NewFeatureBadge';
import { EBreakpoints, Media } from 'src/media';
import { getConfig, getCountry } from 'src/modules/ClientConfig/Selectors';
import { getIsExpertClassOrdersEnabled } from 'src/modules/ExpertClassOrder/Selectors';
import makeMenuItems from 'src/modules/MainContainer/Navbar/MenuItems';
import {
  employerSiteLinkMessage,
  settingPageLinkMessage,
} from 'src/modules/MainContainer/Navbar/messages';
import { logout } from 'src/modules/Session/Actions';
import { getFeatures } from 'src/modules/Unleash/Selectors';
import { getAvailableLanguages } from 'src/selectors/locale';
import { getUser } from 'src/selectors/user';

import { MobileLoginSignupButton } from './MobileLoginSignupButton';
import {
  ArrowRight,
  BorderWrapper,
  Container,
  Divider,
  DrawerContainer,
  FlexCenter,
  IconWrapper,
  LinkWrapper,
  NewFeatureBadgeContainer,
  PaddingTop,
  ProfilePictureWrapper,
  ProfileText,
  SectionTitleWrapper,
  TextWrapper,
  UpperCaseWrapper,
} from './NavigationComponents.sc';

class NavigationComponent extends Component {
  constructor(props) {
    super(props);
    const { config, features, authenticated } = this.props;
    this.menuItems = makeMenuItems(config, features, authenticated)().flatMap(
      i => i
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sidebarOpen !== this.props.sidebarOpen) {
      // to disable background scrolling
      if (this.props.sidebarOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = null;
      }
    }
  }

  countItems() {
    const { authenticated } = this.props;
    if (!authenticated) {
      return this.menuItems.filter(item => !item.requiresAuth);
    }
    return this.menuItems;
  }

  renderLanguageSwitcher() {
    const { availableLanguages } = this.props;
    return (
      <If condition={availableLanguages.length > 1}>
        <LanguageSwitcher
          fontSize={16}
          iconDefaultColor={Greyscale.white}
          textColor={Greyscale.white}
          fontWeight={600}
          languages={availableLanguages}
          showFullWidth={true}
        />
      </If>
    );
  }

  renderFilterItem() {
    const { country } = this.props;
    const filterMenuItems = this.countItems.bind(this)();
    return filterMenuItems.map((item, i) => (
      <Choose key={item}>
        <When condition={item.external}>
          <If condition={!country && (i === 1 || i === 3)}>
            <Divider />
          </If>
          <If condition={!country && i === 1}>
            <SectionTitleWrapper>Hiring solutions</SectionTitleWrapper>
          </If>
          <a
            href={item.to}
            target="_blank"
            key={item.label}
            className={getClassName(item.className)}
          >
            <TextWrapper>
              <Choose>
                <When condition={!country}>
                  <LinkWrapper>
                    <FormattedMessage {...item.message} />
                    <ArrowRight />
                  </LinkWrapper>
                </When>
                <Otherwise>
                  <UpperCaseWrapper
                    role="button"
                    aria-label={`${item.label} page`}
                  >
                    <FormattedMessage {...item.message} />
                  </UpperCaseWrapper>
                </Otherwise>
              </Choose>
              <If condition={item.new}>
                <NewFeatureBadgeContainer>
                  <NewFeatureBadge />
                </NewFeatureBadgeContainer>
              </If>
            </TextWrapper>
          </a>
        </When>
        <Otherwise>
          <Link
            key={item.to}
            href={item.to}
            as={item.as || item.to}
            prefetch={false}
          >
            <a
              target={item.target}
              onClick={this.props.closeSidebar}
              className={getClassName(item.className)}
            >
              <TextWrapper role="button" aria-label={`${item.label} page`}>
                <UpperCaseWrapper>
                  <FormattedMessage {...item.message} />
                </UpperCaseWrapper>
                <If condition={item.new}>
                  <NewFeatureBadgeContainer>
                    <NewFeatureBadge />
                  </NewFeatureBadgeContainer>
                </If>
              </TextWrapper>
            </a>
          </Link>
        </Otherwise>
      </Choose>
    ));
  }

  renderLoginAndSignUpSection() {
    const { closeSidebar } = this.props;
    return (
      <Fragment>
        <MobileLoginSignupButton
          href="/login"
          onClick={closeSidebar}
          type="login"
        >
          <TextWrapper
            textTransform="uppercase"
            role="button"
            aria-label="Login"
          >
            <FormattedMessage
              id="login"
              defaultMessage="Login"
              tagName="span"
            />
          </TextWrapper>
        </MobileLoginSignupButton>
        <MobileLoginSignupButton
          href="/signup"
          onClick={closeSidebar}
          type="signup"
        >
          <TextWrapper
            textTransform="uppercase"
            role="button"
            aria-label="Sign Up"
          >
            <FormattedMessage
              id="menu.button.signup"
              defaultMessage="Sign Up"
              tagName="span"
            />
          </TextWrapper>
        </MobileLoginSignupButton>
      </Fragment>
    );
  }

  renderLoggedInSection() {
    const { me } = this.props;
    return (
      <div>
        <Link href="/profile" as="/profile">
          <a>
            <FlexCenter>
              <ProfilePictureWrapper>
                <ProfilePicture
                  profilePic={me.profilePic}
                  userID={me.id}
                  alt="Profile Picture"
                  sizes="140px"
                  breakpoints={140}
                />
              </ProfilePictureWrapper>
              <ProfileText>
                {me.firstName} {me.lastName}
              </ProfileText>
            </FlexCenter>
          </a>
        </Link>
        {this.renderLoggedInFooterSection()}
      </div>
    );
  }

  redirectURL = (...args) => {
    this.props.router.push(...args);
    this.props.closeSidebar();
  };

  renderLoggedInFooterSection() {
    const { onLogoutItemClick, isExpertClassOrdersEnabled } = this.props;
    const redirectURL = this.redirectURL;
    return (
      <Fragment>
        <TextWrapper role="button" aria-label="My Profile">
          <FlexCenter onClick={() => redirectURL('/profile')}>
            <UserIcon color={Greyscale.white} />
            <FormattedMessage
              id="my.profile"
              defaultMessage="My Profile"
              tagName="span"
            />
          </FlexCenter>
        </TextWrapper>

        <TextWrapper role="button" aria-label="My Applications">
          <FlexCenter onClick={() => redirectURL(`/${ROUTES.applications}`)}>
            <FileSolidIcon color={Greyscale.white} />
            <FormattedMessage
              id="my.applications"
              defaultMessage="My Applications"
              tagName="span"
            />
          </FlexCenter>
        </TextWrapper>

        {isExpertClassOrdersEnabled && (
          <TextWrapper role="button" aria-label="My Tickets">
            <FlexCenter
              onClick={() => redirectURL(`/${ROUTES.expertClassOrders}`)}
            >
              <TicketSolidIcon color={Greyscale.white} />
              <FormattedMessage
                id="user-menu.expert-class-ticket"
                defaultMessage="My Tickets"
                tagName="span"
              />
            </FlexCenter>
          </TextWrapper>
        )}
        <TextWrapper role="button" aria-label="Account Settings">
          <FlexCenter onClick={() => redirectURL('/settings')}>
            <SettingSolidIcon color={Greyscale.white} />
            <FormattedMessage {...settingPageLinkMessage} tagName="span" />
          </FlexCenter>
        </TextWrapper>

        <TextWrapper
          onClick={onLogoutItemClick}
          role="button"
          aria-label="Logout"
        >
          <FlexCenter>
            <PowerIcon color={Greyscale.white} />
            <FormattedMessage
              id="logout"
              defaultMessage="Logout"
              tagName="span"
            />
          </FlexCenter>
        </TextWrapper>
      </Fragment>
    );
  }

  renderSidebar() {
    const { authenticated } = this.props;
    const pageCategory = pageCategoryMapper(this.props.router.pathname);

    const handleLinkOnClick = event => {
      event.preventDefault();
      pushGTMEvent('glints/GoogleTagManager/DOWNLOAD_APP_BUTTON_CLICKED', {
        payload: {
          placement: 'header',
          buttonCta: 'get glints app',
          pageCategory,
        },
      });

      window.open(event.currentTarget.href, '_blank', 'noopener,noreferrer');
    };

    return (
      <Container>
        <IconWrapper>
          <span
            role="button"
            aria-label="Close"
            title="Click to close"
            onClick={this.props.closeSidebar}
          >
            <CloseIcon color={Greyscale.white} />
          </span>
        </IconWrapper>
        <If condition={this.props.country === CountryCodes.ID}>
          <PaddingTop padding={15}>
            <a
              href={`https://dynamic-link.glints.com/page/XJbp`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleLinkOnClick}
            >
              <TextWrapper>
                <BorderWrapper>
                  <UpperCaseWrapper>
                    <FormattedMessage
                      id="text-get-glints-app"
                      defaultMessage="Get Glints App"
                      tagName="span"
                    />
                  </UpperCaseWrapper>
                </BorderWrapper>
              </TextWrapper>
            </a>
          </PaddingTop>
          <Divider />
        </If>
        <If condition={this.props.country}>
          <Choose>
            <When condition={authenticated}>
              {this.renderLoggedInSection()}
            </When>
            <Otherwise>{this.renderLoginAndSignUpSection()}</Otherwise>
          </Choose>
          <Divider />
        </If>
        {this.renderFilterItem()}
        <If condition={this.props.country}>
          <Divider />
          <EmployersLink>
            <TextWrapper>
              <UpperCaseWrapper>
                <FormattedMessage {...employerSiteLinkMessage} tagName="span" />
              </UpperCaseWrapper>
              <ArrowRoundForwardIcon color={Greyscale.white} />
            </TextWrapper>
          </EmployersLink>
        </If>
        {this.renderLanguageSwitcher()}
      </Container>
    );
  }

  render() {
    const { sidebarOpen, closeSidebar } = this.props;
    return (
      <DrawerContainer>
        <NoSSR>
          <Media lessThan={EBreakpoints.desktopS}>
            <Drawer
              isOpen={sidebarOpen}
              onClose={closeSidebar}
              className="drawer-background"
            >
              {this.renderSidebar()}
            </Drawer>
          </Media>
        </NoSSR>
        {this.props.children}
      </DrawerContainer>
    );
  }
}

NavigationComponent.propTypes = {
  children: PropTypes.node,
  sidebarOpen: PropTypes.bool,
  authenticated: PropTypes.bool,
  isExpertClassOrdersEnabled: PropTypes.bool,

  closeSidebar: PropTypes.func,
  onLogoutItemClick: PropTypes.func,
  availableLanguages: PropTypes.array,
  me: PropTypes.object,
  router: PropTypes.object,
  config: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  country: PropTypes.string,
};

NavigationComponent = authenticate(NavigationComponent, true);

export default connect(
  state => ({
    sidebarOpen: state.app.sidebarOpen,
    me: getUser(state),
    availableLanguages: getAvailableLanguages(state),
    config: getConfig(state),
    features: getFeatures(state),
    isExpertClassOrdersEnabled: getIsExpertClassOrdersEnabled(state),
    country: getCountry(state),
  }),
  dispatch => ({
    closeSidebar: () => dispatch(closeSidebar()),
    onLogoutItemClick: () => {
      dispatch(logout());
      dispatch(closeSidebar());
    },
  })
)(withRouter(NavigationComponent));
