import { init as initApm } from '@elastic/apm-rum';

import isServer from './isServer';

if (!isServer) {
  initApm({
    environment: window.__NEXT_DATA__.props.dstContext.clientConfig.ENVIRONMENT,
    pageLoadSpanId: window.__NEXT_DATA__.props.dstContext.apm?.pageLoadSpanId,
    pageLoadTraceId: window.__NEXT_DATA__.props.dstContext.apm?.pageLoadTraceId,
    serviceName: window.__NEXT_DATA__.props.dstContext.apm?.serviceName,
    serverUrl: window.__NEXT_DATA__.props.dstContext.apm?.serverUrl,
    serviceVersion: process.env.BUILD_ID || 'development',
  });
}
