import React, { ErrorInfo } from 'react';

type Props = { componentName: string; children: React.ReactNode };

class ConsoleErrorBoundary extends React.Component<
  Props,
  { hasError: boolean }
> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Provide error boundary here to continue to allow page to render.
    console.warn(
      `Error occurred while rendering ${this.props.componentName}: `,
      error,
      errorInfo
    );
  }
  render() {
    return this.props.children;
  }
}

export default ConsoleErrorBoundary;
