import React from 'react';
import { Upload } from 'glints-aries/lib/@next';
import { isNull } from 'lodash-es';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { uploadImageToS3 } from 'src/actions/uploadImageToS3';
import pushGTMEvent from 'src/common/utils/google-tag-manager';

import {
  ACCEPTED_FILE_TYPES,
  FILE_LABEL,
  MAX_FILE_SIZE_IN_MB,
  MAX_NUMBER_OF_FILES_ALLOWED,
  VALID_FILE_EXTENSIONS,
} from './constants';
import { uploadErrorMessages } from './messages';
import { FileData } from './ReportModalV2';

type UploadReportAttachmentProps = {
  index: number;
  loading: boolean;
  reportAttachments: FileData[];
  setReportAttachments: (reportAttachments: FileData[]) => void;
  setError: (error: string) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UploadReportAttachment = ({
  index,
  loading,
  reportAttachments,
  setReportAttachments,
  setError,
  setLoading,
}: UploadReportAttachmentProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const handleUpload = (file: File) => {
    setError('');
    pushGTMEvent('glints/GoogleTagManager/REPORT_JOB_FILE_UPLOAD_INITIATED');

    // If file is null, it means user removes the file
    if (file === null) {
      const newReportAttachments = [...reportAttachments];
      newReportAttachments.splice(index, 1);

      const isEmptyUploadInputNotPresent =
        newReportAttachments.filter(isNull).length === 0 ||
        newReportAttachments.length === 0;

      if (isEmptyUploadInputNotPresent) {
        newReportAttachments.push(null);
      }

      setReportAttachments(newReportAttachments);
      setLoading(false);
      return;
    }

    const fileSizeInMB = file.size / 1024 / 1024;
    const fileExtension = file.name.split('.').pop();

    if (!VALID_FILE_EXTENSIONS.includes(fileExtension)) {
      setError(formatMessage(uploadErrorMessages.fileTypeError));
      return;
    }

    if (fileSizeInMB > MAX_FILE_SIZE_IN_MB) {
      setError(
        formatMessage(uploadErrorMessages.fileTooLarge, {
          fileSize: Number(fileSizeInMB.toFixed(2)),
          maxSize: MAX_FILE_SIZE_IN_MB,
        })
      );
      return;
    }

    const onSuccess = ({ filename }: { filename: string }) => {
      pushGTMEvent('glints/GoogleTagManager/REPORT_JOB_FILE_UPLOAD_SUCCESS');
      const maxTotalUploadInputReached =
        reportAttachments.length === MAX_NUMBER_OF_FILES_ALLOWED;
      const newReportAttachments = maxTotalUploadInputReached
        ? [...reportAttachments]
        : [...reportAttachments, null];
      newReportAttachments[index] = {
        file,
        uploadedFileName: filename,
      };
      setReportAttachments(newReportAttachments);
      setLoading(false);
    };

    const onError = (error: Error, _: string) => {
      pushGTMEvent('glints/GoogleTagManager/REPORT_JOB_FILE_UPLOAD_FAILED', {
        payload: {
          error,
        },
      });
      const newReportAttachments = [...reportAttachments];
      newReportAttachments[index] = null;
      setReportAttachments(newReportAttachments);
      console.error(error);
      setLoading(false);
      setError(formatMessage(uploadErrorMessages.genericError));
    };

    setLoading(true);
    dispatch(uploadImageToS3(file, FILE_LABEL, onSuccess, onError));
  };

  // Show loading state only for the last upload input
  const isUploading = reportAttachments.length - 1 === index && loading;

  return (
    <Upload
      file={reportAttachments[index] ? reportAttachments[index].file : null}
      handleSetFile={(file) => handleUpload(file)}
      accept={ACCEPTED_FILE_TYPES}
      disableDefaultValidation={true}
      loading={isUploading}
    />
  );
};
