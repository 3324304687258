import { handleActions } from 'redux-actions';

import { Actions } from 'src/actions/currencies';
import { DstAction } from 'src/middleware/api/interfaces';

export interface CurrenciesState {
  loading: boolean;
  error: any;
  value: string[];
}

export const initialState: CurrenciesState = {
  loading: false,
  error: null,
  value: [],
};

export default handleActions<CurrenciesState, any>(
  {
    [Actions.CURRENCIES_REQUEST]: (state) => ({
      ...state,
      ...initialState,
      loading: true,
    }),
    [Actions.CURRENCIES_SUCCESS]: (state, action: DstAction) => ({
      ...state,
      loading: false,
      error: null,
      value: action.response.result,
    }),
    [Actions.CURRENCIES_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      value: [],
      error: action.error,
    }),
  },
  initialState
);
