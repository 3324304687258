import { defineMessage, defineMessages } from 'react-intl';

export enum EReportJobReason {
  INAPPROPRIATE_JOB = 'INAPPROPRIATE_JOB',
  VAGUE_JOB_DESCRIPTION = 'VAGUE_JOB_DESCRIPTION',
  SCAM_OR_FRAUD_JOB = 'SCAM_OR_FRAUD_JOB',
  INVOLVES_FEE_COLLECTION = 'INVOLVES_FEE_COLLECTION',
  BROKERAGE_COMPANY = 'BROKERAGE_COMPANY',
  OTHERS = 'OTHERS',
}

const radioLabelMessages = defineMessages({
  inappropriateJob: {
    id: 'inappropriate.job',
    defaultMessage: 'Inappropriate Job',
  },
  tooLittleInfo: {
    id: 'interactive-vague-job-description',
    defaultMessage: 'Vague Job Description',
  },
  fraudOrScam: {
    id: 'text-fraud-jobs',
    defaultMessage: 'Fraud Indication',
  },
  involvesFeeCollection: {
    id: 'text-involves-fee-collection',
    defaultMessage: 'Involves fee collection',
  },
  brokerageCompany: {
    id: 'text-brokerage-company',
    defaultMessage: 'Brokerage company',
  },
  others: {
    id: 'others',
    defaultMessage: 'Others',
  },
});
const radioLabelDescriptions = defineMessages({
  inappropriateJob: {
    id: 'interactive-contain-ethnicity-religion-race',
    defaultMessage:
      'Contains elements of ethnicity, religion, race, pornography or harm.',
  },
  tooLittleInfo: {
    id: 'interactive-missing-qualifications-or-responsibilities',
    defaultMessage:
      'Missing required candidate qualifications or job responsibilities, and/or has a totally different actual job description compared to what is stated in the job listing',
  },
  fraudOrScam: {
    id: 'interactive-company-requested-money',
    defaultMessage:
      "The company requested for candidate's personal information not related to the job, and/or offer high returns for minimal effort",
  },
  involvesFeeCollection: {
    id: 'text-company-chareges-a-fee',
    defaultMessage: 'The company charges a fee during the recruitment process',
  },
  brokerageCompany: {
    id: 'text-company-brokerage-sector',
    defaultMessage:
      'The company operates in the brokerage sector which trades futures contracts on commodities, currencies, stock indices or other financial instruments',
  },
  others: {
    id: 'interactive-other-problem-discomfort-issues',
    defaultMessage:
      'You experienced other problems, discomfort and issues during the job application process.',
  },
});
export const radioButtons = [
  {
    value: EReportJobReason.INAPPROPRIATE_JOB,
    title: radioLabelMessages.inappropriateJob,
    description: radioLabelDescriptions.inappropriateJob,
  },
  {
    value: EReportJobReason.VAGUE_JOB_DESCRIPTION,
    title: radioLabelMessages.tooLittleInfo,
    description: radioLabelDescriptions.tooLittleInfo,
  },
  {
    value: EReportJobReason.SCAM_OR_FRAUD_JOB,
    title: radioLabelMessages.fraudOrScam,
    description: radioLabelDescriptions.fraudOrScam,
  },
  {
    value: EReportJobReason.INVOLVES_FEE_COLLECTION,
    title: radioLabelMessages.involvesFeeCollection,
    description: radioLabelDescriptions.involvesFeeCollection,
  },
  {
    value: EReportJobReason.BROKERAGE_COMPANY,
    title: radioLabelMessages.brokerageCompany,
    description: radioLabelDescriptions.brokerageCompany,
  },
  {
    value: EReportJobReason.OTHERS,
    title: radioLabelMessages.others,
    description: radioLabelDescriptions.others,
  },
];

export const placeholderMessage = defineMessage({
  id: 'text-provide-information-take-action',
  defaultMessage:
    'Please provide us with more information so that we can take the necessary action',
});

export const atLeast20Characters = defineMessage({
  id: 'text-at-least-20-characters',
  defaultMessage: '(at least 20 characters)',
});

export const uploadErrorMessages = defineMessages({
  fileTooLarge: {
    id: 'text-file-size-error',
    defaultMessage:
      'Your file is too big. The file you uploaded is {fileSize} MB. The maximum file size is {maxSize} MB.',
  },
  fileTypeError: {
    id: 'text-accepted-file-type',
    defaultMessage:
      'Unsupported file type. Accepted file type: Image (JPG, PNG, JPEG) only.',
  },
  genericError: {
    id: 'text-unable-to-upload-file',
    defaultMessage: 'Unable to upload the file. Please try again.',
  },
});
