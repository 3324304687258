import React from 'react';
import { useSelector } from 'react-redux';
import { SWRConfig as BaseSWRConfig } from 'swr';

import { makeFullUrlApiCall } from 'src/middleware/api/helpers';
import { getLanguage } from 'src/selectors/locale';

export const SWRProvider = ({ ...props }) => {
  const locale = useSelector(getLanguage);

  const fetcher = async (endpoint: string) => {
    const res = await makeFullUrlApiCall(endpoint, {
      headers: { 'accept-language': locale },
    });
    return res.data;
  };

  const config = {
    fetcher,
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  };

  return <BaseSWRConfig {...props} value={config} />;
};
