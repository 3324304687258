import { useSelector } from 'react-redux';

import { getCountryWithSGFallback } from 'src/modules/ClientConfig/Selectors';

import { countryCodeAndNameMap, CountryCodes } from '../enums';

export const useCountryData = () => {
  const countryCode = useSelector(getCountryWithSGFallback);
  const englishCountryName = countryCodeAndNameMap[countryCode];
  return { countryCode, englishCountryName };
};

export const useIsCountryCodeVNOrID = (): boolean => {
  const countryCode = useSelector(getCountryWithSGFallback);
  return isCountryCodeID(countryCode) || isCountryCodeVN(countryCode);
};

export const isCountryCodeVNOrID = (countryCode?: CountryCodes): boolean => {
  return isCountryCodeID(countryCode) || isCountryCodeVN(countryCode);
};

export const useIsCountryCodeID = (): boolean => {
  const countryCode = useSelector(getCountryWithSGFallback);
  return countryCode === CountryCodes.ID;
};

export const isCountryCodeID = (countryCode?: CountryCodes): boolean => {
  return countryCode === CountryCodes.ID;
};

export const useIsCountryCodeVN = (): boolean => {
  const countryCode = useSelector(getCountryWithSGFallback);
  return countryCode === CountryCodes.VN;
};

export const isCountryCodeVN = (countryCode?: CountryCodes): boolean => {
  return countryCode === CountryCodes.VN;
};
