import { State } from 'src/global/store';

export const getNotificationState = (state: State) =>
  state.notifications.closeable.isActive;
export const getNotificationMessage = (state: State) =>
  state.notifications.closeable.message;
export const getNotificationType = (state: State) =>
  state.notifications.closeable.type;
export const getNotificationPosition = (state: State) =>
  state.notifications.closeable.position;
