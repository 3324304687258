import React, { useCallback, useState } from 'react';

import { ModalProps } from './types';

export type ModalComponent = React.ComponentType<
  React.PropsWithChildren<ModalProps>
>;
export const useModal = (
  ModalComponent: ModalComponent,
  modalProps?: Record<string, any>
) => {
  // shouldRenderModal is for dynamic load ModalComponent,
  // when ModalComponent is dynamic imported,
  // the chunk will not be loaded until openModal is called.
  const [shouldRenderModal, setShouldRenderModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const openModal = useCallback(() => {
    if (!shouldRenderModal) {
      setShouldRenderModal(true);
    }
    setIsModalOpen(true);
  }, [shouldRenderModal]);

  return {
    isModalOpen,
    closeModal,
    openModal,
    modal: shouldRenderModal ? (
      <ModalComponent
        isOpen={isModalOpen}
        onClose={closeModal}
        {...modalProps}
      />
    ) : null,
  };
};
