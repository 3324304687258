import { schema } from 'normalizr';

/**
 * We use this Normalizr schemas to transform API responses from a nested form
 * to a flat form where repos and users are placed in `entities`, and nested
 * JSON objects are replaced with their IDs. This is very convenient for
 * consumption by reducers, because we can easily build a normalized tree
 * and keep it updated as we fetch more data.
 *
 * Read more about Normalizr: https://github.com/paularmstrong/normalizr
 */

const ApplicationSchema = new schema.Entity(
  'application',
  {},
  { idAttribute: 'id' }
);
const BookmarkSchema = new schema.Entity('bookmark', {}, { idAttribute: 'id' });
const CampaignSchema = new schema.Entity('campaign', {});
const CitySchema = new schema.Entity('city', {}, { idAttribute: 'id' });
const CompanySchema = new schema.Entity('company', {}, { idAttribute: 'id' });
const CountrySchema = new schema.Entity('country', {}, { idAttribute: 'code' });
const CurrencySchema = new schema.Entity(
  'currency',
  {},
  { idAttribute: 'code' }
);
const DegreeSchema = new schema.Entity('degree', {}, { idAttribute: 'id' });
const EventSchema = new schema.Entity('event', {}, { idAttribute: 'id' });
const FollowSchema = new schema.Entity('follow', {}, { idAttribute: 'id' });
const GroupSchema = new schema.Entity('group', {}, { idAttribute: 'id' });

const JobRolePreferences = new schema.Entity('jobRolePreferences', {});
const JobTitleSchema = new schema.Entity('jobTitle', {}, { idAttribute: 'id' });
const IndustrySchema = new schema.Entity('industry', {}, { idAttribute: 'id' });
const OpportunitySchema = new schema.Entity(
  'opportunity',
  {},
  { idAttribute: 'id' }
);
const PlatformSchema = new schema.Entity('platform', {}, { idAttribute: 'id' });
const RecommendedJobsSchema = new schema.Entity('recommendedJobs', {});
const SchoolSchema = new schema.Entity('school', {}, { idAttribute: 'id' });
const SkillSchema = new schema.Entity('skill', {}, { idAttribute: 'id' });
const OpportunitySkillSchema = new schema.Entity(
  'opportunitySkill',
  {},
  { idAttribute: 'id' }
);
const StudySchema = new schema.Entity('study', {}, { idAttribute: 'id' });
const UserSchema = new schema.Entity('user');

UserSchema.define({
  links: {
    city: CitySchema,
    country: CountrySchema,
  },
});

ApplicationSchema.define({
  links: {
    job: OpportunitySchema,
  },
});

BookmarkSchema.define({
  links: {
    company: CompanySchema,
    event: EventSchema,
  },
});

CompanySchema.define({
  links: {
    jobs: [OpportunitySchema],
  },
});

OpportunitySchema.define({
  links: {
    company: CompanySchema,
    skills: [OpportunitySkillSchema],
  },
});

EventSchema.define({});

export default {
  APPLICATION: ApplicationSchema,
  APPLICATION_ARRAY: [ApplicationSchema],
  BOOKMARK: BookmarkSchema,
  BOOKMARK_ARRAY: [BookmarkSchema],
  CAMPAIGN: CampaignSchema,
  CAMPAIGN_ARRAY: [CampaignSchema],
  CITY_ARRAY: [CitySchema],
  COMPANY: CompanySchema,
  COMPANY_ARRAY: [CompanySchema],
  COUNTRY_ARRAY: [CountrySchema],
  CURRENCY: CurrencySchema,
  CURRENCY_ARRAY: [CurrencySchema],
  DEGREE: DegreeSchema,
  DEGREE_ARRAY: [DegreeSchema],
  EVENT_ARRAY: [EventSchema],
  FOLLOW: FollowSchema,
  FOLLOW_ARRAY: [FollowSchema],
  GROUP: GroupSchema,
  GROUP_ARRAY: [GroupSchema],
  INDUSTRY: IndustrySchema,
  INDUSTRY_ARRAY: [IndustrySchema],
  JOB_ROLE_PREFERENCES: JobRolePreferences,
  JOB_ROLE_PREFERENCES_ARRAY: [JobRolePreferences],
  JOB_TITLE: JobTitleSchema,
  JOB_TITLE_ARRAY: [JobTitleSchema],
  OPPORTUNITY: OpportunitySchema,
  OPPORTUNITY_ARRAY: [OpportunitySchema],
  OPPORTUNITY_SKILL: OpportunitySkillSchema,
  OPPORTUNITY_SKILL_ARRAY: [OpportunitySkillSchema],
  PLATFORM: PlatformSchema,
  PLATFORM_ARRAY: [PlatformSchema],
  RECOMMENDED_JOBS: RecommendedJobsSchema,
  RECOMMENDED_JOBS_ARRAY: [RecommendedJobsSchema],
  SCHOOL: SchoolSchema,
  SCHOOL_ARRAY: [SchoolSchema],
  SKILL: SkillSchema,
  SKILL_ARRAY: [SkillSchema],
  STUDY: StudySchema,
  STUDY_ARRAY: [StudySchema],
  USER: UserSchema,
};
