import React, { useEffect } from 'react';
import NoSSR from '@mpth/react-no-ssr';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getIsAuthenticated } from 'src/modules/Session/Selectors';

import isServer from './isServer';

export default function authenticate(WrappedComponent, optional = false) {
  const AuthenticatedComponent = ({ authenticated, ...restProps }) => {
    const router = useRouter();

    useEffect(() => {
      if (!authenticated && !optional) {
        router.push({
          pathname: '/login',
          query: {
            ...router.query,
            next: router.pathname,
            nextAs: router.asPath,
          },
        });
      }
    });

    // need to render authenticated page on client for Cypress to work since we can't mock the token on the server side
    const fromCypress = !isServer && window.Cypress;
    if ((authenticated || optional) && fromCypress) {
      return (
        <NoSSR>
          <WrappedComponent authenticated={authenticated} {...restProps} />
        </NoSSR>
      );
    }

    if (authenticated || optional) {
      return <WrappedComponent authenticated={authenticated} {...restProps} />;
    }

    return null;
  };

  AuthenticatedComponent.propTypes = {
    authenticated: PropTypes.bool,
  };

  const mapStateToProps = state => ({
    authenticated: getIsAuthenticated(state),
  });

  return connect(mapStateToProps, null)(AuthenticatedComponent);
}
