import { ScreenSize } from 'glints-aries';
import { css } from 'styled-components';

export const glintsContainerWidth = {
  desktopM: '1200px',
  desktopL: '1400px',
};

// Duplicates the responsive styles for padding and width found in GlintsContainer from glints-aries
// To be used for components that need to follow the responsive styles but cannot be wrapped by GlintsContainer
export const glintsContainerResponsiveStyles = css`
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: ${ScreenSize.mobileM}px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: ${ScreenSize.mobileL}px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: ${ScreenSize.tablet}px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (min-width: ${ScreenSize.desktopM}px) {
    width: ${glintsContainerWidth.desktopM};
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (min-width: ${ScreenSize.desktopL}px) {
    width: ${glintsContainerWidth.desktopL};
    padding-left: 20px;
    padding-right: 20px;
  }
`;
