import { createAction } from 'redux-actions';

import { ReduxThunkAction } from 'src/global/store';

export const Actions = {
  REQUEST_JOB_ROLE_PREFERENCES:
    'glints/user/jobRolePreferences/REQUEST_JOB_ROLE_PREFERENCES',
  RECEIVE_JOB_ROLE_PREFERENCES:
    'glints/user/jobRolePreferences/RECEIVE_JOB_ROLE_PREFERENCES',
  CLEAR_JOB_ROLE_PREFERENCES:
    'glints/user/jobRolePreferences/CLEAR_JOB_ROLE_PREFERENCES',
};

export const requestJobRolePreferences = createAction(
  Actions.REQUEST_JOB_ROLE_PREFERENCES
);
export const receiveJobRolePreferences = createAction(
  Actions.RECEIVE_JOB_ROLE_PREFERENCES
);
export const clearJobRolePreferences = createAction(
  Actions.CLEAR_JOB_ROLE_PREFERENCES
);

export function fetchJobRolePreferences(): ReduxThunkAction<any> {
  return async (dispatch, getState, { api }) => {
    dispatch(requestJobRolePreferences());
    try {
      const response = await api(getState().session.token).get(
        '/me/jobRolePreferences',
        {
          params: {
            include: 'HierarchicalJobCategory',
          },
        }
      );
      dispatch(receiveJobRolePreferences(response.data.data));
    } catch (err) {
      dispatch(receiveJobRolePreferences(err));
      throw err;
    }
  };
}
