import { useSelector, useStore } from 'react-redux';

import { makeFullUrlApiCall } from 'src/middleware/api/helpers';
import { getConfig } from 'src/modules/ClientConfig';
import { ExperimentApiProviders } from 'src/modules/Unleash/constants';
import { getV2ApiProvider } from 'src/modules/Unleash/Selectors';
import { getLanguage } from 'src/selectors/locale';

export default function useGetSWRFetcherForApiV2() {
  const locale = useSelector(getLanguage);
  const experimentV2ApiProviderVariant = useSelector(getV2ApiProvider);
  const useAlicloud =
    experimentV2ApiProviderVariant === ExperimentApiProviders.ALICLOUD;

  const store = useStore();
  const state = store.getState();
  const API_V2_BASE = getConfig(state).API_V2_BASE;
  const API_V2_ALC_BASE = getConfig(state).API_V2_ALC_BASE;
  const API_BASE = useAlicloud ? API_V2_ALC_BASE : API_V2_BASE;

  const CANDIDATE_BASE = getConfig(state).CANDIDATE_BASE;

  const fetcher = async (endpoint: string) => {
    const res = await makeFullUrlApiCall(
      endpoint,
      {
        headers: { 'accept-language': locale },
      },
      undefined,
      `${CANDIDATE_BASE}${API_BASE}`
    );
    return res.data;
  };

  return {
    fetcher,
  } as const;
}
