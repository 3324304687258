import React, { PureComponent } from 'react';
import { Button } from 'glints-aries';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { closeExternalLinkModal } from '../../../actions/app';
import StyledModal from './RedirectToExternalLinkModal.sc';

class RedirectToExternalLinkModal extends PureComponent {
  generateDefaultTitle = () => {
    const { formatMessage } = this.props.intl;
    return formatMessage({
      id: 'external.link.modal.go.to.link',
      defaultMessage: 'Go to link',
    });
  };

  generateDefaultContent = () => {
    const { link } = this.props;
    return (
      <FormattedMessage
        id="external.link.modal.content"
        defaultMessage={`You’ll be redirected to {link}.{br}{br}
          Remember, this website is outside our platform and security.
          So, always be careful with your data.{br}{br}
          Hang on tight!`}
        values={{ br: <br />, link }}
        tagName="span"
      />
    );
  };

  closeModalAfterRedirecting = () => {
    const { closeExternalLinkModal } = this.props;
    setTimeout(() => closeExternalLinkModal(), 0);
  };

  render() {
    const {
      className,
      link,
      intl: { formatMessage },
      title = this.generateDefaultTitle(),
      content = this.generateDefaultContent(),
      isVisible,
      closeExternalLinkModal,
    } = this.props;
    return (
      <StyledModal
        className={className}
        title={title}
        isVisible={isVisible}
        onClose={closeExternalLinkModal}
        footer={[
          <Button
            key="cancel"
            variant="ghost"
            small={true}
            onClick={closeExternalLinkModal}
          >
            {formatMessage({
              id: 'external.link.modal.cancel',
              defaultMessage: 'Cancel',
            })}
          </Button>,
          <a key="go.to.link" href={link} target="_blank" rel="noreferrer">
            <Button
              variant="solid-blue"
              small={true}
              onClick={this.closeModalAfterRedirecting}
            >
              {formatMessage({
                id: 'external.link.modal.go.to.link',
                defaultMessage: 'Go to link',
              })}
            </Button>
          </a>,
        ]}
      >
        {content}
      </StyledModal>
    );
  }
}

RedirectToExternalLinkModal.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  isVisible: PropTypes.bool.isRequired,
  closeExternalLinkModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isVisible: state.app.externalLinkModal.isVisible,
  link: state.app.externalLinkModal.link,
});

export default compose(
  injectIntl,
  connect(mapStateToProps, { closeExternalLinkModal })
)(RedirectToExternalLinkModal);
