import { handleActions } from 'redux-actions';

import { DstAction } from 'src/middleware/api/interfaces';

import { Actions } from '../actions/industry';

export interface IndustryState {
  loading: boolean;
  error: any;
  value: number;
}

export const initialState: IndustryState = {
  loading: false,
  error: null,
  value: null,
};

export default handleActions(
  {
    [Actions.INDUSTRY_REQUEST]: (state) => ({
      ...state,
      loading: true,
      error: null,
      value: null,
    }),
    [Actions.INDUSTRY_SUCCESS]: (state, action: DstAction) => ({
      ...state,
      loading: false,
      error: null,
      value: action.response.result,
    }),
    [Actions.INDUSTRY_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
      value: null,
    }),
    [Actions.CLEAR_INDUSTRY]: () => initialState,
  },
  initialState
);
