import { handleActions } from 'redux-actions';

import { Actions } from 'src/actions/similarJobs';
import { Job } from 'src/global/models';
import { DstAction } from 'src/middleware/api/interfaces';

export interface SimilarJobsState {
  loading: boolean;
  error: any;
  value: Job[];
}

export const initialState: SimilarJobsState = {
  loading: false,
  error: null,
  value: [],
};

export default handleActions<SimilarJobsState, any>(
  {
    [Actions.SIMILAR_JOBS_REQUEST]: (state) => ({
      ...state,
      loading: true,
      error: null,
      value: [],
    }),
    [Actions.SIMILAR_JOBS_SUCCESS]: (state, action: DstAction<any, Job[]>) => {
      return {
        ...state,
        loading: false,
        error: null,
        value: action.response,
      };
    },
    [Actions.SIMILAR_JOBS_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
      value: [],
    }),
  },
  initialState
);
