import React from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';

import { Products } from 'src/common/enums';

export const loginSignupModalTypes = {
  joinExpertClass: 'joinExpertClass',
  confirmCurrentUser: 'confirmCurrentUser',
  generic: 'generic',
};

function buildLoginSignupModalText(loginSignupObj) {
  const {
    loginSignUpModalTitle,
    loginModalTitle,
    signUpModalTitle,
    loginModalButtonTitle,
    signUpModalButtonTitle,
    loginModalContentHeader,
    signUpModalContentHeader,
    gtmPayloadString,
  } = loginSignupObj;
  const hasModalTitle =
    loginSignUpModalTitle || (loginModalTitle && signUpModalTitle);

  if (
    !hasModalTitle ||
    !loginModalButtonTitle ||
    !signUpModalButtonTitle ||
    !loginModalContentHeader ||
    !signUpModalContentHeader
  ) {
    throw new Error('LoginSignupObj lacks some fields');
  }
  return {
    login: {
      modalTitle: loginSignUpModalTitle || loginModalTitle,
      buttonTitle: loginModalButtonTitle,
      contentHeader: loginModalContentHeader,
    },
    signup: {
      modalTitle: loginSignUpModalTitle || signUpModalTitle,
      buttonTitle: signUpModalButtonTitle,
      contentHeader: signUpModalContentHeader,
    },
    gtmPayloadString,
  };
}

function buildFormattedMessage(message) {
  return <FormattedMessage {...message} tagName="span" />;
}

export const LoginSignUpModalMessages = {
  [loginSignupModalTypes.joinExpertClass]: {
    loginModalTitle: defineMessage({
      id: 'expert-class.login-modal.title.join-event',
      defaultMessage: 'Login',
    }),
    signUpModalTitle: defineMessage({
      id: 'expert-class.signup-modal.title.join-event',
      defaultMessage: 'Sign Up',
    }),
    loginModalButtonTitle: defineMessage({
      id: 'expert-class.login-modal.button.join-event',
      defaultMessage: 'Login',
    }),
    signUpModalButtonTitle: defineMessage({
      id: 'expert-class.signup-modal.button.join-event',
      defaultMessage: 'Sign Up',
    }),
    loginModalContentHeader: defineMessage({
      id: 'expert-class.login-modal.content.join-event',
      defaultMessage: 'Login to your Glints account to join the ExpertClass.',
    }),
    signUpModalContentHeader: defineMessage({
      id: 'expert-class.signup-modal.content.join-event',
      defaultMessage: 'Let’s create a Glints account and join the ExpertClass.',
    }),
  },
};

export const loginSignupModalText = {
  [loginSignupModalTypes.joinExpertClass]: buildLoginSignupModalText({
    loginModalTitle: buildFormattedMessage(
      LoginSignUpModalMessages[loginSignupModalTypes.joinExpertClass]
        .loginModalTitle
    ),
    signUpModalTitle: buildFormattedMessage(
      LoginSignUpModalMessages[loginSignupModalTypes.joinExpertClass]
        .signUpModalTitle
    ),
    loginModalButtonTitle: buildFormattedMessage(
      LoginSignUpModalMessages[loginSignupModalTypes.joinExpertClass]
        .loginModalButtonTitle
    ),
    signUpModalButtonTitle: buildFormattedMessage(
      LoginSignUpModalMessages[loginSignupModalTypes.joinExpertClass]
        .signUpModalButtonTitle
    ),
    loginModalContentHeader: buildFormattedMessage(
      LoginSignUpModalMessages[loginSignupModalTypes.joinExpertClass]
        .loginModalContentHeader
    ),
    signUpModalContentHeader: buildFormattedMessage(
      LoginSignUpModalMessages[loginSignupModalTypes.joinExpertClass]
        .signUpModalContentHeader
    ),
    gtmPayloadString: Products.ExpertClass,
  }),
  [loginSignupModalTypes.confirmCurrentUser]: buildLoginSignupModalText({
    loginSignUpModalTitle: buildFormattedMessage(
      defineMessage({
        id: 'confirm.current.user.modal.title',
        defaultMessage: 'Login',
      })
    ),
    loginModalButtonTitle: buildFormattedMessage(
      defineMessage({
        id: 'confirm.current.user.modal.login.button',
        defaultMessage: 'Login',
      })
    ),
    signUpModalButtonTitle: buildFormattedMessage(
      defineMessage({
        id: 'confirm.current.user.modal.signup.button',
        defaultMessage: 'Signup',
      })
    ),
    loginModalContentHeader: buildFormattedMessage(
      defineMessage({
        id: 'expertclasscertificate-login-modal-header',
        defaultMessage:
          'Login to your Glints account to get your ExpertClass certificate.',
      })
    ),
    signUpModalContentHeader: buildFormattedMessage(
      defineMessage({
        id: 'expertclasscertificate-signup-modal-header',
        defaultMessage:
          'Sign up to your Glints account to get your ExpertClass certificate.',
      })
    ),
  }),
  [loginSignupModalTypes.generic]: buildLoginSignupModalText({
    loginSignUpModalTitle: buildFormattedMessage(
      defineMessage({
        id: 'login-page_modal-header"',
        defaultMessage: 'Login',
      })
    ),
    loginModalButtonTitle: buildFormattedMessage(
      defineMessage({
        id: 'login.signup.modal..login.button',
        defaultMessage: 'Login',
      })
    ),
    signUpModalButtonTitle: buildFormattedMessage(
      defineMessage({
        id: 'login.signup.modal..signup.button',
        defaultMessage: 'Signup',
      })
    ),
    loginModalContentHeader: buildFormattedMessage(
      defineMessage({
        id: 'login.signup.modal.login.header',
        defaultMessage: 'Login to your Glints account to continue.',
      })
    ),
    signUpModalContentHeader: buildFormattedMessage(
      defineMessage({
        id: 'login.signup.modal.signup.header',
        defaultMessage: 'Sign up to your Glints account to continue.',
      })
    ),
  }),
};

export const ELoginSignupModalActionType = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
};
