import { merge } from 'lodash';

import { CALL_API } from 'src/common/constants';
import Schemas from 'src/common/schemas';
import { ReduxThunkAction } from 'src/global/store';
import { CallAPIOptions } from 'src/middleware/api/interfaces';

export const Actions = {
  CURRENCIES_REQUEST: 'glints/currencies/CURRENCIES_REQUEST',
  CURRENCIES_SUCCESS: 'glints/currencies/CURRENCIES_SUCCESS',
  CURRENCIES_FAILURE: 'glints/currencies/CURRENCIES_FAILURE',
};

export function fetchCurrencies(
  options?: CallAPIOptions
): ReduxThunkAction<any> {
  return (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [
          Actions.CURRENCIES_REQUEST,
          Actions.CURRENCIES_SUCCESS,
          Actions.CURRENCIES_FAILURE,
        ],
        endpoint: 'currencies',
        schema: Schemas.CURRENCY_ARRAY,
        options: merge(options, {
          params: {
            order: 'code ASC',
            limit: 0,
          },
        }),
      },
    });
  };
}
