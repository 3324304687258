import { handleActions } from 'redux-actions';

import { Actions } from 'src/actions/notifications';

export enum ToastType {
  INFO = 'info',
  SUCCESS = 'success',
  FAILURE = 'failure',
  DEFAULT = 'default',
}

interface Toast {
  isActive: boolean;
  message: string;
  barStyle: {
    type?: ToastType;
  };
}

interface Snackbar {
  isActive: boolean;
  message: string;
}

interface Closeable {
  isActive: boolean;
  message: string | JSX.Element;
  type: string;
  position?: string;
  autoCloseInterval?: number;
}

export interface NotificationsState {
  toasts: any[];
  toast: Toast;
  snackbar: Snackbar;
  closeable: Closeable;
}

export const initialState: NotificationsState = {
  toasts: [],
  toast: {
    isActive: false,
    message: '',
    barStyle: {},
  },
  snackbar: {
    isActive: false,
    message: '',
  },
  closeable: {
    isActive: false,
    message: '',
    type: ToastType.SUCCESS,
    position: 'top',
    autoCloseInterval: 0,
  },
};
export default handleActions<NotificationsState, any>(
  {
    [Actions.UPDATE_TOASTS]: (state, action) => ({
      ...state,
      toasts: action.error ? state.toasts : action.payload,
    }),
    [Actions.SHOW_CLOSEABLE_NOTIFICATION]: (state, action) => ({
      ...state,
      closeable: {
        isActive: true,
        message: action.payload.message || action.payload,
        type: action.payload.type || ToastType.SUCCESS,
        autoCloseInterval: action.payload.autoCloseInterval,
      },
    }),
    [Actions.DISMISS_CLOSEABLE_NOTIFICATION]: (state) => ({
      ...state,
      closeable: {
        ...state.closeable,
        isActive: false,
      },
    }),
    [Actions.SHOW_CLOSEABLE_NOTIFICATION_WITH_TYPE]: (state, action) => ({
      ...state,
      closeable: {
        isActive: true,
        message: action.payload.message,
        type: action.payload.type || ToastType.SUCCESS,
        position: action.payload.position || 'top',
        autoCloseInterval: action.payload.autoCloseInterval,
      },
    }),
  },
  initialState
);
