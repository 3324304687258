import { handleActions } from 'redux-actions';

import { DstAction } from 'src/middleware/api/interfaces';
import { Actions } from 'src/modules/Applications/actions';

export interface ApplicationsState {
  loading: boolean;
  error: any;
  value: string[];
  total: number;
  totalPages: number;
  itemsPerPage: number;
  opportunities: {
    value: string[];
  };
  companies: {
    loading: boolean;
    error: any;
  };
}

const initialState: ApplicationsState = {
  loading: false,
  error: null,
  value: [],
  total: null,
  totalPages: 0,
  itemsPerPage: 5,
  opportunities: {
    value: [],
  },
  companies: {
    loading: true,
    error: null,
  },
};

export const applicationsReducer = handleActions<ApplicationsState, any>(
  {
    [Actions.APPLICATIONS_REQUEST]: (state) => ({
      ...state,
      loading: true,
      error: null,
      value: [],
    }),
    [Actions.APPLICATIONS_SUCCESS]: (state, action: DstAction) => ({
      ...state,
      loading: false,
      error: null,
      value: action.response.result,
      opportunities: {
        ...state.opportunities,
        value: action.response.result.map(
          (id: string) => action.response.entities.application[id].JobId
        ),
      },
      totalPages:
        action.response.count > 0
          ? Math.ceil(action.response.count / state.itemsPerPage)
          : 0,
      total: action.response.count || 0,
    }),
    [Actions.APPLICATIONS_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
      value: [],
    }),
    [Actions.APPLICATIONS_COMPANIES_REQUEST]: (state) => ({
      ...state,
      companies: {
        ...state.companies,
        loading: true,
        error: null,
      },
    }),
    [Actions.APPLICATIONS_COMPANIES_SUCCESS]: (state) => ({
      ...state,
      companies: {
        ...state.companies,
        loading: false,
        error: null,
      },
    }),
    [Actions.APPLICATIONS_COMPANIES_FAILURE]: (state, action) => ({
      ...state,
      companies: {
        ...state.companies,
        loading: false,
        error: action.error,
      },
    }),
  },
  initialState
);
