import { handleActions } from 'redux-actions';

import { Actions } from 'src/actions/server';

export interface ServerState {
  userAgent: string;
}

export const initialState: ServerState = {
  userAgent: '',
};

export default handleActions<ServerState, any>(
  {
    [Actions.SET_USER_AGENT]: (state, action) => ({
      userAgent: action.payload,
    }),
  },
  initialState
);
