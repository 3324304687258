import { kebabCase, mapValues } from 'lodash';

import { ROUTES } from 'src/common/routes';

export enum OnboardingUsage {
  OnboardingGuided = 'onboardingGuided',
}

export const ONBOARDING_CONFIG_BY_USAGE = {
  [OnboardingUsage.OnboardingGuided]: {
    metadataKey: 'onboarding',
    redirectionRoute: ROUTES.opportunitiesJobsRecommended,
    route: ROUTES.onboardingGuided,
    successQuery: 'onboardingCompleted',
  },
};

export enum OnboardingType {
  Default = 'DEFAULT',
  JobSeeker = 'JOB_SEEKER',
  JobSeekerShortened = 'JOB_SEEKER_SHORTENED',
  JobSeekerShortenedWithWhatsapp = 'JOB_SEEKER_SHORTENED_WITH_WHATSAPP',
}

export enum StepNames {
  CandidateStatus = 'CANDIDATE_STATUS',
  JobInterest = 'JOB_INTEREST',
  JobPreferences = 'JOB_PREFERENCES',
  ProfilePicture = 'PROFILE_PICTURE',
  ProfileTypeAndResume = 'PROFILE_TYPE_AND_RESUME',
  WorkExperience = 'WORK_EXPERIENCE',
  Education = 'EDUCATION',
  Skills = 'SKILLS',
  Whatsapp = 'WHATSAPP',
  Success = 'SUCCESS',
}

export const StepPathNames = mapValues(StepNames, kebabCase);

const jobSeekerDefaultFlow = [
  StepNames.CandidateStatus,
  StepNames.JobInterest,
  StepNames.JobPreferences,
  StepNames.ProfilePicture,
  StepNames.ProfileTypeAndResume,
  StepNames.Success,
];

const jobSeekerShortenedFlow = [
  StepNames.JobInterest,
  StepNames.JobPreferences,
  StepNames.Success,
];

const jobSeekerShortenedWithWhatsappFlow = [
  StepNames.JobInterest,
  StepNames.JobPreferences,
  StepNames.Whatsapp,
  StepNames.Success,
];

export const STEPS_BY_TYPE = {
  [OnboardingType.Default]: [StepNames.CandidateStatus],
  [OnboardingType.JobSeeker]: jobSeekerDefaultFlow,
  [OnboardingType.JobSeekerShortened]: jobSeekerShortenedFlow,
  [OnboardingType.JobSeekerShortenedWithWhatsapp]:
    jobSeekerShortenedWithWhatsappFlow,
};

export const FIRST_DEFAULT_STEP = STEPS_BY_TYPE[OnboardingType.Default][0];

export function getMinTotalSteps() {
  const stepsByType = { ...STEPS_BY_TYPE };
  delete stepsByType[OnboardingType.Default];
  return Math.min(...Object.values(stepsByType).map((steps) => steps.length));
}
