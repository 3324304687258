import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { TERMS_AND_CONDITIONS } from 'src/common/constants';
import { CountryCodes } from 'src/common/enums';
import { useCountry } from 'src/common/hooks';
import { useUrlUtils } from 'src/common/hooks/useUrlUtils';
import { ROUTES } from 'src/common/routes';
import { AssetType } from 'src/components/GlintsPicture/interfaces';

const label = (
  <FormattedMessage
    id="signup.terms.link"
    defaultMessage="{url} Terms of Service and Privacy Policy"
    values={{ url: 'Glints' }}
    tagName="span"
  />
);

const TOSLink = ({ style }) => {
  const country = useCountry();
  const { getAssetURL } = useUrlUtils();
  if (country === CountryCodes.TW) {
    return (
      <a
        style={style}
        target="_blank"
        href={getAssetURL(AssetType.docs, TERMS_AND_CONDITIONS.tw)}
      >
        {label}
      </a>
    );
  } else {
    return (
      <Link href={`/${ROUTES.terms}`}>
        <a style={style} target="_blank">
          {label}
        </a>
      </Link>
    );
  }
};

TOSLink.propTypes = {
  style: PropTypes.object,
};

export default TOSLink;
