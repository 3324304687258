import { ReduxThunkAction } from 'src/global/store';
import { getConfig } from 'src/modules/ClientConfig';

import { uploadFileToS3 } from './assets';

const UPLOAD_IMAGE_TO_S3_START =
  'glints/upload_image_to_S3/UPLOAD_IMAGE_TO_S3_START';
const UPLOAD_IMAGE_TO_S3_SUCCESS =
  'glints/upload_image_to_S3/UPLOAD_IMAGE_TO_S3_SUCCESS';
const UPLOAD_IMAGE_TO_S3_START_FAIL =
  'glints/upload_image_to_S3/UPLOAD_IMAGE_TO_S3_START_FAIL';

const maxSizeMB = 1.2;
const maxWidthOrHeight = 1920;

export const uploadImageToS3 =
  (
    file: File,
    label: string,
    onSuccess: (successParam: { filename: string }) => void,
    onError?: (error: Error, message: string) => void
  ): ReduxThunkAction<any> =>
  async (dispatch, getState, { api }) => {
    dispatch({ type: UPLOAD_IMAGE_TO_S3_START });

    const imageCompression = (await import('browser-image-compression'))
      .default;

    const { AWS_BASE } = getConfig(getState());
    try {
      const response = await api(null, AWS_BASE).get('/s3PrepareUpload', {
        params: {
          name: file.name,
          type: file.type,
          label,
        },
      });

      let resizedFile;
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > maxSizeMB || file.type === 'image/jpeg') {
        resizedFile = await imageCompression(file, {
          maxSizeMB,
          maxWidthOrHeight,
        });
      } else {
        resizedFile = file;
      }

      const { request: requestUrl, name: filename } = response.data;

      const xhr = new XMLHttpRequest();
      xhr.open('PUT', requestUrl);
      xhr.setRequestHeader('Content-Type', file.type);

      xhr.onload = () => {
        dispatch({ type: UPLOAD_IMAGE_TO_S3_SUCCESS });
        onSuccess({ filename });
      };
      xhr.send(resizedFile);
    } catch (err) {
      console.error(err);
      if (onError) {
        if (err.error && err.error.detail.type) {
          onError(err, "You can't upload files of this type.");
        } else {
          onError(err, 'There was an error uploading the file.');
        }
      }
      dispatch({ type: UPLOAD_IMAGE_TO_S3_START_FAIL });
    }
  };

export const uploadImageToS3WithAuth =
  (
    file: File,
    label: string,
    onSuccess: (successParam: { filename: string }) => void,
    onError?: (error: Error, message: string) => void
  ): ReduxThunkAction<any> =>
  async (dispatch) => {
    dispatch({ type: UPLOAD_IMAGE_TO_S3_START });

    const imageCompression = (await import('browser-image-compression'))
      .default;

    try {
      let resizedFile;
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > maxSizeMB || file.type === 'image/jpeg') {
        resizedFile = await imageCompression(file, {
          maxSizeMB,
          maxWidthOrHeight,
        });
      } else {
        resizedFile = file;
      }
      const filename = await uploadFileToS3(resizedFile, {
        name: file.name,
        type: file.type,
        label,
      });
      dispatch({ type: UPLOAD_IMAGE_TO_S3_SUCCESS });
      onSuccess({ filename });
    } catch (err) {
      console.error(err);
      if (onError) {
        if (err.error && err.error.detail.type) {
          onError(err, "You can't upload files of this type.");
        } else {
          onError(err, 'There was an error uploading the file.');
        }
      }
      dispatch({ type: UPLOAD_IMAGE_TO_S3_START_FAIL });
    }
  };
