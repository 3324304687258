import React from 'react';
import { FormattedMessage } from 'react-intl';

import { BookmarkTypeIds } from 'src/global/models/Bookmark';
import { BookmarkTypeKeys } from 'src/reducers/user/bookmarks';

export enum BOOKMARK_TYPES {
  company = 'COMPANY',
  opportunity = 'OPPORTUNITY',
  event = 'EVENT',
}

export const BOOKMARK_MAP = {
  [BOOKMARK_TYPES.company]: {
    idKey: 'CompanyId' as keyof BookmarkTypeIds,
    reduxStateKey: 'companies' as keyof BookmarkTypeKeys,
  },
  [BOOKMARK_TYPES.opportunity]: {
    idKey: 'JobId' as keyof BookmarkTypeIds,
    reduxStateKey: 'opportunities' as keyof BookmarkTypeKeys,
  },
  [BOOKMARK_TYPES.event]: {
    idKey: 'EventId' as keyof BookmarkTypeIds,
    reduxStateKey: 'events' as keyof BookmarkTypeKeys,
  },
};

// for certain page redirect back from /login that needs to have the entity id in the query
// eg: /companies/[id]?id=xxx
export const BOOKMARK_REDIRECT_QUERY_ID_KEY = {
  [BOOKMARK_TYPES.company]: 'id',
};

export enum BOOKMARK_NOTIFICATION_TYPES {
  bookmarkSuc = 'BOOKMARK_SUCCESS',
  unbookmarkSuc = 'UNBOOKMARK_SUCCESS',
  hadBookmarked = 'HAD_BOOKMARKED',
}

export const BOOKMARK_NOTIFICATION_MAP = {
  [BOOKMARK_NOTIFICATION_TYPES.bookmarkSuc]: {
    [BOOKMARK_TYPES.company]: (
      <FormattedMessage
        id="company.has.been.bookmarked"
        defaultMessage="Company has been bookmarked"
        tagName="span"
      />
    ),
    [BOOKMARK_TYPES.opportunity]: (
      <FormattedMessage
        id="job.has.been.bookmarked"
        defaultMessage="Job has been bookmarked"
        tagName="span"
      />
    ),
    [BOOKMARK_TYPES.event]: (
      <FormattedMessage
        id="event.has.been.bookmarked"
        defaultMessage="Event has been bookmarked"
        tagName="span"
      />
    ),
  },
  [BOOKMARK_NOTIFICATION_TYPES.unbookmarkSuc]: {
    [BOOKMARK_TYPES.company]: (
      <FormattedMessage
        id="company.has.been.removed.from.your.bookmarks"
        defaultMessage="Company has been removed from your bookmarks"
        tagName="span"
      />
    ),
    [BOOKMARK_TYPES.opportunity]: (
      <FormattedMessage
        id="job.has.been.removed.from.your.bookmarks"
        defaultMessage="Job has been removed from your bookmarks"
        tagName="span"
      />
    ),
    [BOOKMARK_TYPES.event]: (
      <FormattedMessage
        id="event.has.been.removed.from.your.bookmarks"
        defaultMessage="Event has been removed from your bookmarks"
        tagName="span"
      />
    ),
  },
  [BOOKMARK_NOTIFICATION_TYPES.hadBookmarked]: {
    [BOOKMARK_TYPES.company]: (
      <FormattedMessage
        id="you.have.bookmarked.this.company.before"
        defaultMessage="You have bookmarked this company before"
        tagName="span"
      />
    ),
    [BOOKMARK_TYPES.opportunity]: (
      <FormattedMessage
        id="you.have.bookmarked.this.job.before"
        defaultMessage="You have bookmarked this job before"
        tagName="span"
      />
    ),
    [BOOKMARK_TYPES.event]: (
      <FormattedMessage
        id="you.have.bookmarked.this.event.before"
        defaultMessage="You have bookmarked this event before"
        tagName="span"
      />
    ),
  },
};
