import { createSelector } from 'reselect';

import { State } from 'src/global/store';

import { getOpportunities, getOpportunitySkills } from './api';

const getOpportunity = (state: State) => state.opportunity;

export const getCurrentOpportunityId = (state: State) =>
  getOpportunity(state).value;

export const getOpportunityLoading = (state: State) =>
  getOpportunity(state).loading;

export const getOpportunityError = (state: State) =>
  getOpportunity(state).error;

export const getIsOpportunityModalVisible = (state: State) =>
  getOpportunity(state).visible;

export const getIsOpportunityDirectApply = (state: State) =>
  getOpportunity(state).directApply;

export const getCurrentOpportunity = createSelector(
  [getCurrentOpportunityId, getOpportunities],
  (id, opportunities) => opportunities[id]
);

const getCurrentOpportunitySkillIds = (state: State) =>
  getOpportunity(state).skills;

export const getCurrentOpportunitySkills = createSelector(
  [getCurrentOpportunitySkillIds, getOpportunitySkills],
  (ids, skills) => ids.map((id) => skills[id])
);

export const getIsApplicationSuccessModalVisible = (state: State) =>
  getOpportunity(state).applicationSuccessModal.isVisible;
