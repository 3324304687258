export enum CountryCodes {
  ID = 'ID',
  SG = 'SG',
  VN = 'VN',
  MY = 'MY',
  TW = 'TW',
}

export const defaultCityProvinceAll = 'All Cities/Provinces';
export const defaultCityProvinceInVN = 'Thành phố Hồ Chí Minh';
export const defaultLocationIdInVN = 'dcc3718e-ff4c-403e-9600-818e76cc8a64';

export const countryCodeAndNameMap = {
  [CountryCodes.ID]: 'Indonesia',
  [CountryCodes.SG]: 'Singapore',
  [CountryCodes.VN]: 'Vietnam',
  [CountryCodes.MY]: 'Malaysia',
  [CountryCodes.TW]: 'Taiwan',
};

export enum Products {
  ExpertClass = 'expertClass',
  Marketplace = 'marketplace',
}

export enum OrderType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum AffirmationStatuses {
  ALREADY_AFFIRMED = 'alreadyAffirmed',
  FAILED = 'failed',
  SUCCESS = 'success',
}

export enum ExpertClassClassification {
  ClassType = 'type',
  Category = 'category',
  CategoryClassType = 'category-type',
}

export enum CANDIDATE_MOBILE_APP_URL {
  ANDROID = 'https://play.google.com/store/apps/details?id=com.glints.candidate',
  IOS = 'https://apps.apple.com/app/glints-job-search-career/id1613169954',
}

export enum MobileAppPlatform {
  ANDROID = 'android',
  IOS = 'ios',
}

export enum APIVersion {
  V1 = 'v1',
  V2 = 'v2',
}
