import { createAction } from 'redux-actions';

export const Actions = {
  UPDATE_TOASTS: 'glints/Notifications/UPDATE_TOASTS',
  SHOW_CLOSEABLE_NOTIFICATION:
    'glints/Notifications/SHOW_CLOSE_ABLE_NOTIFICATION',
  SHOW_CLOSEABLE_NOTIFICATION_WITH_TYPE:
    'glints/Notifications/SHOW_CLOSE_ABLE_NOTIFICATION_WITH_TYPE',
  DISMISS_CLOSEABLE_NOTIFICATION:
    'glints/Notifications/DISMISS_CLOSE_ABLE_NOTIFICATION',
};

export const dismissCloseableNotification = createAction(
  Actions.DISMISS_CLOSEABLE_NOTIFICATION
);
export const showCloseableNotification = createAction(
  Actions.SHOW_CLOSEABLE_NOTIFICATION
);
export const showCloseableNotificationWithType = createAction(
  Actions.SHOW_CLOSEABLE_NOTIFICATION_WITH_TYPE
);
