import * as React from 'react';
import { NextPageContext } from 'next';
import ErrorPage from 'next/error';

import Error403Page from 'src/pages/Error403Page';
import Error404Page from 'src/pages/Error404Page';

export interface ErrorPageProps {
  statusCode?: number;
  error: Error;
  info: React.ErrorInfo;
  clearError: () => void;
}

class DstErrorPage extends React.Component<ErrorPageProps> {
  static async getInitialProps(ctx: NextPageContext) {
    return ErrorPage.getInitialProps(ctx);
  }

  render() {
    switch (this.props.statusCode) {
      case 403:
        return <Error403Page />;
      default:
        return <Error404Page />;
    }
  }
}

export default DstErrorPage;
