export enum PageCategory {
  GLINTS_HOMEPAGE = 'glints_homepage',
  JOBS_EXPLORE = 'jobs_explore_page',
  JOBS_FYP = 'jobs_fyp',
  JOBS_BOOKMARKED = 'jobs_bookmarked',
  JOB_DETAIL = 'jobs_detail_page',
  COMPANIES = 'companies_homepage',
  COMPANY_DETAIL = 'company_detail_page',
  EXPERTCLASS = 'expertclass',
  OTHERS = 'others',
}
