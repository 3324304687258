import { CALL_API } from 'src/common/constants';

export const Actions = {
  FETCH_GENERAL_CONFIG_REQUEST:
    'glints/generalConfig/FETCH_GENERAL_CONFIG_REQUEST',
  FETCH_GENERAL_CONFIG_SUCCESS:
    'glints/generalConfig/FETCH_GENERAL_CONFIG_SUCCESS',
  FETCH_GENERAL_CONFIG_FAILURE:
    'glints/generalConfig/FETCH_GENERAL_CONFIG_FAILURE',
};

export function fetchGeneralConfig() {
  return {
    [CALL_API]: {
      types: [
        Actions.FETCH_GENERAL_CONFIG_REQUEST,
        Actions.FETCH_GENERAL_CONFIG_SUCCESS,
        Actions.FETCH_GENERAL_CONFIG_FAILURE,
      ],
      endpoint: 'generalConfigs',
    },
  };
}
