import { handleActions } from 'redux-actions';

import {
  Actions,
  BookmarkPayload,
  BookmarkResponse,
} from 'src/actions/user/bookmarks';
import { DstAction, DstResponse } from 'src/middleware/api/interfaces';
import { BOOKMARK_MAP, BOOKMARK_TYPES } from 'src/modules/Bookmark';

function getBookmarksFromResponseByType(
  response: DstResponse<string[]>,
  type: BOOKMARK_TYPES
) {
  const bookmarks = response.entities.bookmark;
  const idKey = BOOKMARK_MAP[type].idKey;
  return response.result
    .filter((id) => bookmarks[id][idKey])
    .map((id) => bookmarks[id][idKey]);
}

interface BookmarkTypeState {
  value: string[];
  bookmarking: string[];
}

export interface BookmarkTypeKeys {
  companies: BookmarkTypeState;
  opportunities: BookmarkTypeState;
  events: BookmarkTypeState;
}

export interface BookmarksState extends BookmarkTypeKeys {
  loading: boolean;
  error: any;
  value: string[];
  lastFetched: string;
}

export const initialState: BookmarksState = {
  loading: false,
  error: null,
  value: [],
  lastFetched: null,
  companies: {
    value: [],
    bookmarking: [],
  },
  opportunities: {
    value: [],
    bookmarking: [],
  },
  events: {
    value: [],
    bookmarking: [],
  },
};

export default handleActions<BookmarksState, any>(
  {
    [Actions.FETCH_BOOKMARKS_REQUEST]: (state) => ({
      ...state,
      loading: true,
      error: null,
      lastFetched: null,
    }),
    [Actions.FETCH_BOOKMARKS_SUCCESS]: (state, action: DstAction) => ({
      ...state,
      loading: false,
      error: null,
      value: action.response.result,
      lastFetched: new Date().toISOString().split('.')[0],
      companies: {
        ...state.companies,
        value: getBookmarksFromResponseByType(
          action.response,
          BOOKMARK_TYPES.company
        ),
      },
      opportunities: {
        ...state.opportunities,
        value: getBookmarksFromResponseByType(
          action.response,
          BOOKMARK_TYPES.opportunity
        ),
      },
      events: {
        ...state.events,
        value: getBookmarksFromResponseByType(
          action.response,
          BOOKMARK_TYPES.event
        ),
      },
    }),
    [Actions.FETCH_BOOKMARKS_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
      value: [],
      lastFetched: null,
      companies: {
        ...state.companies,
        value: [],
      },
      opportunities: {
        ...state.opportunities,
        value: [],
      },
      events: {
        ...state.events,
        value: [],
      },
    }),
    [Actions.BOOKMARK_REQUEST]: (
      state,
      action: DstAction<any, BookmarkResponse>
    ) => {
      const { type, id } = action.response.requestPayload;
      const stateKey = BOOKMARK_MAP[type].reduxStateKey;

      return {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          bookmarking: [...new Set(state[stateKey].bookmarking.concat(id))],
        },
      };
    },
    [Actions.BOOKMARK_SUCCESS]: (
      state,
      action: DstAction<any, BookmarkResponse>
    ) => {
      const { type, id } = action.response.successPayload as BookmarkPayload;
      const stateKey = BOOKMARK_MAP[type].reduxStateKey;

      return {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          bookmarking: state[stateKey].bookmarking.filter(
            (value) => value !== id
          ),
        },
      };
    },
    [Actions.BOOKMARK_FAILURE]: (
      state,
      action: DstAction<any, BookmarkResponse>
    ) => {
      const { type, id } = action.response.failurePayload as BookmarkPayload;
      const stateKey = BOOKMARK_MAP[type].reduxStateKey;

      return {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          bookmarking: state[stateKey].bookmarking.filter(
            (value) => value !== id
          ),
        },
      };
    },
    [Actions.CLEAR_BOOKMARKS]: () => ({
      ...initialState,
    }),
  },
  initialState
);
