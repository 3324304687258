import { defineMessage, defineMessages } from 'react-intl';

export enum Recency {
  THIS_WEEK = 'this week',
  NEXT_WEEK = 'next week',
  THIS_MONTH = 'this month',
  THIS_WEEKEND = 'this weekend',
}

export const recencyOptions = [
  {
    id: Recency.THIS_WEEK,
    name: 'This week',
  },
  {
    id: Recency.NEXT_WEEK,
    name: 'Next week',
  },
  {
    id: Recency.THIS_MONTH,
    name: 'This month',
  },
  {
    id: Recency.THIS_WEEKEND,
    name: 'This weekend',
  },
];

export const expertClassesDefaultSortOrder = 'startDate ASC, createdAt ASC';

export const BREAKPOINTS = {
  DESKTOP_SMALL: 1024,
  TABLET: 768,
  DESKTOP_MEDIUM: 1280,
};

export const LIMIT = {
  DEFAULT: 30,
  SEARCH: 3,
};

export const FilterKey = {
  CLASS_TYPE: 'classType',
  CATEGORIES: 'categories',
  RECENCY: 'recency',
  KEY_WORD: 'keyword',
  SHOW_CASE: 'showCase',
};

export enum ClassTypeNames {
  WEBINAR = 'class-type_name_webinar',
  ON_DEMAND = 'class-type_name_on-demand',
}

export const classTypeDisplayNames: { [x: string]: string } = {
  [ClassTypeNames.WEBINAR]: 'Livestream',
  [ClassTypeNames.ON_DEMAND]: 'On-Demand',
};

export const popularCategoriesImage = [
  '/images/events/categories/top-1.png',
  '/images/events/categories/top-2.png',
  '/images/events/categories/top-3.png',
];

export const Keys = {
  All: 'all',
};

export const DataKey = {
  FEATURED_CLASS: 'getFeaturedClassShowcase',
  HOT_DEAL: 'getHotDealShowcase',
  SPECIAL_PROGRAM: 'getSpecialProgramShowcase',
  CATEGORIES: 'getPopularCategoriesShowcase',
};

export const classTypeDescriptionMessages: {
  [key: string]: { id: string; defaultMessage: string };
} = defineMessages({
  'class-type_description_webinar': {
    id: 'gec-webinar-listingpage_heading-text_page-description',
    defaultMessage:
      'Get practical insights straight from experts in a 2-hour interactive webinar.',
  },
  'class-type_description_workshop': {
    id: 'gec-workshop-listingpage_heading-text_page-title',
    defaultMessage:
      'Master a specific topic with hands-on experiences to develop your career skills in 4-8 hours for 2-4 sessions.',
  },
  'class-type_description_intensive-program': {
    id: 'gec-intensiveprogram-listingpage_heading-text_page-title',
    defaultMessage:
      'Upgrade your expertise in a particular field with cross-disciplinary of knowledge and skills to enable an end-to-end process, in 10-16 hours for 5-8 sessions.',
  },
  'class-type_description_on-demand': {
    id: 'gec-on-demand-listingpage_heading-text_page-title',
    defaultMessage:
      'Gain more knowledge anytime and anywhere with a set of self-learning methods in 1.5-2 hours.',
  },
  'class-type_description_mentorship': {
    id: 'gec-mentorship-listingpage_heading-text_page-title',
    defaultMessage:
      'Get job-related advice and guidance from experts in a 45 minutes-1 hour one-on-one session.',
  },
  'class-type_description_bundle': {
    id: 'gec-bundle-listingpage_heading-text_page-title',
    defaultMessage:
      ' Learn various topics and get a deeper understanding of a certain field in 1.5-2 hours each topic.',
  },
  'class-type_description_premium-membership': {
    id: 'gec-premium-membership-listingpage_heading-text_page-title',
    defaultMessage:
      'Be our exlusive member to learn more classes and save up to million rupiah!',
  },
});

export const RecencyMessages = defineMessages({
  [Recency.NEXT_WEEK]: {
    id: 'general_text-filter_next-week',
    defaultMessage: 'Next week',
  },
  [Recency.THIS_WEEKEND]: {
    id: 'General_Text-Filter_This-Weekend',
    defaultMessage: 'This weekend',
  },
  [Recency.THIS_MONTH]: {
    id: 'general_text-filter_this-month',
    defaultMessage: 'Next weeek',
  },
  [Recency.THIS_WEEK]: {
    id: 'General_Text-Filter_This-Week',
    defaultMessage: 'This week',
  },
});

enum CategoryNames {
  PersonalDevelopment = 'Personal Development',
  Tech = 'Tech',
  Marketing = 'Marketing',
  Product = 'Product',
  HumanResources = 'Human Resources',
  BusinessFundamentals = 'Business Fundamentals',
  DataAndAnalytics = 'Data & Analytics',
  Finance = 'Finance',
  Sales = 'Sales',
  Legal = 'Legal',
  Design = 'Design',
}

export const CustomOrderedCategoryNames: CategoryNames[] = [
  CategoryNames.PersonalDevelopment,
  CategoryNames.Tech,
  CategoryNames.Marketing,
  CategoryNames.Product,
  CategoryNames.HumanResources,
  CategoryNames.BusinessFundamentals,
  CategoryNames.DataAndAnalytics,
  CategoryNames.Finance,
  CategoryNames.Sales,
  CategoryNames.Legal,
  CategoryNames.Design,
];

export const FIRST_PAGE = 1;

export const ENABLE_START_WEBINAR_BUTTON_HOUR_BEFORE = 1;
export const HIDE_START_WEBINAR_BUTTON_HOUR_AFTER = 2;

export const numberOfSessionsMesssage = defineMessage({
  id: 'Classcard.session-chip.number-of-sessions',
  defaultMessage: '{sessionNumber} Sessions',
});
