import { Action, handleActions } from 'redux-actions';

import { Actions } from './Actions';

export interface ReducerState {
  shareId: string;
  isNewSignUp: boolean;
}

const initialState: ReducerState = {
  shareId: null,
  isNewSignUp: false,
};

declare module 'src/global/store/state' {
  interface State {
    virality: ReducerState;
  }
}

type ShareId = string;
type IsNewSignUp = boolean;

type ViralityActionTypes = ShareId | IsNewSignUp;

export default handleActions<ReducerState, ViralityActionTypes>(
  {
    [Actions.SET_SHARE_ID]: (
      state,
      { payload }: Action<ShareId>
    ): ReducerState => ({
      ...state,
      shareId: payload,
    }),
    [Actions.SET_IS_NEW_SIGN_UP]: (
      state,
      { payload }: Action<IsNewSignUp>
    ) => ({
      ...state,
      isNewSignUp: payload,
    }),
  },
  initialState
);
