import { Company } from '../../global/models/Company';
import {
  EBenefits,
  FraudReportFlag,
  JobStatus,
  JobType,
  WorkArrangementOption,
} from '../../global/models/Job';
import { SalaryMode, SalaryType } from '../../global/models/JobSalary';
import { Gender } from '../../global/models/User';
import { Event as RelatedClass } from '../../modules/Events/types';
import { ExpertClassCategory } from '../../modules/ExpertPlatform/ExpertClassApplication/models/ExpertClassApplication';
import { HierarchicalLocation } from '../Profile/graphql/hierarchicalLocations';
import { HierarchyJobRolePreferenceCategory } from '../Profile/graphql/jobCategoriesAndTitles';

export interface Salary {
  salaryType: SalaryType;
  CurrencyCode: string;
  minAmount: number;
  maxAmount: number;
  salaryMode: SalaryMode;
}

export type JobBenefitType = {
  benefit: EBenefits;
  description: string;
  logo: string;
  title: string;
};

export type LocationIdAndLevel = {
  id: string;
  level: number;
};

export type JobLocationBase = {
  administrativeLevelName: string;
  formattedName: string;
  id: string;
  level: number;
  name: number;
};

export type JobRecommendationJobLocationBase = {
  administrativeLevelDefaultName: string;
  administrativeLevelPrefix: string;
  administrativeLevelPrefixTranslationKey: string;
  administrativeLevelTranslationKey: string;
  defaultName: string;
  formattedName: string;
  id: string;
  latitude: number;
  level: number;
  longitude: number;
};

export type JobLocation = JobLocationBase & { parents: Array<JobLocationBase> };
export type JobLocationRecommended = JobRecommendationJobLocationBase & {
  parents: Array<JobRecommendationJobLocationBase>;
};

export enum UserCannotApplyReason {
  HAS_APPLIED = 'HAS_APPLIED',
  HAS_CONNECTION = 'HAS_CONNECTION',
  HAS_RECOMMENDATION = 'HAS_RECOMMENDATION',
  HAS_BEEN_CONTACTED_BY_RECRUITER = 'HAS_BEEN_CONTACTED_BY_RECRUITER',
  NO_FOREIGN_APPLICANTS = 'NO_FOREIGN_APPLICANTS',
}

export type CanUserApplyWithReason = {
  canApply: boolean;
  reason: UserCannotApplyReason;
};

export enum JobDescriptionType {
  DESCRIPTION = 'DESCRIPTION',
  MUST_HAVE_REQUIREMENT = 'MUST_HAVE_REQUIREMENT',
}

export enum EducationLevel {
  PRIMARY_SCHOOL = 'PRIMARY_SCHOOL',
  SECONDARY_SCHOOL = 'SECONDARY_SCHOOL',
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  DIPLOMA = 'DIPLOMA',
  COLLEGE_DEGREE = 'COLLEGE_DEGREE',
  BACHELOR_DEGREE = 'BACHELOR_DEGREE',
  MASTER_DEGREE = 'MASTER_DEGREE',
  DOCTORATE = 'DOCTORATE',
}

export enum ResumeRequiredStatus {
  OPTIONAL = 'OPTIONAL',
  HIDDEN = 'HIDDEN',
}

export type Creator = {
  id: string;
  name?: string;
  image?: string;
  online?: boolean;
  lastSeen?: string;
  isHighResponseRate?: boolean;
};

export interface JobInterface {
  id: string;
  title: string;
  status: JobStatus;
  workArrangementOption: WorkArrangementOption;
  maxYearsOfExperience: number;
  minYearsOfExperience: number;
  createdAt: string;
  updatedAt: string;
  fraudReportFlag?: FraudReportFlag | null;
  attachmentsJsonString?: string;
  type: JobType;
  canUserApplyWithReason?: CanUserApplyWithReason;
  country: { name: string; code: string };
  city: { name: string; id: number };
  citySubDivision?: { name: string; id: number };
  company: Company;
  salaries?: Salary[];
  hasBookmarked: boolean;
  salaryEstimate?: {
    minAmount: number;
    maxAmount: number;
    CurrencyCode: string;
  };
  isResumeUploadRequired?: boolean;
  hierarchicalJobCategory?: HierarchyJobRolePreferenceCategory;
  acceptsForeignApplications: boolean;
  CountryCode: string;
  descriptionJsonString?: string;
  interviewProcessJsonString?: string;
  mustHaveRequirementJsonString?: string;
  expiryDate?: string;
  CityId: number;
  shouldShowBenefits?: boolean;
  shouldShowSalary?: boolean;
  resumeRequiredStatus?: ResumeRequiredStatus;
  benefits?: JobBenefitType[];
  isActivelyHiring?: boolean;
  isHot: boolean;
  isVIP?: boolean;
  isApplied?: boolean;
  skills?: {
    skill: {
      id: string;
      name: string;
    };
  }[];
  externalApplyURL?: string;
  location?: JobLocation | JobLocationRecommended | HierarchicalLocation;
  educationLevel?: EducationLevel;
  creator?: Creator;
  gender?: Gender;
  minAge?: number;
  maxAge?: number;
  traceInfo?: string;
  creatorResponseTime?: number;
  isEducationRequiredForCandidate: boolean;
  isLocationRequiredForCandidate: boolean;
  expInfo?: string;
}

export type JobDraft = {
  id: string;
  title: string;
  workArrangementOption: WorkArrangementOption;
  maxYearsOfExperience: number;
  minYearsOfExperience: number;
  createdAt: string;
  updatedAt: string;
  type: JobType;
  country: { name: string; code: string };
  city: { name: string; id: number };
  company: Company;
  salaries: Salary[];
  acceptsForeignApplications: boolean;
  descriptionJsonString: string;
  shouldShowBenefits?: boolean;
  shouldShowSalary?: boolean;
  benefits?: JobBenefitType[];
  skills: {
    skill: {
      id: string;
      name: string;
    };
  }[];
};

export interface JobRelatedExpertClasses {
  expertClassCategories: ExpertClassCategory[];
  expertClasses: RelatedClass[];
  totalCount: number;
}

export enum JobRecommendationType {
  SIMILAR = 'SIMILAR', // Jobs based on category etc.
  RELEVANT = 'RELEVANT', // Jobs from same company
}

export enum OpportunitySkillMatchVariant {
  HIDDEN = 'HIDDEN',
  VISIBLE = 'VISIBLE',
}
