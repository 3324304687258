import { countryCodeAndNameMap, CountryCodes } from 'src/common/enums';
import { transformBasename } from 'src/common/utils/transformBasename';
import { State } from 'src/global/store';
import { getUser } from 'src/selectors/user';

export const getConfig = (state: State) => state.clientConfig;

export const getAWSBase = (state: State) => getConfig(state)?.AWS_BASE;

export const getCountry = (state: State): CountryCodes =>
  getConfig(state)?.COUNTRY;

export const getCountryWithSGFallback = (state: State): CountryCodes =>
  getConfig(state)?.COUNTRY || CountryCodes.SG;

export const getBasename = (state: State) => {
  const basename = getConfig(state)?.BASENAME;
  return transformBasename(basename);
};

export const getUserCountryCodeWithSGFallback = (
  state: State
): CountryCodes => {
  const country = getUser(state)?.CountryCode as CountryCodes;

  if (!(country in countryCodeAndNameMap)) {
    return getCountryWithSGFallback(state);
  }

  return country;
};

export const getEmployersBaseUrl = (state: State) =>
  getConfig(state)?.EMPLOYERS_BASE;

export const getFacebookAppId = (state: State) =>
  getConfig(state)?.FACEBOOK_APPID;

export const getIsNotificationEnabled = (state: State) =>
  getConfig(state)?.ENABLE_NOTIFICATION;

export const getMobileAppScheme = (state: State) =>
  getConfig(state)?.MOBILE.candidate.scheme;

export const getMobileAppId = (state: State) =>
  getConfig(state)?.MOBILE.candidate.id;

export const getMobileAppUserAgent = (state: State) =>
  getConfig(state)?.MOBILE?.candidate.userAgent;

export const getZendeskKey = (state: State) =>
  getConfig(state)?.ZENDESK_WIDGET_KEY;
