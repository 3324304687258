import { Loading as AriesLoading, SecondaryColor } from 'glints-aries';
import { Icon as AriesIcon } from 'glints-aries/lib/@next';
import styled from 'styled-components';

export const Icon = styled(AriesIcon)`
  width: 20px;
  height: 20px;
  fill: ${SecondaryColor.actionblue};
`;

export const Loading = styled(AriesLoading)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  gap: 8px;
  color: ${SecondaryColor.actionblue};

  > span {
    font-weight: bold;
  }
`;
