import { InfoIcon, Modal as LegacyModal } from 'glints-aries';
import { Banner, Modal, TextArea } from 'glints-aries/lib/@next';
import { Red } from 'glints-aries/lib/@next/utilities/colors';
import styled, { css } from 'styled-components';

import { semanticModalResponsiveStyle } from 'src/styles/components/semanticModal';

export const Header = styled.div<{ isMobile: boolean }>`
  color: black;
  font-size: ${({ isMobile }) => (isMobile ? '16px' : '20px')};
  font-family: Noto Sans;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.15px;
  word-wrap: break-word;
`;

export const Content = styled.div`
  padding: 21px;
`;

export const ReportBanner = styled(Banner).attrs({
  title: null,
  dismissable: false,
  showIcon: false,
  type: 'static',
  status: 'info',
})`
  svg {
    display: none;
  }
`;

export const CustomLegacyModal = styled(LegacyModal)`
  .modal-content {
    display: flex;
    justify-content: center;
  }
`;
export const CustomModal = styled(Modal)<{ isMobile: boolean }>`
  width: 60% !important;
  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 92% !important;
      position: relative !important;
      border-radius: 8px !important;
    `}
  .modal-wrapper {
    background-color: red;
  }
  .modal-dialog {
    ${semanticModalResponsiveStyle}
  }
  .modal-content {
    padding: 0px;
    width: 100%;
    border-radius: 8px;
  }
  .modal-body {
    padding: 0px;
  }
  .modal-footer {
    button:disabled {
      color: #acacad !important;
      background: #eef0f3 !important;
      border-color: #eef0f3 !important;
      pointer-events: none !important;
    }
  }
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
  margin-top: 7px;
  margin-bottom: 7px;
`;

export const ReportReasonListItemContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
`;

export const BannerContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  background: #eaf9ff;
  border-radius: 4px;
  border: 0.5px #017eb7 solid;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  margin-bottom: 20px;
`;

export const Require = styled.span`
  color: ${Red.B93};
  &:after {
    content: '*';
  }
`;

export const SelectionDetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  margin-bottom: 20px;
`;

export const SelectionDetailsTitle = styled.div<{ isMobile: boolean }>`
  color: #2d2d2d;
  font-size: ${({ isMobile }) => (isMobile ? '14px' : '16px')};
  font-family: Noto Sans;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
  word-wrap: break-word;
`;

export const SelectionDetailsDescription = styled.div<{ isMobile: boolean }>`
  color: #666666;
  font-size: ${({ isMobile }) => (isMobile ? '12px' : '14px')};
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.25px;
  word-wrap: break-word;
`;

export const ReportTextArea = styled(TextArea)`
  div {
    border: 0.5px #acacad solid;
    color: #acacad;
    font-size: 16px;
  }
  textarea {
    align-self: stretch;
    height: 44px;
    color: #acacad !important;
    font-size: 16px;
    font-family: Noto Sans;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
  }
`;

export const Icon = styled.img.attrs({
  src: '/images/icons/ellipse-check.svg',
})`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
`;

export const SuccessDetailsContainer = styled.div<{ isMobile?: boolean }>`
  ${({ isMobile }) =>
    !isMobile &&
    css`
      width: 35%;
    `}
  margin-top: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 60px;
  align-items: center;
`;

export const MobileReasonDescriptionContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  div {
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    display: flex;
  }
`;

export const ReportInfoIcon = styled(InfoIcon)`
  margin-left: 4px;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
