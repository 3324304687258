import { createContext, useContext } from 'react';

interface MainContainerContext {
  setAlternativeHeader: React.Dispatch<
    React.SetStateAction<React.ForwardRefExoticComponent<unknown>>
  >;
}

export const MainContainerContext = createContext<
  MainContainerContext | undefined
>(undefined);

export function useMainContainerState() {
  const context = useContext(MainContainerContext);
  if (context === undefined) {
    throw new Error('useMainContainerState must be used within MainContainer');
  }
  return context;
}

export const MainHeaderHeightContext = createContext(0);

interface MainHeaderStickyContext {
  isStickyHeader: boolean;
  setIsStickyHeader: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MainHeaderStickyContext = createContext<
  MainHeaderStickyContext | undefined
>(undefined);

class UndefinedStickyContextError extends Error {
  static message = 'useMainHeaderStickyState must be used within MainContainer';
  constructor(message?: string) {
    super(message || UndefinedStickyContextError.message);
  }
}

export function useMainHeaderStickyState() {
  const context = useContext(MainHeaderStickyContext);
  if (!context) {
    throw new UndefinedStickyContextError();
  }
  return context;
}
