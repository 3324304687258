import { ScreenSize } from 'glints-aries';
import { css } from 'styled-components';

// To keep the original responsive styles of semantic-modal components that are replaced by glints-aries
export const semanticModalResponsiveStyle = css`
  width: 95%;
  @media (min-width: ${ScreenSize.tablet}px) {
    width: 70.4%;
  }
  @media (min-width: 992px) {
    width: 680px;
  }
  @media (min-width: 1200px) {
    width: 720px;
  }
  @media (min-width: 1920px) {
    width: 760px;
  }
`;
