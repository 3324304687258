import { createAction } from 'redux-actions';
import { IClientConfigVars } from 'shared/interfaces/ClientConfig';

export const Actions = {
  SET_CLIENT_CONFIG: 'glints/clientConfig/SET_CLIENT_CONFIG',
};

export const setClientConfig = createAction(
  Actions.SET_CLIENT_CONFIG,
  (config: IClientConfigVars) => ({ ...config })
);
