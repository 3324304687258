import React from 'react';
import { ScreenSize } from 'glints-aries';
import Head from 'next/head';
import { FormattedMessage } from 'react-intl';

import * as Styled from './ErrorStyles.sc';
import { TakeMeHomeButton } from './TakeMeHomeButton';

const Error404Page = () => (
  <>
    <Head>
      <title>Page Not Found</title>
    </Head>
    <Styled.Container>
      <Styled.Image
        name="error/404.png"
        sizes={`(min-width: ${ScreenSize.tablet}px) 597px, 100vw`}
        alt="404 Not Found"
      />
      <FormattedMessage
        id="error.404_page.title"
        defaultMessage="Page Not Found"
        tagName={Styled.Title}
      />
      <FormattedMessage
        id="error.404_page.description"
        defaultMessage="Whoops, you may have wandered too far!{br}(The page you have requested was not found or might have been moved.)"
        tagName={Styled.Description}
        values={{ br: <br /> }}
      />
      <TakeMeHomeButton />
    </Styled.Container>
  </>
);

export default Error404Page;
