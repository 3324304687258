import { handleActions } from 'redux-actions';

import { Actions } from './actions';

export interface ReducerState {
  isMessagingVisible: boolean;
}

const initialState: ReducerState = {
  isMessagingVisible: true,
};

export default handleActions(
  {
    [Actions.SHOW_WHATSAPP_MESSAGING]: (state) => ({
      ...state,
      isMessagingVisible: true,
    }),
    [Actions.HIDE_WHATSAPP_MESSAGING]: (state) => ({
      ...state,
      isMessagingVisible: false,
    }),
  },
  initialState
);
