import { get } from 'lodash-es';
import { Action } from 'redux-actions';

import { getLoginEventUserProperties } from 'src/common/utils/googleTagManagerHelpers';
import { ReduxThunkAction } from 'src/global/store';
import { ProfileFields } from 'src/modules/Profile/GTMActions';
import { getExperimentFeaturesValueObject } from 'src/modules/Unleash/Selectors';
import { getAmplitudeUserProperties, getUser } from 'src/selectors/user';

// actions here do not have corresponding reducer,
// we use the GoogleTagManager middleware to push data to gtm dataLayer
// and it will trigger its corresponding GTM tag

const SET_USER = 'glints/GoogleTagManager/SET_USER';
const SET_EXPERIMENTATION_INFO =
  'glints/GoogleTagManager/SET_EXPERIMENTATION_INFO';

type EventUserProperties = {
  days_from_signup: number;
  has_resume: boolean;
  has_mobile_number: boolean;
  has_whatsapp_number: boolean;
  age_in_years: number;
  gender: string;
  number_of_skills_listed: number;
};

export function GTMSetUser(otherProperties = {}): ReduxThunkAction<any> {
  return (dispatch, getState) => {
    const state = getState();
    const userProperties = getAmplitudeUserProperties(state);
    const experimentFeatures = getExperimentFeaturesValueObject(state);

    return dispatch({
      type: SET_USER,
      payload: {
        ...userProperties,
        ...experimentFeatures,
        ...otherProperties,
      },
    });
  };
}

export function GTMSetUserWithCallbackAction(
  eventUserProperties: Partial<EventUserProperties> = {},
  callbackAction?: Action<{
    field: ProfileFields;
    actionType: string;
  }>
): ReduxThunkAction<any> {
  return async (dispatch, getState, { api }) => {
    const state = getState();
    const userProperties = getAmplitudeUserProperties(state);
    const experimentFeatures = getExperimentFeaturesValueObject(state);
    try {
      const skillsResponse = await api(state.session.token).get('/me/skills');
      const skills = get(skillsResponse, 'data.data[0].links.skills', []);

      const defaultEventUserProperties = getLoginEventUserProperties(
        getUser(state)
      );

      dispatch({
        type: SET_USER,
        payload: {
          ...userProperties,
          ...experimentFeatures,
          ...defaultEventUserProperties,
          ...eventUserProperties,
          number_of_skills_listed: skills.length,
        },
      });

      if (callbackAction) {
        dispatch(callbackAction);
      }
    } catch (error) {
      throw error;
    }
  };
}

export function GTMSetExperimentationInfo(
  experimentationInfo = ''
): ReduxThunkAction<any> {
  return (dispatch) => {
    dispatch({
      type: SET_EXPERIMENTATION_INFO,
      payload: Object.fromEntries(
        experimentationInfo.split(',').map((item: string) => {
          const [key, value] = item.split(':');
          return key && value ? [key, value] : [];
        })
      ),
    });
  };
}
