import { useRouter } from 'next/router';

import { Products } from '../enums';

export const routerPathToProductGtmMap: { [name: string]: Products } = {
  'expert-class': Products.ExpertClass,
};

export const useProductGtmPayload = () => {
  const router = useRouter();
  const { product } = router.query;
  const productGtmPayload =
    routerPathToProductGtmMap[product as string] || Products.Marketplace;

  return { productGtmPayload };
};
