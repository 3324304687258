import { v4 as uuidv4 } from 'uuid';

import { THIRTY_DAYS_IN_MS } from 'src/common/constants';

export const generateCookiesValueId = (
  keyName: string,
  validTime?: number
): string => {
  const expirationTime = validTime ?? THIRTY_DAYS_IN_MS;

  const randomId = uuidv4();
  const expires = new Date();
  expires.setTime(expires.getTime() + expirationTime);

  return `${keyName}=${randomId}; Path=/; Expires=${expires.toUTCString()}; SameSite=Lax`;
};
