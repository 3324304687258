import React, { useCallback } from 'react';
import { Dropdown, GlobeIcon, Greyscale } from 'glints-aries';
import { useSelector } from 'react-redux';

import { languages as supportedLanguages } from '../../common/constants';
import { getConfig } from '../../modules/ClientConfig';
import { getLocale, setLocale } from '../../selectors/locale';
import {
  LanguageSwitcherContainer,
  LanguageSwitcherContainerProps,
} from './LanguageSwitcher.sc';

const LanguageSwitcher: React.FC<React.PropsWithChildren<Props>> = ({
  fontSize = 12,
  iconDefaultColor,
  fontWeight = 500,
  textColor = Greyscale.black,
  showHoverLine,
  showFullWidth,
  languages,
}) => {
  const locale = useSelector(getLocale);
  const config = useSelector(getConfig);

  const currentLanguage = locale.language;
  const country = config.COUNTRY;
  const basename = config.BASENAME;

  const configuredLanguages = supportedLanguages.filter(
    (lang) => languages.indexOf(lang.value) > -1
  );

  const switchLanguage = useCallback(
    (value: any) => {
      setLocale(country, value);

      if (!basename || basename === '/') {
        location.reload();
      } else {
        location.pathname = location.pathname.replace(
          basename,
          `/${country.toLowerCase()}/${value}`
        );
      }
    },
    [basename, country]
  );

  return (
    <LanguageSwitcherContainer
      fontSize={fontSize}
      textColor={textColor}
      fontWeight={fontWeight}
    >
      <Dropdown
        label={currentLanguage}
        onChange={switchLanguage}
        leftIcon={<GlobeIcon />}
        iconDefaultColor={iconDefaultColor}
        fontWeight={fontWeight}
        showHoverLine={showHoverLine}
        showFullWidth={showFullWidth}
      >
        {configuredLanguages.map((option) => (
          <Dropdown.Item key={option.value} value={option.value}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </LanguageSwitcherContainer>
  );
};

type Props = {
  languages: string[];
} & LanguageSwitcherContainerProps &
  Pick<
    React.ComponentPropsWithoutRef<typeof Dropdown>,
    'iconDefaultColor' | 'showFullWidth' | 'showHoverLine'
  >;

export default LanguageSwitcher;
