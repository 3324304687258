import React, { useState } from 'react';
import { Orange } from 'glints-aries/lib/@next/utilities/colors';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { uploadImageToS3 } from 'src/actions/uploadImageToS3';
import pushGTMEvent from 'src/common/utils/google-tag-manager';

import {
  ACCEPTED_FILE_TYPES,
  FILE_LABEL,
  MAX_FILE_SIZE_IN_MB,
  MAX_NUMBER_OF_FILES_ALLOWED,
} from './constants';
import { uploadErrorMessages } from './messages';
import { FileData } from './ReportModalV2';

type DragAndDropUploadReportAttachmentProps = {
  reportAttachments: FileData[];
  setReportAttachments: React.Dispatch<React.SetStateAction<FileData[]>>;
  setError: (error: string) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const DragAndDropContainer = styled.div<{ isDragging: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: white;

  ${({ isDragging }) =>
    isDragging &&
    `
      background-color: ${Orange.S21};
      color: #de8e68;
    `}
`;

const DropzoneText = styled.p`
  margin: 0;
`;

export const DragAndDropUploadReportAttachment = ({
  reportAttachments,
  setReportAttachments,
  setError,
  setLoading,
}: DragAndDropUploadReportAttachmentProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [isDragging, setIsDragging] = useState(false);

  const handleUpload = (file: File) => {
    setError('');
    pushGTMEvent('glints/GoogleTagManager/REPORT_JOB_FILE_UPLOAD_INITIATED');

    const fileSizeInMB = file.size / 1024 / 1024;
    const fileExtension = file.name.split('.').pop();

    if (!ACCEPTED_FILE_TYPES.includes(fileExtension)) {
      setError(formatMessage(uploadErrorMessages.fileTypeError));
      return;
    }

    if (fileSizeInMB > MAX_FILE_SIZE_IN_MB) {
      setError(formatMessage(uploadErrorMessages.fileTooLarge));
      return;
    }

    setLoading(true);

    const onSuccess = ({ filename }: { filename: string }) => {
      pushGTMEvent('glints/GoogleTagManager/REPORT_JOB_FILE_UPLOAD_SUCCESS');
      setReportAttachments((reportAttachments) => {
        const maxTotalUploadInputReached =
          reportAttachments.length === MAX_NUMBER_OF_FILES_ALLOWED;
        const newReportAttachments = maxTotalUploadInputReached
          ? [...reportAttachments]
          : [...reportAttachments, null];
        newReportAttachments[reportAttachments.length - 1] = {
          file,
          uploadedFileName: filename,
        };
        return newReportAttachments;
      });

      setLoading(false);
    };

    const onError = (error: Error, _: string) => {
      pushGTMEvent('glints/GoogleTagManager/REPORT_JOB_FILE_UPLOAD_FAILED', {
        payload: {
          error,
        },
      });
      setReportAttachments((reportAttachments) => {
        const newReportAttachments = [...reportAttachments];
        newReportAttachments[reportAttachments.length - 1] = null;
        return newReportAttachments;
      });
      console.error(error);
      setLoading(false);
      setError(formatMessage(uploadErrorMessages.genericError));
    };

    dispatch(uploadImageToS3(file, FILE_LABEL, onSuccess, onError));
  };

  const canUpload =
    reportAttachments.filter((reportAttachment) => reportAttachment !== null)
      .length < MAX_NUMBER_OF_FILES_ALLOWED;

  return (
    <DragAndDropContainer
      isDragging={canUpload && isDragging}
      onDragEnter={() => canUpload && setIsDragging(true)}
      onDragOver={(e) => {
        e.preventDefault();
        if (canUpload) setIsDragging(true);
      }}
      onDragLeave={() => canUpload && setIsDragging(false)}
      onDrop={(e) => {
        e.preventDefault();
        if (canUpload) {
          setIsDragging(false);
          handleUpload(e.dataTransfer.files[0]);
        }
      }}
    >
      <DropzoneText>
        <FormattedMessage
          id="report-modal-v2.drag-and-drop-upload-report-attachment"
          defaultMessage="Drag and drop file here or click to select file"
        />
      </DropzoneText>
    </DragAndDropContainer>
  );
};
