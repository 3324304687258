import React from 'react';

import { DEFAULT_COMPANY_LOGO } from '../../common/constants';
import GlintsPicture from './GlintsPicture';
import { GlintsPictureProps } from './interfaces';

interface Props extends Omit<GlintsPictureProps, 'name'> {
  logo?: string;
}

const CompanyLogo: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  logo,
  sizes = '100px',
  breakpoints,
  ...otherProps
}) => (
  // @ts-ignore
  <GlintsPicture
    className={className}
    name={logo ? logo : DEFAULT_COMPANY_LOGO}
    assetType="company-logo"
    fallbackAssetType="company-logo"
    fallback={DEFAULT_COMPANY_LOGO}
    sizes={sizes}
    breakpoints={breakpoints}
    {...otherProps}
  />
);

export default CompanyLogo;
