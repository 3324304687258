import React from 'react';
import { Typography } from 'glints-aries/lib/@next';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export default function FacebookEmailErrorMessage() {
  return (
    <NotificationContainer>
      <Typography variant="subtitle1">
        <FormattedMessage
          id="text-fb-email-access-error"
          defaultMessage="Login failed. You didn’t allow Glints to access your Facebook email."
          tagName="span"
        />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage
          id="text-fb-email-access-error-desc"
          defaultMessage="To fix this, go to this <Link>link</Link> and remove the Glints app, then try logging in again with email access enabled."
          values={{
            Link: (chunks: any) => (
              <a
                href="https://www.facebook.com/settings?tab=applications"
                target="_blank"
                rel="noreferrer"
              >
                {chunks}
              </a>
            ),
          }}
          tagName={Typography}
        />
      </Typography>
    </NotificationContainer>
  );
}
