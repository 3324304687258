import {
  countryCodeAndNameMap,
  CountryCodes,
  defaultCityProvinceAll,
  defaultCityProvinceInVN,
  defaultLocationIdInVN,
} from 'src/common/enums';
import { SearchRouterQueryKey } from 'src/modules/Opportunities/pages/Explore/types';

const opportunitiesJobs = 'opportunities/jobs';
const dashboardJobs = 'dashboard/jobs';

export const ROUTES = {
  companies: 'companies',
  company: 'companies/id',
  confirmOrder: 'user/orders/confirm',
  dashboard: 'dashboard',
  dashboardJobs: `${dashboardJobs}`,
  expertClass: 'expert-class',
  expertClassDetail: 'expert-class/title/shortId',
  expertClassOrders: 'user/orders',
  expertClassSearch: 'expert-class/search',
  expertApplication: 'expert-class/expert-application',
  expertSwitchToDesktop: 'expert-class/expert/switch-to-desktop',
  expertApplicationSwitchToDesktop: 'expert-class/expert/switch-to-desktop',
  expertClassApplication: 'expert-class/expert/class-application/shortId',
  expert: 'expert-class/expert',
  expertLogin: 'expert-class/expert/login',
  forgotPassword: 'forgot-password',
  komunitas: 'komunitas',
  liveRoom: 'liveroom',
  login: 'login',
  signup: 'signup',
  onboardingGuided: 'onboarding-guided',
  opportunitiesJob: `${opportunitiesJobs}/id`,
  opportunitiesJobs: `${opportunitiesJobs}`,
  opportunitiesJobsExplore: `${opportunitiesJobs}/explore`,
  opportunitiesJobsRecommended: `${opportunitiesJobs}/recommended`,
  opportunitiesJobsBookmarked: `${opportunitiesJobs}/bookmarked`,
  profile: 'profile',
  settings: 'settings',
  testimonials: 'testimonials',
  applications: 'user/applications',
  terms: 'about/terms',
  userAgreement: 'about/terms',
  privacyPolicy: 'about/privacy-policy',
  standardTermsForServices: 'about/standard-terms-for-services',
  download: 'download',
};

export const JOB_EXPLORE_URL = '/jobs';
export const ID_JOB_EXPLORE_URL = '/lowongan-kerja';
export const VN_JOB_EXPLORE_URL = '/viec-lam';

export const EXPERT_CLASS_PATH_REGEXPS = [
  new RegExp(`^/${ROUTES.expertClass}`),
  new RegExp(`^/${ROUTES.expertClassOrders}`),
  new RegExp(`^/${ROUTES.expertClassSearch}`),
  new RegExp(`^/${ROUTES.confirmOrder}`),
];

export const safetyURL = {
  [CountryCodes.ID]:
    'https://glints.com/id/lowongan/panduan-cari-kerja-online/?utm_source=cdd_job_detail_page&utm_medium=referral&utm_campaign=glints_safety_tips',
  [CountryCodes.VN]:
    'https://glints.com/vn/blog/glints-tuyen-dung-safety-tips/',

  // TODO: Add english version as default.
  // Right now for english and other non translated locales this won't be visible as removed the link from copy
  // But Once we get copy we need to update this default url and the default translation to add link back
  default:
    'https://glints.com/id/lowongan/panduan-cari-kerja-online/?utm_source=cdd_job_detail_page&utm_medium=referral&utm_campaign=glints_safety_tips',
} as const;

export const getOpportunitiesJobsExploreDefaultLocationQueryString = (
  country: keyof typeof countryCodeAndNameMap
) => {
  const isCountryCodeVN = country === CountryCodes.VN;
  return {
    [SearchRouterQueryKey.Country]: country,
    [SearchRouterQueryKey.Location]: isCountryCodeVN
      ? defaultCityProvinceInVN
      : defaultCityProvinceAll,
    ...(isCountryCodeVN && {
      [SearchRouterQueryKey.LocationId]: defaultLocationIdInVN,
    }),
  };
};
