import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Flex, ScreenSize } from 'glints-aries';
import {
  Button,
  Icon,
  InlineError,
  PrimaryButton,
  Spacing,
  Typography,
} from 'glints-aries/lib/@next';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
} from 'glints-aries/lib/@next/utilities/spacing';
import { SpacingScaleValues } from 'glints-aries/lib/Layout/Box/types';
import { camelCase, identity, lowerCase } from 'lodash-es';
import { useRouter } from 'next/router';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { closeReportModal } from 'src/actions/app';
import { useMediaQuery } from 'src/common/hooks/useMediaQuery';
import { ROUTES } from 'src/common/routes';
import { State } from 'src/global/store';
import { trackReportJob as trackReportJobAction } from 'src/modules/MainContainer/GTMActions';
import {
  ReportJobInput,
  reportJobMutation,
} from 'src/modules/Opportunity/graphql';

import pushGTMEvent from '../../common/utils/google-tag-manager';
import { IMAGE_TYPE_COPY } from './constants';
import { DragAndDropUploadReportAttachment } from './DragAndDropUploadReportAttachment';
import {
  atLeast20Characters,
  EReportJobReason,
  placeholderMessage,
  radioButtons,
} from './messages';
import * as Styles from './ReportModalV2.sc';
import { ReportReasonListItem } from './ReportReasonListItem';
import { UploadReportAttachment } from './UploadReportAttachment';

export type FileData = {
  file: File;
  uploadedFileName: string;
};

type ReportModalV2Props = {
  reportModalId: string;
  reportCompanyId: string;
  reportModalOpen: boolean;
  openedFrom: 'fyp' | 'explore';
  reportListing: (id: string, value: string) => void;
  closeReportModal: () => void;
  trackReportJob: (item: any) => void;
};

const ReportModalV2 = (props: ReportModalV2Props) => {
  const {
    reportModalId,
    reportCompanyId,
    closeReportModal,
    reportModalOpen,
    trackReportJob,
    openedFrom,
  } = props;

  const { formatMessage } = useIntl();
  const router = useRouter();
  const isMobile = useMediaQuery(`(max-width: ${ScreenSize.mobileL}px)`);

  const [wizardStep, setWizardStep] = useState(1);
  const [mobInfoModalOpen, setMobInfoModalOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState(radioButtons[0]);
  const [reportJustification, setReportJustification] = useState('');
  const [reportAttachments, setReportAttachments] = useState<FileData[]>([
    null,
  ]);
  const [uploadError, setUploadError] = useState<string>('');
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);

  const [reportJustificationError, setReportJustificationError] =
    useState(false);

  const [reportJob, { loading, error }] = useMutation<void, ReportJobInput>(
    reportJobMutation
  );

  const reasonIsOthers = selectedReason.value === EReportJobReason.OTHERS;
  const isSuccessStep = wizardStep === 3;
  const isSubmitStep = wizardStep === 2;
  const isReportJustificationRequired = [
    EReportJobReason.SCAM_OR_FRAUD_JOB,
    EReportJobReason.INVOLVES_FEE_COLLECTION,
    EReportJobReason.OTHERS,
  ].includes(selectedReason.value);
  const isReportJustificationRequireMin20Chars = [
    EReportJobReason.SCAM_OR_FRAUD_JOB,
    EReportJobReason.OTHERS,
  ].includes(selectedReason.value);
  const isAttachmentRequired = [
    EReportJobReason.SCAM_OR_FRAUD_JOB,
    EReportJobReason.INVOLVES_FEE_COLLECTION,
  ].includes(selectedReason.value);

  useEffect(
    function clearDescriptionOnReasonChange() {
      if (selectedReason.value) {
        setReportJustification('');
        setReportJustificationError(false);
        setReportAttachments([null]);
        setUploadError('');
      }
    },
    [selectedReason.value]
  );

  useEffect(
    function resetModalStateOnOpen() {
      if (reportModalOpen) {
        resetAllState();
        pushGTMEvent('glints/GoogleTagManager/REPORT_JOB_FORM_VIEWED', {
          payload: {
            source: 'job details page',
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportModalOpen]
  );

  const resetAllState = () => {
    setSelectedReason(radioButtons[0]);
    setReportJustification('');
    setReportJustificationError(false);
    setWizardStep(1);
    setMobInfoModalOpen(false);
  };

  const handleClose = () => {
    resetAllState();
    closeReportModal();
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const selectRsn = radioButtons.find((r) => r.value === value);
    setSelectedReason(selectRsn);
  };

  const handleReport = async () => {
    try {
      const attachments = reportAttachments
        .filter(identity)
        .map((file) => file.uploadedFileName);

      const data = {
        jobId: reportModalId,
        reason: selectedReason.value,
        reportDetails: reportJustification,
        ...(attachments.length > 0 && { attachments }),
      };

      await reportJob({
        variables: {
          data,
        },
      });
      const sendTrackingEvent = () => {
        const trackingReportReason = reasonIsOthers
          ? `${lowerCase(
              camelCase(selectedReason.value)
            )}: ${reportJustification}`
          : lowerCase(camelCase(selectedReason.value));

        trackReportJob({
          jobId: reportModalId,
          companyId: reportCompanyId,
          reportReason: trackingReportReason,
          success: !error,
        });
        if (!error) {
          setWizardStep(3);
        }
      };
      if (!loading) {
        sendTrackingEvent();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isJustificationInvalid = (changeValue: string) =>
    isReportJustificationRequired &&
    (!changeValue.length ||
      changeValue.length > 500 ||
      (isReportJustificationRequireMin20Chars && changeValue.length < 20));

  const isUploadInvalid = () =>
    reportAttachments.filter(identity).length < 1 && isAttachmentRequired;

  const isFormInvalid =
    isJustificationInvalid(reportJustification) || isUploadInvalid();

  const handleReportJustificationChange = (changeValue: string) => {
    setReportJustificationError(isJustificationInvalid(changeValue));
    setReportJustification(changeValue);
  };

  const customActions = (
    <Styles.ActionContainer>
      <If condition={wizardStep !== 1}>
        <Button
          onClick={() => {
            setWizardStep(wizardStep - 1);
          }}
        >
          <FormattedMessage
            id="confirm-order-page.back-button"
            defaultMessage="Back"
          />
        </Button>
      </If>
      <PrimaryButton
        key={Math.random()}
        disabled={isSubmitStep && isFormInvalid}
        loading={loading}
        onClick={async () => {
          if (isSubmitStep) {
            await handleReport();
          } else {
            setWizardStep(wizardStep + 1);
          }
        }}
      >
        <Choose>
          <When condition={isSubmitStep}>
            <FormattedMessage id="submit.password" defaultMessage="Submit" />
          </When>
          <Otherwise>
            <FormattedMessage
              id="chat-intro_dialog_button_next"
              defaultMessage="Next"
            />
          </Otherwise>
        </Choose>
      </PrimaryButton>
    </Styles.ActionContainer>
  );

  return (
    <Choose>
      <When condition={isSuccessStep}>
        <Styles.CustomLegacyModal
          isVisible={reportModalOpen}
          fullscreen={true}
          hideHeader={true}
        >
          <Styles.Content>
            <Flex
              mt={Spacing.space40 as SpacingScaleValues}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Styles.Icon width="200" height="200" />
              <Styles.SuccessDetailsContainer isMobile={isMobile}>
                <Typography variant="subtitle1" color="#2D2D2D">
                  <FormattedMessage
                    id="title-text-received-report"
                    defaultMessage="Thank you for your report"
                  />
                </Typography>
                <Typography variant="body1" color="#666666">
                  <FormattedMessage
                    id="body-text-received-report"
                    defaultMessage=" We will investigate this job further. You will see fewer jobs like this in the future."
                  />
                </Typography>
                <div>
                  <PrimaryButton
                    fullWidth={false}
                    type="button"
                    onClick={() => {
                      if (openedFrom === 'fyp') {
                        router.push(`/${ROUTES.opportunitiesJobsRecommended}`);
                      } else {
                        router.push(`/${ROUTES.opportunitiesJobsExplore}`);
                      }
                      handleClose();
                    }}
                  >
                    <FormattedMessage
                      id="explore.more.jobs"
                      defaultMessage="Explore more jobs"
                    />
                  </PrimaryButton>
                </div>
              </Styles.SuccessDetailsContainer>
            </Flex>
          </Styles.Content>
        </Styles.CustomLegacyModal>
      </When>
      <Otherwise>
        <Styles.CustomModal
          isMobile={isMobile}
          isOpen={reportModalOpen}
          onClose={handleClose}
          closeOnClickOutside={true}
          customActions={customActions}
          header={
            <Styles.Header isMobile={isMobile}>
              <FormattedMessage
                id="job_detail_page.job.report"
                defaultMessage="Report This Job"
              />
            </Styles.Header>
          }
        >
          <Styles.Content>
            <Styles.BannerContainer>
              <Icon
                name="ri-shield-check-line"
                fill={Blue.S99}
                height={24}
                width={24}
                style={{ flexShrink: 0 }}
              />
              <FormattedMessage
                id="text-provid-as-much-as-details"
                defaultMessage="Our team will investigate your report and take actions if necessary. Please provide as much detail as possible so that your report can be properly reviewed. Worry not, your report remains anonymous."
              >
                {(text) => <Typography variant="body1">{text}</Typography>}
              </FormattedMessage>
            </Styles.BannerContainer>
            <Choose>
              <When condition={wizardStep === 1}>
                <Typography variant="body2">
                  <FormattedMessage
                    id="text-select-reason-pick-one"
                    defaultMessage="Select a reason (pick one)"
                  />
                  <Styles.Require />
                  <If condition={isMobile}>
                    <Styles.ReportInfoIcon
                      color="#017EB7"
                      onClick={() => setMobInfoModalOpen(true)}
                    />
                    <Styles.CustomModal
                      isMobile={isMobile}
                      isOpen={mobInfoModalOpen}
                      closeOnClickOutside={true}
                      primaryAction={null}
                      onClose={() => setMobInfoModalOpen(false)}
                      header={
                        <Styles.Header isMobile={isMobile}>
                          <FormattedMessage
                            id="text-report-reasons"
                            defaultMessage="Report Reasons"
                          />
                        </Styles.Header>
                      }
                    >
                      <Styles.MobileReasonDescriptionContainer>
                        {radioButtons.map(({ value, title, description }) => (
                          <div key={value}>
                            <Typography variant="body2">
                              <FormattedMessage {...title} />
                            </Typography>
                            <Typography variant="subtitle2">
                              <FormattedMessage {...description} />
                            </Typography>
                          </div>
                        ))}
                      </Styles.MobileReasonDescriptionContainer>
                    </Styles.CustomModal>
                  </If>
                </Typography>
                <Box mt={Spacing.space12 as SpacingScaleValues}>
                  {radioButtons.map(({ value, title, description }) => (
                    <Styles.Field key={value}>
                      <ReportReasonListItem
                        view={isMobile ? 'mobile' : 'desktop'}
                        currentSelection={selectedReason.value}
                        value={value}
                        reasonTitle={title}
                        reasonDescription={description}
                        handleOnChange={handleOnChange}
                      />
                    </Styles.Field>
                  ))}
                </Box>
              </When>
              <When condition={isSubmitStep}>
                <Styles.SelectionDetailsContainer>
                  <FormattedMessage {...selectedReason.title}>
                    {(text) => (
                      <Styles.SelectionDetailsTitle isMobile={isMobile}>
                        {text}
                      </Styles.SelectionDetailsTitle>
                    )}
                  </FormattedMessage>
                  <FormattedMessage
                    {...selectedReason.description}
                    tagName={Styles.SelectionDetailsDescription}
                  >
                    {(text) => (
                      <Styles.SelectionDetailsDescription isMobile={isMobile}>
                        {text}
                      </Styles.SelectionDetailsDescription>
                    )}
                  </FormattedMessage>
                </Styles.SelectionDetailsContainer>

                <Typography variant="body2">
                  <FormattedMessage
                    id="my-report_report-reason-detail_text"
                    defaultMessage="Additional Information"
                  />
                  {isReportJustificationRequired && <Styles.Require />}
                </Typography>
                <Box mt={Spacing.space12 as SpacingScaleValues}>
                  <Styles.ReportTextArea
                    maxLength={500}
                    onChange={handleReportJustificationChange}
                    placeholder={`${formatMessage(placeholderMessage)} ${
                      isReportJustificationRequireMin20Chars
                        ? formatMessage(atLeast20Characters)
                        : ''
                    }`}
                    error={reportJustificationError}
                    rows={3}
                    value={reportJustification}
                    width="100%"
                    canExceedMaxLength={true}
                  />
                </Box>
                <Typography variant="body2" style={{ marginTop: 20 }}>
                  <FormattedMessage
                    id="text-upload-supporting-evidence"
                    defaultMessage="Upload Supporting Evidence"
                  />
                  <If condition={isAttachmentRequired}>
                    <Styles.Require />
                  </If>
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={Neutral.B40}
                  style={{ marginBottom: space12 }}
                >
                  <FormattedMessage
                    id="text-image-file-max"
                    defaultMessage="Only image file types ({imageType}) are accepted. Max 5 files, less than 5MB each."
                    values={{
                      imageType: IMAGE_TYPE_COPY,
                    }}
                  />{' '}
                  <FormattedMessage
                    id="text-upload-image"
                    defaultMessage="Upload image or drag your file to the area below."
                  />
                </Typography>
                <Flex>
                  <Flex style={{ gap: space8 }}>
                    {reportAttachments.map((attachment, index) => (
                      <UploadReportAttachment
                        key={attachment?.uploadedFileName}
                        index={index}
                        reportAttachments={reportAttachments}
                        setReportAttachments={setReportAttachments}
                        setError={setUploadError}
                        loading={uploadLoading}
                        setLoading={setUploadLoading}
                      />
                    ))}
                  </Flex>
                  <DragAndDropUploadReportAttachment
                    reportAttachments={reportAttachments}
                    setReportAttachments={setReportAttachments}
                    setError={setUploadError}
                    setLoading={setUploadLoading}
                  />
                </Flex>
                <If condition={uploadError}>
                  <InlineError
                    text={uploadError}
                    style={{ marginTop: space4 }}
                  />
                </If>
              </When>
            </Choose>
          </Styles.Content>
        </Styles.CustomModal>
      </Otherwise>
    </Choose>
  );
};

const mapStateToProps = (state: State) => ({
  reportModalOpen: state.app.reportModalOpen,
  reportModalId: state.app.reportModalId,
  reportCompanyId: state.app.reportCompanyId,
  openedFrom: state.app.openedFrom,
});

const mapDispatchToProps = {
  closeReportModal,
  trackReportJob: trackReportJobAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportModalV2);
