export const getDeviceIdFromCookie = (cookie?: string): string | undefined => {
  if (!cookie) return undefined;

  const cookies = cookie
    .split(';')
    .reduce<Record<string, string>>((acc, part) => {
      const [key, value] = part.trim().split('=');
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});

  return cookies['device_id'];
};
