import Cookies from 'js-cookie';

import { State } from 'src/global/store';
import { getConfig } from 'src/modules/ClientConfig';

export function getAvailableLanguages(state: State) {
  const config = getConfig(state);

  return config?.LANGS && config.LANGS?.length > 1 ? config.LANGS : ['en'];
}

export function getLocale(state: State) {
  const config = getConfig(state);

  // Check if language is contained within supported list.
  let language = config?.LANG;
  const availableLanguages = getAvailableLanguages(state);
  if (!language || availableLanguages.indexOf(language) < 0) {
    language = availableLanguages[0];
  }

  return {
    country: config?.COUNTRY,
    language,
  };
}

export function setLocale(country: string, language: string) {
  Cookies.set('country', country);
  Cookies.set('language', language);
}

export function unsetLocale() {
  Cookies.remove('country');
  Cookies.remove('language');
}

export function getLanguage(state: State) {
  return getLocale(state).language;
}
