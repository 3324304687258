import { Modal } from 'glints-aries';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  header h3 {
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
  }

  section {
    font-size: 16px;
    word-break: break-word;
  }

  section > span {
    line-height: normal;
  }
`;

export default StyledModal;
