import get from 'lodash/get';
import { handleActions } from 'redux-actions';

import { Unleash } from 'src/global/models/Unleash';
import { DstAction } from 'src/middleware/api/interfaces';

import { Actions } from './Actions';

export interface ReducerState {
  loading: boolean;
  error: any;
  value: {
    [name: string]: Unleash;
  };
}

const initialState: ReducerState = {
  loading: false,
  error: null,
  value: {},
};

export default handleActions(
  {
    [Actions.FETCH_FEATURES_REQUEST]: (state) => ({
      ...state,
      loading: true,
      error: null,
      value: {},
    }),
    [Actions.FETCH_FEATURES_SUCCESS]: (
      state,
      action: DstAction<any, Unleash[]>
    ) => ({
      ...state,
      loading: false,
      error: null,
      value: action.response.reduce((acc, cur) => {
        acc[cur.name] = cur;
        return acc;
      }, {} as StrIndexObject),
    }),
    [Actions.FETCH_FEATURES_FAILURE]: (state, action: DstAction) => ({
      ...state,
      loading: false,
      error: get(action.error, 'response.data'),
      value: {},
    }),
  },
  initialState
);
