import { handleActions } from 'redux-actions';

import { Actions } from 'src/actions/opportunities';
import { DstAction } from 'src/middleware/api/interfaces';

export interface OpportunitiesState {
  loading: boolean;
  lastFetched: string;
  error: any;
  value: string[];
  total: number;
  itemsPerPage: number;
  locationQueryString: string;
}

export const initialState: OpportunitiesState = {
  loading: false,
  lastFetched: null,
  error: null,
  value: [],
  total: 0,
  itemsPerPage: 30,
  locationQueryString: '',
};

export default handleActions<OpportunitiesState, any>(
  {
    [Actions.OPPORTUNITIES_REQUEST]: (state) => ({
      ...state,
      loading: true,
      error: null,
      value: [],
      lastFetched: null,
      total: 0,
      itemsPerPage: 30,
    }),
    [Actions.OPPORTUNITIES_SUCCESS]: (state, action: DstAction) => ({
      ...state,
      loading: false,
      error: null,
      value: action.response.result,
      total: action.response.count || 0,
      lastFetched: new Date().toISOString().split('.')[0],
    }),
    [Actions.OPPORTUNITIES_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
      value: [],
      lastFetched: null,
    }),
    [Actions.MORE_OPPORTUNITIES_REQUEST]: (state) => ({
      ...state,
      error: null,
    }),
    [Actions.MORE_OPPORTUNITIES_SUCCESS]: (state, action: DstAction) => ({
      ...state,
      error: null,
      value: [...new Set([...state.value.concat(action.response.result)])],
      total: action.response.count || 0,
    }),
    [Actions.MORE_OPPORTUNITIES_FAILURE]: (state, action) => ({
      ...state,
      error: action.error,
    }),
    [Actions.SET_OPPORTUNITIES_LOCATION_QUERY_STRING]: (state, action) => ({
      ...state,
      locationQueryString: action.payload || '',
    }),
    [Actions.CLEAR_OPPORTUNITIES]: (state) => ({
      ...initialState,
      locationQueryString: state.locationQueryString,
    }),
  },
  initialState
);
