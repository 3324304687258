import React, { PureComponent, ReactNode } from 'react';
import classNames from 'classnames';
import { NextRouter, withRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Action, ActionFunctionAny } from 'redux-actions';

import { showCloseableNotification } from 'src/actions/notifications';
import {
  bookmark,
  fetchMyBookmarks,
  unbookmark,
} from 'src/actions/user/bookmarks';
import {
  bookmarkMessage,
  unbookmarkMessage,
} from 'src/common/messages/general';
import { Bookmark, Company, Job } from 'src/global/models';
import { State as ReduxState } from 'src/global/store';
import { CallAPIOptions } from 'src/middleware/api/interfaces';
import { BOOKMARK_MAP, BOOKMARK_TYPES } from 'src/modules/Bookmark/constants';
import {
  getMyBookmarks,
  getMyBookmarksLastFetched,
} from 'src/selectors/user/bookmarks';

import { JobInterface } from '../Opportunity/interface';
import * as Styled from './BookmarkButton.sc';

export type Props = {
  type: BOOKMARK_TYPES;
  id: string;
  bookmarkEntity?: Job | JobInterface | Company; // could be Job, Company
  showIcon: boolean;
  showText: boolean;
  onBookmarkComplete: (id: string) => void;
  className: string;

  isNotBookmarkedClassName: string;
  preview: boolean;
  sticky: boolean;
  renderButton: (
    isBookmarked: boolean,
    isLoading: boolean,
    handleClick: (e: React.MouseEvent) => void
  ) => ReactNode;

  myBookmarks: any[];
  lastFetched: string;

  bookmark: (
    type: BOOKMARK_TYPES,
    bookmarkEntity: Company | Job | JobInterface,
    options: CallAPIOptions
  ) => void;
  unbookmark: (
    type: BOOKMARK_TYPES,
    bookmark: Bookmark,
    options: CallAPIOptions
  ) => void;
  fetchMyBookmarks: (options?: CallAPIOptions) => ReduxState;
  showCloseableNotification: ActionFunctionAny<
    Action<{ message: typeof FormattedMessage }>
  >;
  router: NextRouter;
};

type State = {
  isLoading: boolean;
};

class BookmarkButton extends PureComponent<Props, State> {
  _isMounted = false;

  state = { isLoading: false };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // gets bookmark from current store's bookmarks
  getBookmark = () => {
    const { myBookmarks } = this.props;
    return this.getBookmarkFromBookmarks(myBookmarks);
  };

  // gets bookmark from `bookmarks`
  getBookmarkFromBookmarks = (bookmarks: Bookmark[]) => {
    const { id, type } = this.props;
    const idKey = BOOKMARK_MAP[type].idKey;
    return bookmarks.find((bookmark: Bookmark) => bookmark[idKey] === id);
  };

  handleBookmark = async () => {
    const { type, id, bookmarkEntity, onBookmarkComplete } = this.props;
    const bookmark = this.getBookmark();

    this.setState({ isLoading: true });

    if (bookmark) {
      await this.props.unbookmark(type, bookmark, {});
    } else {
      if (type === BOOKMARK_TYPES.opportunity) {
        await this.props.bookmark(type, bookmarkEntity, {});
      } else {
        await this.props.bookmark(type, bookmarkEntity, {});
      }

      if (onBookmarkComplete) {
        onBookmarkComplete(id);
      }
    }
    // This condition prevents this component from calling setState when it is unmounted
    if (this._isMounted) {
      this.setState({ isLoading: false });
    }
  };

  // The clicking behaviour should be disabled for the jobs preview page.
  handleClick = (event: React.MouseEvent) => {
    if (this.props.preview) return;

    event.preventDefault();
    this.handleBookmark();
    event.stopPropagation();
  };

  renderIcon(isBookmarked: boolean) {
    return (
      <Styled.Icon
        name={isBookmarked ? 'ri-bookmark-fill' : 'ri-bookmark-line'}
      />
    );
  }

  renderText(isBookmarked: boolean) {
    if (isBookmarked) {
      return <FormattedMessage {...unbookmarkMessage} tagName="span" />;
    }
    return <FormattedMessage {...bookmarkMessage} tagName="span" />;
  }

  render() {
    const {
      showIcon,
      showText,
      className,
      isNotBookmarkedClassName,
      renderButton,
      ...restProps
    } = this.props;
    const { isLoading } = this.state;
    const isBookmarked = Boolean(this.getBookmark());

    if (renderButton) {
      return renderButton(isBookmarked, isLoading, this.handleClick);
    }

    return (
      <>
        {isLoading ? (
          <Styled.Loading className={className} />
        ) : (
          <Styled.ButtonWrapper
            className={classNames(className, {
              [isNotBookmarkedClassName]: !isBookmarked,
            })}
            onClick={this.handleClick}
            data-gtm-bookmarked={isBookmarked}
            {...restProps}
          >
            {showIcon && this.renderIcon(isBookmarked)}
            {showText && this.renderText(isBookmarked)}
          </Styled.ButtonWrapper>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
  myBookmarks: getMyBookmarks(state, ownProps.type),
  lastFetched: getMyBookmarksLastFetched(state),
});

const mapDispatchToProps = {
  bookmark,
  unbookmark,
  fetchMyBookmarks,
  showCloseableNotification,
};

export default compose<Props, any>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BookmarkButton);
