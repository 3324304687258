import { Children, cloneElement } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/dist/client/link';
import { format, UrlObject } from 'url';

import useConfig from 'src/common/hooks/useConfig';

function formatURL(url: string | UrlObject) {
  return url && typeof url === 'object' ? format(url) : (url as string);
}

const Link = ({
  children,
  ...rest
}: React.PropsWithChildren<NextLinkProps>) => {
  const config = useConfig();
  const pathPrefix = config.BASENAME;
  const child = Children.only(children) as React.ReactElement<any>;
  const shouldPassHref =
    rest.passHref || (child.type === 'a' && !('href' in child.props));

  // bypass, nothing to do here
  if (!pathPrefix || pathPrefix === '/' || !shouldPassHref) {
    return <NextLink {...rest}>{child}</NextLink>;
  }

  // prepend pathPrefix to the child's props.href
  const props: { href?: string } = {};
  const href = formatURL(rest.as || rest.href);
  if (href && !href.includes(pathPrefix)) {
    if (href === '/') props.href = pathPrefix;
    else props.href = pathPrefix + href;
  }

  return (
    <NextLink {...rest} passHref={false}>
      {cloneElement(child, props)}
    </NextLink>
  );
};

export type LinkProps = NextLinkProps;

export default Link;
