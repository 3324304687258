import { createMedia } from '@artsy/fresnel';
import { ScreenSize } from 'glints-aries';

export const EBreakpoints = {
  mobileS: 'mobileS',
  mobileM: 'mobileM',
  mobileL: 'mobileL',
  desktopS: 'desktopS',
  desktopM: 'desktopM',
  desktopL: 'desktopL',
  tablet: 'tablet',
};

/**
 * Define breakpoints
 * https://github.com/artsy/fresnel#createmedia
 */
const AppMedia = createMedia({
  breakpoints: {
    [EBreakpoints.mobileS]: ScreenSize.mobileS,
    [EBreakpoints.mobileM]: ScreenSize.mobileM,
    [EBreakpoints.mobileL]: ScreenSize.mobileL,
    [EBreakpoints.desktopS]: ScreenSize.desktopS,
    [EBreakpoints.desktopM]: ScreenSize.desktopM,
    [EBreakpoints.desktopL]: ScreenSize.desktopL,
    [EBreakpoints.tablet]: ScreenSize.tablet,
  },
});

export const {
  Media,
  MediaContextProvider,
  // needed for server-side rendering
  createMediaStyle,
  findBreakpointAtWidth,
  findBreakpointsForWidths,
} = AppMedia;
