import get from 'lodash/get';
import { createAction } from 'redux-actions';

import { JobLocationPreference } from 'src/global/models/JobLocationPreference';
import { ReduxThunkAction } from 'src/global/store';

export const Actions = {
  REQUEST_JOB_LOCATION_PREFERENCES:
    'glints/user/jobLocationPreferences/REQUEST_JOB_LOCATION_PREFERENCES',
  RECEIVE_JOB_LOCATION_PREFERENCES_SUCCESS:
    'glints/user/jobLocationPreferences/RECEIVE_JOB_LOCATION_PREFERENCES_SUCCESS',
  RECEIVE_JOB_LOCATION_PREFERENCES_FAILURE:
    'glints/user/jobLocationPreferences/RECEIVE_JOB_LOCATION_PREFERENCES_FAILURE',

  CLEAR_JOB_LOCATION_PREFERENCES:
    'glints/user/jobLocationPreferences/CLEAR_JOB_LOCATION_PREFERENCES',
};

const requestJobLocationPreferences = createAction(
  Actions.REQUEST_JOB_LOCATION_PREFERENCES
);
const receiveJobLocationPreferencesSuccess = createAction(
  Actions.RECEIVE_JOB_LOCATION_PREFERENCES_SUCCESS
);
const receiveJobLocationPreferencesFailure = createAction(
  Actions.RECEIVE_JOB_LOCATION_PREFERENCES_FAILURE
);

export const clearJobLocationPreferences = createAction(
  Actions.CLEAR_JOB_LOCATION_PREFERENCES
);

export function fetchJobLocationPreferences(): ReduxThunkAction<
  any,
  Promise<void>
> {
  return async (dispatch, getState, { api }) => {
    dispatch(requestJobLocationPreferences());
    try {
      const response = await api(getState().session.token).get(
        '/me/jobLocationPreferences',
        {
          params: {
            order: 'id ASC',
            include: 'City, Country',
          },
        }
      );
      const locations = (response.data.data as JobLocationPreference[]).map(
        (location) => ({
          ...location,
          name: location.CityId
            ? `${get(location, 'links.city.name')}, ${get(
                location,
                'links.country.name'
              )}`
            : get(location, 'links.country.name'),
        })
      );
      dispatch(receiveJobLocationPreferencesSuccess(locations));
    } catch (err) {
      dispatch(receiveJobLocationPreferencesFailure(err));
      throw err;
    }
  };
}
