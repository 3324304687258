import { State } from 'src/global/store';

export const getApplications = (state: State) => state.entities.application;
export const getBookmarks = (state: State) => state.entities.bookmark;
export const getCampaigns = (state: State) => state.entities.campaign;
export const getCities = (state: State) => state.entities.city;
export const getCompanies = (state: State) => state.entities.company;
export const getCountries = (state: State) => state.entities.country;
export const getDegrees = (state: State) => state.entities.degree;
export const getIndustries = (state: State) => state.entities.industry;
export const getOpportunities = (state: State) => state.entities.opportunity;
export const getPlatforms = (state: State) => state.entities.platform;
export const getSkills = (state: State) => state.entities.skill;
export const getOpportunitySkills = (state: State) =>
  state.entities.opportunitySkill;
export const getStudies = (state: State) => state.entities.study;
