import { combineReducers } from 'redux';

import bookmarks, { BookmarksState } from './bookmarks';
import me, { MeState } from './me';

export interface UserState {
  bookmarks: BookmarksState;
  me: MeState;
}

export default combineReducers({
  bookmarks,
  me,
});
