import React from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import authenticate from 'src/common/authenticate';
import useGetSWRFetcherForApiV2 from 'src/common/hooks/useGetSWRFetcherForApiV2';
import { State } from 'src/global/store';
import { getConfig } from 'src/modules/ClientConfig';
import { getIsAuthenticated } from 'src/modules/Session/Selectors';
import {
  areFeaturesFetched,
  getFeatures,
  getIsForYouV3Enabled,
} from 'src/modules/Unleash/Selectors';

import MenuBase from './MenuBase';
import makeMenuItems from './MenuItems';

interface Props {
  authenticated: boolean;
}

interface MenuItem {
  to: string;
  label: string;
  external?: boolean;
  message: {
    id: string;
    defaultMessage: string;
  };
  requiresAuth: boolean;
  new?: boolean;
  className?: string;
}

const useHasRecommendations = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { fetcher: apiV2Fetcher } = useGetSWRFetcherForApiV2();
  const areFeatureFetched = useSelector(areFeaturesFetched);
  const isForYouV3Enabled = useSelector(getIsForYouV3Enabled);

  const { data } = useSWR(
    isAuthenticated &&
      areFeatureFetched &&
      (isForYouV3Enabled
        ? `v3/me/recommend/es/jobs?page=1&pageSize=1`
        : `me/recommend/es/jobs?limit=1`),
    {
      fetcher: apiV2Fetcher,
    }
  );

  // We can just ignore loading state and errors here because we want the menu
  // to default to assuming that there are none.
  const hasRecommendations = data?.count > 0;
  return hasRecommendations;
};

const Menu = ({ authenticated }: Props) => {
  const hideMenu = useSelector((state: State) => state.app.hideMenu);
  const { asPath } = useRouter();
  const config = useSelector(getConfig);
  const features = useSelector(getFeatures);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const hasRecommendations = useHasRecommendations();
  const menuItems = makeMenuItems(
    config,
    features,
    isAuthenticated,
    hasRecommendations,
    asPath
  )() as MenuItem[];
  return (
    <MenuBase
      authenticated={authenticated}
      hideMenu={hideMenu}
      menuItems={menuItems}
    />
  );
};

export default authenticate(Menu, true);
