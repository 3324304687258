import { useRouter } from 'next/router';

import { ROUTES } from 'src/common/routes';

export const useShouldUseLoginSignupModal = () => {
  const router = useRouter();
  return (
    router.pathname.startsWith(`/${ROUTES.komunitas}`) ||
    router.pathname.startsWith(`/${ROUTES.expertClass}`)
  );
};
