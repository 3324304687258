import { createAction } from 'redux-actions';

export const GTMActions = {
  LOGIN_WITH_EMAIL_SUCCESS: 'glints/GoogleTagManager/LOGIN_WITH_EMAIL_SUCCESS',
  LOGIN_WITH_EMAIL_FAILURE: 'glints/GoogleTagManager/LOGIN_WITH_EMAIL_FAILURE',
  LOGIN_WITH_FACEBOOK_SUCCESS:
    'glints/GoogleTagManager/LOGIN_WITH_FACEBOOK_SUCCESS',
  LOGIN_WITH_FACEBOOK_FAILED:
    'glints/GoogleTagManager/LOGIN_WITH_FACEBOOK_FAILED',
  LOGIN_WITH_LINKEDIN_SUCCESS:
    'glints/GoogleTagManager/LOGIN_WITH_LINKEDIN_SUCCESS',
  LOGIN_WITH_LINKEDIN_FAILED:
    'glints/GoogleTagManager/LOGIN_WITH_LINKEDIN_FAILED',
  LOGIN_SIGN_UP_WITH_GOOGLE_CLICKED:
    'glints/GoogleTagManager/LOGIN_SIGN_UP_WITH_GOOGLE_CLICKED',
  LOGIN_WITH_GOOGLE_SUCCESS:
    'glints/GoogleTagManager/LOGIN_WITH_GOOGLE_SUCCESS',
  LOGIN_WITH_GOOGLE_ONE_TAP_SUCCESS:
    'glints/GoogleTagManager/LOGIN_WITH_GOOGLE_ONE_TAP_SUCCESS',
  LOGIN_WITH_GOOGLE_FAILED: 'glints/GoogleTagManager/LOGIN_WITH_GOOGLE_FAILED',
  LOGOUT_SUCCESS: 'glints/GoogleTagManager/LOGOUT_SUCCESS',
  SIGNUP_WITH_EMAIL_SUCCESS:
    'glints/GoogleTagManager/SIGNUP_WITH_EMAIL_SUCCESS',
  SIGNUP_WITH_FACEBOOK_SUCCESS:
    'glints/GoogleTagManager/SIGNUP_WITH_FACEBOOK_SUCCESS',
  SIGNUP_WITH_LINKEDIN_SUCCESS:
    'glints/GoogleTagManager/SIGNUP_WITH_LINKEDIN_SUCCESS',
  SIGNUP_WITH_GOOGLE_SUCCESS:
    'glints/GoogleTagManager/SIGNUP_WITH_GOOGLE_SUCCESS',
  SIGNUP_WITH_GOOGLE_ONE_TAP_SUCCESS:
    'glints/GoogleTagManager/SIGNUP_WITH_GOOGLE_ONE_TAP_SUCCESS',
  LOGIN_BUTTON_CLICKED: 'glints/GoogleTagManager/LOGIN_BUTTON_CLICKED',
  SIGNUP_BUTTON_CLICKED: 'glints/GoogleTagManager/SIGNUP_BUTTON_CLICKED',
  GOOGLE_ONE_TAP_VIEWED: 'glints/GoogleTagManager/GOOGLE_ONE_TAP_VIEWED',
};

export const LoginWithEmailSuccess = createAction(
  GTMActions.LOGIN_WITH_EMAIL_SUCCESS
);
export const LoginWithEmailFailure = createAction(
  GTMActions.LOGIN_WITH_EMAIL_FAILURE
);
export const LoginWithFacebookSuccess = createAction(
  GTMActions.LOGIN_WITH_FACEBOOK_SUCCESS
);
export const LoginWithFacebookFailed = createAction(
  GTMActions.LOGIN_WITH_FACEBOOK_FAILED
);
export const LoginWithLinkedinSuccess = createAction(
  GTMActions.LOGIN_WITH_LINKEDIN_SUCCESS
);
export const LoginWithLinkedinFailed = createAction(
  GTMActions.LOGIN_WITH_LINKEDIN_FAILED
);
export const LoginWithGoogleSuccess = createAction(
  GTMActions.LOGIN_WITH_GOOGLE_SUCCESS
);
export const LoginWithGoogleOneTapSuccess = createAction(
  GTMActions.LOGIN_WITH_GOOGLE_ONE_TAP_SUCCESS
);
export const LoginWithGoogleFailed = createAction(
  GTMActions.LOGIN_WITH_GOOGLE_FAILED
);
export const LoginSignUpWithGoogleClicked = createAction(
  GTMActions.LOGIN_SIGN_UP_WITH_GOOGLE_CLICKED
);

export const LogoutSuccess = createAction(GTMActions.LOGOUT_SUCCESS);

export const SignupWithEmailSuccess = createAction(
  GTMActions.SIGNUP_WITH_EMAIL_SUCCESS
);

export const SignupWithFacebookSuccess = createAction(
  GTMActions.SIGNUP_WITH_FACEBOOK_SUCCESS
);
export const SignupWithLinkedinSuccess = createAction(
  GTMActions.SIGNUP_WITH_LINKEDIN_SUCCESS
);
export const SignupWithGoogleSuccess = createAction(
  GTMActions.SIGNUP_WITH_GOOGLE_SUCCESS
);
export const SignupWithGoogleOneTapSuccess = createAction(
  GTMActions.SIGNUP_WITH_GOOGLE_ONE_TAP_SUCCESS
);

export const LoginButtonClicked = createAction(GTMActions.LOGIN_BUTTON_CLICKED);
export const SignUpButtonClicked = createAction(
  GTMActions.SIGNUP_BUTTON_CLICKED
);

export const GoogleOneTapViewed = createAction(
  GTMActions.GOOGLE_ONE_TAP_VIEWED
);
