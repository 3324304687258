import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Products } from 'src/common/enums';
import { useConfig } from 'src/common/hooks';
import useMount from 'src/common/hooks/useMount';
import { routerPathToProductGtmMap } from 'src/common/hooks/useProductGtmQuery';
import { State } from 'src/global/store';
import { loginWithGoogle } from 'src/modules/Session/Actions';
import { GoogleOneTapViewed } from 'src/modules/Session/GTMActions';
import { getIsAuthenticated } from 'src/modules/Session/Selectors';
import { getPrimaryPathName } from 'src/modules/SignUp/helper';

const pathnamesToShowGoogleOneTap = ['/', '/companies'];

const GoogleOneTapContainer = styled.div`
  position: fixed;
  top: 84px;
  right: 0;
  z-index: 100;
`;

export const GoogleOneTap = () => {
  const { googleClientId } = useConfig({ googleClientId: 'GOOGLE_CLIENT_ID' });
  const dispatch = useDispatch();
  const router = useRouter();
  const isLoggedIn = useSelector(getIsAuthenticated);
  const isLoginSignUpModalOpen = useSelector(
    (state: State) => state.app.eventsLoginSignUpModalOpen
  );

  const gtmPayloadString =
    routerPathToProductGtmMap[getPrimaryPathName(router.pathname)] ||
    Products.Marketplace;
  const onSuccess = useCallback(
    (res: any) =>
      dispatch(
        loginWithGoogle(
          res.credential,
          {
            login: gtmPayloadString,
            signup: gtmPayloadString,
          },
          'one tap'
        )
      ),
    [dispatch, gtmPayloadString]
  );
  const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);

  const shouldShowGoogleOneTap =
    !isLoggedIn &&
    pathnamesToShowGoogleOneTap.includes(router.pathname) &&
    !isLoginSignUpModalOpen;

  useMount(function trackGoogleOneTapPopupAppeared() {
    if (!shouldShowGoogleOneTap) return;
    dispatch(GoogleOneTapViewed());
  });

  useEffect(
    function loadGsiScript() {
      const handleGoogleSignIn = (res: CredentialResponse) => {
        onSuccess(res);
      };

      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.onload = () => {
        setGsiScriptLoaded(true);
        window.google.accounts.id.initialize({
          client_id: googleClientId,
          callback: handleGoogleSignIn,
        });
      };
      script.async = true;
      script.defer = true;
      script.id = 'google-client-script';
      document.body.appendChild(script);

      return () => {
        document.getElementById('google-client-script')?.remove();
      };
    },
    [googleClientId, gsiScriptLoaded, onSuccess]
  );

  if (!shouldShowGoogleOneTap) return null;
  return (
    <GoogleOneTapContainer
      id="g_id_onload"
      data-client_id={googleClientId}
      data-prompt_parent_id="g_id_onload"
    />
  );
};
