import { createAction } from 'redux-actions';

export const Actions = {
  OPPORTUNITY_REQUEST: 'glints/opportunity/OPPORTUNITY_REQUEST',
  OPPORTUNITY_SUCCESS: 'glints/opportunity/OPPORTUNITY_SUCCESS',
  OPPORTUNITY_FAILURE: 'glints/opportunity/OPPORTUNITY_FAILURE',

  OPPORTUNITY_OPEN_MODAL: 'glints/opportunity/OPPORTUNITY_OPEN_MODAL',
  OPPORTUNITY_CLOSE_MODAL: 'glints/opportunity/OPPORTUNITY_CLOSE_MODAL',

  OPPORTUNITY_OPEN_APPLICATION_SUCCESS_MODAL:
    'glints/opportunity/OPPORTUNITY_OPEN_APPLICATION_SUCCESS_MODAL',
  OPPORTUNITY_CLOSE_APPLICATION_SUCCESS_MODAL:
    'glints/opportunity/OPPORTUNITY_CLOSE_APPLICATION_SUCCESS_MODAL',

  CLEAR_OPPORTUNITY: 'glints/opportunity/CLEAR_OPPORTUNITY',

  DIRECT_APPLY_TO_OPPORTUNITY_REQUEST:
    'glints/opportunity/DIRECT_APPLY_TO_OPPORTUNITY_REQUEST',
  DIRECT_APPLY_TO_OPPORTUNITY_FAILED:
    'glints/opportunity/DIRECT_APPLY_TO_OPPORTUNITY_FAILED',
  DIRECT_APPLY_TO_OPPORTUNITY_SUCCESS:
    'glints/opportunity/DIRECT_APPLY_TO_OPPORTUNITY_SUCCESS',
};

export const clearOpportunity = createAction(Actions.CLEAR_OPPORTUNITY);

export const directApplyToOpportunity = createAction(
  Actions.DIRECT_APPLY_TO_OPPORTUNITY_REQUEST
);
export const directApplyToOpportunityFailed = createAction(
  Actions.DIRECT_APPLY_TO_OPPORTUNITY_FAILED
);
export const directApplyToOpportunitySuccess = createAction(
  Actions.DIRECT_APPLY_TO_OPPORTUNITY_SUCCESS
);

export const openModal = createAction(Actions.OPPORTUNITY_OPEN_MODAL);
export const closeModal = createAction(Actions.OPPORTUNITY_CLOSE_MODAL);

export const openApplicationSuccessModal = createAction(
  Actions.OPPORTUNITY_OPEN_APPLICATION_SUCCESS_MODAL
);
export const closeApplicationSuccessModal = createAction(
  Actions.OPPORTUNITY_CLOSE_APPLICATION_SUCCESS_MODAL
);
