import { ResourceLinks } from './ResourceLinks';

export enum Status {
  NEW = 'NEW',
  IN_REVIEW = 'IN_REVIEW',
  INTERVIEWING = 'INTERVIEWING',
  OFFERED = 'OFFERED',
  HIRED = 'HIRED',
  REJECTED = 'REJECTED',
  ASSESSMENT = 'ASSESSMENT',
}
export enum ApplicationSource {
  UNKNOWN = 'UNKNOWN',
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  WEB = 'WEB',
  TALENT_SEARCH_WEB = 'TALENT_SEARCH_WEB',
}

export type ApplicationStatusTracking = {
  createdAt: string;
  status: Status;
  message: string;
};

export interface EmployerScreeningQuestionAnswer {
  name: string;
  answers: {
    resourceId: string | null;
    value: string;
  }[];
}

export interface Application extends ResourceLinks {
  id: string;
  note: string;
  attachments: string[];
  metadata: {
    forGroupId?: string;
    recommendedBy?: boolean;
    streamChannelId?: string;
  };
  expiryDate: string;
  status: Status;
  JobId: string;
  estimatedSalaries?: {
    minAmount: number;
    maxAmount: number;
    CurrencyCode: string;
  };
  ApplicantId: string;
  source: ApplicationSource;
  createdAt: string;
  updatedAt: string;
  resume: string;
  phone: string;
  applicationStatusTracking?: ApplicationStatusTracking[];
  employerScreeningQuestionAnswers?: EmployerScreeningQuestionAnswer[];
}
