import { handleActions } from 'redux-actions';

import { Actions } from 'src/actions/opportunity';
import { DstAction } from 'src/middleware/api/interfaces';

export interface OpportunityState {
  loading: boolean;
  error: any;
  value: string;
  skills: string[];
  visible: boolean;
  directApply: boolean;
  applicationSuccessModal: {
    isVisible: boolean;
  };
}

export const initialState: OpportunityState = {
  loading: false,
  error: null,
  value: null,
  skills: [],
  visible: false,
  directApply: false,
  applicationSuccessModal: {
    isVisible: false,
  },
};

export default handleActions<OpportunityState, any>(
  {
    [Actions.OPPORTUNITY_REQUEST]: (state) => ({
      ...state,
      loading: true,
      error: null,
      value: null,
    }),
    [Actions.OPPORTUNITY_SUCCESS]: (state, action: DstAction) => ({
      ...state,
      loading: false,
      error: null,
      value: action.response.result,
      skills:
        action.response.entities.opportunity[action.response.result].links
          .skills,
    }),
    [Actions.OPPORTUNITY_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
      value: null,
    }),
    [Actions.OPPORTUNITY_OPEN_MODAL]: (state) => ({
      ...state,
      visible: true,
    }),
    [Actions.OPPORTUNITY_CLOSE_MODAL]: (state) => ({
      ...state,
      visible: false,
    }),
    [Actions.DIRECT_APPLY_TO_OPPORTUNITY_REQUEST]: (state) => ({
      ...state,
      directApply: true,
    }),
    [Actions.DIRECT_APPLY_TO_OPPORTUNITY_FAILED]: (state) => ({
      ...state,
      directApply: false,
    }),
    [Actions.DIRECT_APPLY_TO_OPPORTUNITY_SUCCESS]: (state) => ({
      ...state,
      directApply: false,
    }),
    [Actions.OPPORTUNITY_OPEN_APPLICATION_SUCCESS_MODAL]: (state) => ({
      ...state,
      applicationSuccessModal: {
        ...state.applicationSuccessModal,
        isVisible: true,
      },
    }),
    [Actions.OPPORTUNITY_CLOSE_APPLICATION_SUCCESS_MODAL]: (state) => ({
      ...state,
      applicationSuccessModal: {
        ...state.applicationSuccessModal,
        isVisible: false,
      },
    }),
    [Actions.CLEAR_OPPORTUNITY]: () => ({
      ...initialState,
    }),
  },
  initialState
);
