import { defineMessage } from 'react-intl';

export const cancelMessage = defineMessage({
  id: 'cancel',
  defaultMessage: 'Cancel',
});

export const saveMessage = defineMessage({
  id: 'save',
  defaultMessage: 'Save',
});

export const savingMessage = defineMessage({
  id: 'saving',
  defaultMessage: 'Saving',
});

export const sendMessage = defineMessage({
  id: 'send',
  defaultMessage: 'Send',
});

export const bookmarkMessage = defineMessage({
  id: 'bookmark',
  defaultMessage: 'Bookmark',
});

export const unbookmarkMessage = defineMessage({
  id: 'unbookmark',
  defaultMessage: 'Unbookmark',
});

export const backToTopMessage = defineMessage({
  id: 'back.to.top',
  defaultMessage: 'Back to top',
});

export const deleteMessage = defineMessage({
  id: 'delete',
  defaultMessage: 'Delete',
});

export const editMessage = defineMessage({
  id: 'edit',
  defaultMessage: 'Edit',
});
