import '@glints/poppins';
import 'src/common/apm';
import 'moment/locale/id';
import 'moment/locale/vi';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import 'moment/locale/en-sg';
import './toast-override.css';

import * as React from 'react';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import get from 'lodash/get';
import Moment from 'moment';
import App from 'next/app';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import initClientApp, {
  DstClientAppProps,
} from 'nextDir/enhancers/initClientApp';
import initServerApp, {
  DstServerAppProps,
} from 'nextDir/enhancers/initServerApp';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';

import { CountryCodes } from 'src/common/enums';
import isServer from 'src/common/isServer';
import { mapLanguageToMomentLocale } from 'src/common/locale';
import { DstStore } from 'src/global/store';
import { default as DefaultAppShell } from 'src/MainLayout';
import { MediaContextProvider } from 'src/media';
import { ApolloWrapperProvider } from 'src/modules/ApolloWrapper/ApolloWrapperProvider';
import {
  getCountryWithSGFallback,
  getZendeskKey,
} from 'src/modules/ClientConfig/Selectors';
import { SWRProvider } from 'src/modules/SWRProvider/SWRProvider';
import { WhatsappButton } from 'src/modules/Whatsapp/Whatsapp';

import { GlobalStyles } from './_app.sc';
import ErrorPage from './_error';

const RenderlessVirality = dynamic(() =>
  import('src/modules/Virality/components/RenderlessVirality').then(
    (mod) => mod.RenderlessVirality
  )
);

const RenderlessRedirectToEmployer = dynamic(
  () =>
    import('src/modules/Session/Components/RenderlessRedirectToEmployer').then(
      (mod) => mod.RenderlessRedirectToEmployer
    ),
  { ssr: false }
);

const RenderlessZendesk = dynamic(
  () =>
    import('src/modules/Zendesk/RenderlessZendesk').then(
      (mod) => mod.RenderlessZendesk
    ),
  { ssr: false }
);

const ApolloProviderComponent = isServer
  ? ApolloProvider
  : ApolloWrapperProvider;

const gtmScript = `
  (function(w,d,s,l,i){w[l] = w[l] || []; w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-NNXRHZJ');
`;

class DstApp extends App<DstAppProps> {
  constructor(props: DstAppProps) {
    super(props);
    const {
      dstContext: { intl },
    } = props;
    Moment.locale(mapLanguageToMomentLocale(intl.locale));
  }

  renderPageComponent() {
    const { Component, pageProps } = this.props;
    const AppShell = (Component as any).AppShell || DefaultAppShell;
    const PageLayout = (Component as any).PageLayout || React.Fragment;

    // pageProps is a property added by nextjs
    // in our withApollo HOC, it is not available in appTreeProps
    const page = pageProps?.statusCode ? (
      // @ts-ignore
      <ErrorPage statusCode={pageProps.statusCode} />
    ) : (
      <Component {...(pageProps || {})} />
    );

    return (
      <AppShell>
        <PageLayout>{page}</PageLayout>
      </AppShell>
    );
  }

  render() {
    const {
      reduxStore,
      dstContext: { intl },
      apolloClient,
      responsiveRenderProps,
    } = this.props;

    const country = getCountryWithSGFallback(reduxStore.getState());
    const zenDeskKey = getZendeskKey(reduxStore.getState());

    return (
      <IntlProvider locale={intl.locale} messages={intl.messages}>
        <Provider store={reduxStore as any}>
          {/* @ts-ignore */}
          <MediaContextProvider
            onlyMatch={get(responsiveRenderProps, 'onlyMatch', undefined)}
          >
            <GlobalStyles />
            {/* @ts-ignore */}
            <ApolloProviderComponent client={apolloClient}>
              <SWRProvider>
                <RenderlessVirality />
                <RenderlessRedirectToEmployer />
                <Choose>
                  <When condition={country === CountryCodes.ID}>
                    <WhatsappButton />
                  </When>
                  <When condition={country === CountryCodes.VN}>
                    <></>
                  </When>
                  <Otherwise>
                    <RenderlessZendesk zenDeskKey={zenDeskKey} />
                  </Otherwise>
                </Choose>
                <Script id="gtm-base" strategy="afterInteractive">
                  {gtmScript}
                </Script>
                {this.renderPageComponent()}
                <div id="glints-portal-container" />
              </SWRProvider>
            </ApolloProviderComponent>
          </MediaContextProvider>
        </Provider>
      </IntlProvider>
    );
  }
}

export interface DstAppProps extends DstServerAppProps, DstClientAppProps {
  reduxStore: DstStore;
  apolloClient: ApolloClient<any>;
}

const enhancer = isServer ? initServerApp : initClientApp;

export default enhancer(DstApp);
