/**
 * Assets used on Glints are stored on Amazon S3. Due to the differing storage
 * locations depending on environment (i.e. production vs development) and
 * asset type, this helper function allows callers to quickly determine the
 * full URL.
 *
 * The currently defined asset types are:
 *
 *   - application-attachment
 *   - career-banner-pic
 *   - career-banner-pic-small
 *   - company-banner-pic
 *   - company-logo
 *   - company-resources
 *   - content
 *   - default-banner-pic-large
 *   - default-banner-pic-small
 *   - job-attachment
 *   - job-banner-pic
 *   - perk-pic
 *   - profession-banner-pic
 *   - profession-banner-pic-small
 *   - profile-picture
 *   - profile-picture-default
 *   - resource-banner-pic
 *   - resource-cards
 *   - resume
 */

// https://stackoverflow.com/a/34842797/825981
function hash(str: string) {
  if (typeof str !== 'string') {
    return 0;
  }
  return str
    .split('')
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0
    );
}

export function getRandomFallbackProfilePicture(id: string) {
  return `${(Math.abs(hash(id)) % 15) + 1}.jpg`;
}

export function getRandomFallbackCompanyBannerPic(id: string) {
  return `${(Math.abs(hash(id)) % 16) + 1}.jpg`;
}

export function getRandomFallbackJobBannerPic(id: string) {
  return `${(Math.abs(hash(id)) % 16) + 1}.jpg`;
}
