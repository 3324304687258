import { Alert as AriesAlert } from 'glints-aries';
import styled from 'styled-components';

export const Alert = styled(AriesAlert)<{
  position?: 'top' | 'top-right';
}>`
  display: flex;
  align-items: start;
  svg {
    margin-top: 8px;
  }
  ${({ position }) => {
    if (position === 'top-right') {
      return `
        top: 0;
        right: 0;
        left: auto;
        margin: 16px;
        animation: slideInRight 0.3s ease-in-out;

        @keyframes slideInRight {
          from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(0);
          }
        }
      `;
    }
    return '';
  }}
`;
