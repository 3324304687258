import { isEmpty, isObject } from 'lodash';

export function getClassName(className) {
  if (isObject(className) && !Array.isArray(className)) {
    throw new Error('className can only be string or array');
  }
  if (isEmpty(className)) {
    return '';
  }
  if (Array.isArray(className)) {
    return className.join(' ');
  }
  return className;
}
