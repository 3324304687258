import { createAction } from 'redux-actions';

export const Actions = {
  SHOW_ZENDESK_MESSAGING: 'glints/zendesk/SHOW_ZENDESK_MESSAGING',
  HIDE_ZENDESK_MESSAGING: 'glints/zendesk/HIDE_ZENDESK_MESSAGING',
};

export const showZendeskMessagingAction = createAction(
  Actions.SHOW_ZENDESK_MESSAGING
);

export const hideZendeskMessagingAction = createAction(
  Actions.HIDE_ZENDESK_MESSAGING
);
