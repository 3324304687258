import get from 'lodash/get';
import { handleActions } from 'redux-actions';

import { Actions } from '../actions/app';

export interface AppState {
  eventsLoginSignUpModalOpen: boolean;
  eventsLoginSignUpModalAfterLoginCallback(): void;
  eventsLoginSignUpModalAfterSignUpClicked(): void;
  externalLinkModal: {
    isVisible: boolean;
    link: string;
  };
  hideMenu: boolean;
  loadingSignupPage: boolean;
  loginFormIsHovering: boolean;
  loginModalOpen: boolean;
  mainContainerProtected: boolean;
  menuInverted: boolean;
  sidebarOpen: boolean;
  signupFormIsHovering: boolean;
  reportModalId: string;
  reportCompanyId: string;
  reportModalOpen: boolean;
  openedFrom: 'fyp' | 'explore';
  source?: string;
}

export const initialState: AppState = {
  eventsLoginSignUpModalOpen: false,
  eventsLoginSignUpModalAfterLoginCallback: null,
  eventsLoginSignUpModalAfterSignUpClicked: null,
  externalLinkModal: {
    isVisible: false,
    link: null,
  },
  hideMenu: false,
  loadingSignupPage: false,
  loginFormIsHovering: false,
  loginModalOpen: false,
  mainContainerProtected: true,
  menuInverted: false,
  sidebarOpen: false,
  signupFormIsHovering: false,
  reportModalId: undefined,
  reportCompanyId: undefined,
  reportModalOpen: false,
  openedFrom: 'explore',
};

export default handleActions<AppState, any>(
  {
    [Actions.OPEN_LOGIN_MODAL]: (state) => ({
      ...state,
      loginModalOpen: true,
    }),
    [Actions.CLOSE_LOGIN_MODAL]: (state) => ({
      ...state,
      loginModalOpen: false,
    }),
    [Actions.OPEN_EVENTS_LOGIN_SIGNUP_MODAL]: (state, action) => ({
      ...state,
      eventsLoginSignUpModalOpen: true,
      eventsLoginSignUpModalAfterLoginCallback: get(
        action,
        'payload.eventsLoginSignUpModalAfterLoginCallback',
        null
      ),
      eventsLoginSignUpModalAfterSignUpClicked: get(
        action,
        'payload.eventsLoginSignUpModalAfterSignUpClicked',
        null
      ),
    }),
    [Actions.CLOSE_EVENTS_LOGIN_SIGNUP_MODAL]: (state) => ({
      ...state,
      eventsLoginSignUpModalOpen: false,
      eventsLoginSignUpModalAfterLoginCallback: null,
      eventsLoginSignUpModalAfterSignUpClicked: null,
      source: null,
    }),
    [Actions.SET_PROTECTED]: (state) => ({
      ...state,
      mainContainerProtected: true,
    }),
    [Actions.UNSET_PROTECTED]: (state) => ({
      ...state,
      mainContainerProtected: false,
    }),
    [Actions.SET_INVERTED]: (state) => ({
      ...state,
      menuInverted: true,
    }),
    [Actions.UNSET_INVERTED]: (state) => ({
      ...state,
      menuInverted: false,
    }),
    [Actions.OPEN_SIDEBAR]: (state) => ({
      ...state,
      sidebarOpen: true,
    }),
    [Actions.CLOSE_SIDEBAR]: (state) => ({
      ...state,
      sidebarOpen: false,
    }),
    [Actions.SET_LOGIN_FORM_HOVER]: (state) => ({
      ...state,
      loginFormIsHovering: true,
    }),
    [Actions.UNSET_LOGIN_FORM_HOVER]: (state) => ({
      ...state,
      loginFormIsHovering: false,
    }),
    [Actions.SET_SIGNUP_FORM_HOVER]: (state) => ({
      ...state,
      signupFormIsHovering: true,
    }),
    [Actions.UNSET_SIGNUP_FORM_HOVER]: (state) => ({
      ...state,
      signupFormIsHovering: false,
    }),
    [Actions.OPEN_REPORT_MODAL]: (state, action) => ({
      ...state,
      reportModalOpen: true,
      reportModalId: action.payload.id,
      reportCompanyId: action.payload.companyId,
      openedFrom: action.payload.openedFrom,
    }),
    [Actions.CLOSE_REPORT_MODAL]: (state) => ({
      ...state,
      reportModalOpen: false,
    }),
    [Actions.OPEN_EXTERNAL_LINK_MODAL]: (state, action) => ({
      ...state,
      externalLinkModal: {
        isVisible: true,
        link: action.payload,
      },
    }),
    [Actions.CLOSE_EXTERNAL_LINK_MODAL]: (state) => ({
      ...state,
      externalLinkModal: {
        isVisible: false,
        link: null,
      },
    }),
    [Actions.OPEN_LOGIN_POPUP]: (state) => ({
      ...state,
      eventsLoginSignUpModalOpen: true,
      source: 'popup',
    }),
    [Actions.CLOSE_LOGIN_POPUP]: (state) => ({
      ...state,
      eventsLoginSignUpModalOpen: false,
      source: null,
    }),
    [Actions.OPEN_LOGIN_MODAL_FROM_SECTION]: (state) => ({
      ...state,
      eventsLoginSignUpModalOpen: true,
      source: 'signup/login section',
    }),
  },
  initialState
);
