export enum Locale {
  en = 'en',
  id = 'id',
  vi = 'vi',
  zhMY = 'zh-MY',
  zhTW = 'zh-TW',
}

const languageToMomentLocaleMap = {
  en: 'en-sg',
  id: 'id',
  vi: 'vi',
  'zh-MY': 'zh-cn',
  'zh-TW': 'zh-tw',
};

export const mapLanguageToMomentLocale = (language: Locale) =>
  languageToMomentLocaleMap[language] || language;
