export const ApiV2ClientName = 'apiV2';
export const ApiV2AlcClientName = 'apiV2Alc';

export const apolloContextWithApiV2ClientName = {
  clientName: ApiV2ClientName,
};

export const apolloContextWithApiV2AlcClientName = {
  clientName: ApiV2AlcClientName,
};
